import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import {
  addHeaderInAxios,
  channelViews,
  checkSignature,
} from "../utils/Helpers";
import backLogo from "../assets/images/back.png";

import PrivateView from "../components/PrivateView";
import PublicView from "../components/PublicView";
import SettingsView from "../components/SettingsView";
import SharingView from "../components/SharingView";
import APIKeysView from "../components/APIKeysView";
import DataImportExportView from "../components/DataImportExportView";
import axios from "axios";
import { GET_CHANNEL_BY_ID } from "../shared/ApiURLs";
import MyLoader from "../components/ContentLoader";
import Toast from "../shared/Toast";
import { toast, ToastContainer } from "react-toastify";
import { Backdrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const Channel = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [channel, setChannel] = useState({});
  const [feed, setFeed] = useState({});
  const [active_feed_fields, setActive_feed_fields] = useState({});
  const [isContentLoading, setContentLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [apiRefreshRateMs, setapiRefreshRateMs] = useState(300000);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  useEffect(() => {
    // Anything in here is fired on component mount.
    addHeaderInAxios(props)
      ? getChannel(props?.match?.params?.id)
      : this.props.history.replace("/");

    const refreshInterval = setInterval(() => {
      getChannel(props?.match?.params?.id);
    }, apiRefreshRateMs);
    return () => {
      // Anything in here is fired on component unmount.
      clearInterval(refreshInterval);
    };
  }, [firstTimeLoad]);

  useEffect(() => {
    // Anything in here is fired on component mount.
    setFirstTimeLoad(true);
    addHeaderInAxios(props)
      ? getChannel(props?.match?.params?.id)
      : this.props.history.replace("/");
  }, [props?.match?.params?.id]);

  const getChannel = (channel_id) => {
    axios
      .post(GET_CHANNEL_BY_ID, { channel_id: channel_id })
      .then((res) => {
        setChannel(res.data.channel);
        setFeed(res.data.feed);
        setActive_feed_fields(res.data.active_feed_fields);
        if (firstTimeLoad) {
          setFirstTimeLoad(false);

          if (props?.match?.params?.view_type) {
            let isViewExists = channelViews.indexOf(
              props?.match?.params?.view_type
            );
            if (isViewExists > 0) {
              handleChangeIndex(isViewExists);
            } else {
              handleChangeIndex(0);
            }
          } else {
            handleChangeIndex(0);
          }
        }
        setContentLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        setContentLoading(false);
        props.history.replace("/channels");
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const backBtnHandler = () => {
    return props.history.replace("/channels");
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const handleLoading = (isLoding) => {
    setLoading(isLoding);
  };

  // setInterval(() => {
  //   getChannel(props?.match?.params?.id);
  // }, apiRefreshRateMs);

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && Object.keys(channel).length && (
        <Container fluid>
          <div className="mb-3">
            <img
              src={backLogo}
              alt="back"
              className="backBtn"
              onClick={backBtnHandler}
            />
          </div>

          <div>
            <div className="mb-5">
              <h1 className="mb-3">{channel.name}</h1>
              <div>
                <h6 className="mb-1">
                  {" "}
                  <b>Channel ID : </b> {channel?.id}{" "}
                </h6>
                <h6 className="mb-1">
                  {" "}
                  <b>Access : </b> {channel?.is_private ? "Private" : "Public"}{" "}
                </h6>
                <h6 className="mb-1">
                  {" "}
                  <b>Description : </b> {channel.description}
                </h6>
                <h6 className="mb-1">
                  {" "}
                  <b>Site : </b> {channel.site_name}
                </h6>
              </div>
            </div>

            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Private View" {...a11yProps(0)} />
                  <Tab label="Public View" {...a11yProps(1)} />
                  <Tab label="Channel Settings" {...a11yProps(2)} />
                  <Tab label="Sharing" {...a11yProps(3)} />
                  <Tab label="API Keys" {...a11yProps(4)} />
                  <Tab label="Data Import / Export" {...a11yProps(5)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <PrivateView
                    active_feed_fields={active_feed_fields}
                    channelData={channel}
                    feedData={feed}
                    apiRefreshRateMs={apiRefreshRateMs}
                    handleToast={handleToast}
                    handleLoading={handleLoading}
                    {...props}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <PublicView
                    channelData={channel}
                    handleChangeIndex={handleChangeIndex}
                    feedData={feed}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <SettingsView
                    active_feed_fields={active_feed_fields}
                    channelData={channel}
                    handleToast={handleToast}
                    handleLoading={handleLoading}
                    refreshChannel={getChannel}
                    {...props}
                  />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                  <SharingView
                    channelData={channel}
                    refreshChannel={getChannel}
                    handleToast={handleToast}
                    handleLoading={handleLoading}
                    {...props}
                  />
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                  <APIKeysView channelData={channel} />
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>
                  <DataImportExportView
                    channelData={channel}
                    handleToast={handleToast}
                    handleLoading={handleLoading}
                    {...props}
                  />
                </TabPanel>
              </SwipeableViews>
            </div>
          </div>
        </Container>
      )}
      <Footer />
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Channel;
