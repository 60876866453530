import React from "react";
import MyLoader from "../components/ContentLoader";
import Header from "../components/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import {
  Backdrop,
  CircularProgress,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import backLogo from "../assets/images/back.png";
import { addHeaderInAxios, checkSignature } from "../utils/Helpers";
import {
  GET_WMS_HISTORY,
  SAMPLE_WMS_CSV,
  WMS_FILE_UPLOAD,
  SAMPLE_ICS_CSV,
  ICS_FILE_UPLOAD,
  GET_ICS_HISTORY,
} from "../shared/ApiURLs";
import axios from "axios";
import Moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { ReactSelect } from "../components/ReactSelect";
import DialogTitle from "@material-ui/core/DialogTitle";
import { serverURL } from "../shared/ApiURLs";
class UploadZone extends React.Component {
  state = {
    uploadHistory: [],
    isContentLoading: true,
    uploadIscHistory: [],
    loading: false,
    file: null,
    uploadeDataType: null,
    siteName: null,
    siteNameList: [],
    token: localStorage.getItem("auth-token"),
  };

  componentDidMount = () => {
    addHeaderInAxios(this.props);
    this.getUploadHistory();
    this.getUploadISCHistory();
    this.selectSiteList();
  };
  selectSiteList = () => {
    axios
      .get(`${serverURL}/channels/get_all_site`, {
        Authorization: `Bearer ${this.state.token}`,
      })
      .then((res) => {
        //console.log("resModel", res.data);
        this.setState({ siteNameList: res.data.sites });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  getUploadHistory = () => {
    axios
      .get(GET_WMS_HISTORY)
      .then((res) => {
        this.setState(
          {
            uploadHistory: res.data.uploadHistory.reverse(),
            isContentLoading: false,
          },
          () => {
            // console.log(this.state.uploadHistory);
            // console.log("hi", this.state.uploadeDataType);
          }
        );
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({ isContentLoading: false });
      });
  };

  getUploadISCHistory = () => {
    axios
      .get(GET_ICS_HISTORY)
      .then((res) => {
        this.setState(
          {
            uploadIscHistory: res.data.uploadHistory.reverse(),
            isContentLoading: false,
          },
          () => {
            // console.log(this.state.uploadIscHistory);
            // console.log("hi", this.state.uploadeDataType);
          }
        );
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({ isContentLoading: false });
      });
  };

  backBtnHandler = () => {
    return this.props.history.replace("/channels");
  };

  handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  handleFileUpload = () => {
    if (!this.state.file)
      return this.handleToast("error", "Please Select a File!");
    if (this.state.uploadeDataType.label == "WMS Data") {
      this.setState({ loading: true });
      let formdata = new FormData();
      formdata.append("file", this.state.file);
      formdata.append("site_id", this.state.siteName.value);
      axios
        .post(WMS_FILE_UPLOAD, formdata)
        .then((response) => {
          this.getUploadHistory();
          this.setState({ loading: false, file: null });
          this.handleToast("success", response?.data?.message);
        })
        .catch((e) => {
          this.setState({ loading: false, file: null });
          console.log(e?.response?.data);
          this.getUploadHistory();
          this.handleToast("error", e?.response?.data?.message);
        });
    } else if (this.state.uploadeDataType.label == "Cleaning Data") {
      this.setState({ loading: true });
      let formdata = new FormData();
      formdata.append("file", this.state.file);
      axios
        .post(ICS_FILE_UPLOAD, formdata)
        .then((response) => {
          this.getUploadISCHistory();
          this.setState({ loading: false, file: null });
          this.handleToast("success", response?.data?.message);
        })
        .catch((e) => {
          this.setState({ loading: false, file: null });
          console.log(e?.response?.data);
          this.getUploadISCHistory();
          this.handleToast("error", e?.response?.data?.message);
        });
    }
  };

  onFileChange = (event) => {
    console.log("EVENT ", event);
    console.log("EVENT ", this.state.uploadeDataType);
    const fileData = event.target.files[0];
    this.setState({ file: fileData });
    // event.target.files = null;
  };

  sliceName = (name) => {
    return String(name).length > 40 ? String(name).slice(0, 40) + "..." : name;
  };

  render() {
    const {
      isContentLoading,
      loading,
      file,
      uploadHistory,
      uploadeDataType,
      uploadIscHistory,
    } = this.state;

    return (
      <div className="main-wrapper">
        <Header {...this.props} />
        {isContentLoading && <MyLoader />}
        {!isContentLoading && (
          <Container fluid>
            <div className="mb-3">
              <img
                src={backLogo}
                alt="back"
                className="backBtn mb-3"
                onClick={this.backBtnHandler}
              />

              <div>
                <div className="custom_card pt-3 pb-2 px-3 text-center">
                  <div className="mb-3">
                    <h1>Upload Zone</h1>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-center w-100 my-3">
                    <div style={{ margin: "0px 20px" }}>
                      <ReactSelect
                        data={[
                          {
                            label: "WMS Data",
                            value: "wms data",
                          },
                          {
                            label: "Cleaning Data",
                            value: "clening data",
                          },
                        ]}
                        value={this.state.uploadeDataType}
                        placeholder={"Select type"}
                        // isLoading={false}
                        handleChange={(value) =>
                          this.setState({ uploadeDataType: value })
                        }
                      />
                    </div>
                    {this.state.uploadeDataType?.label !== "WMS Data" ? (
                      ""
                    ) : (
                      <div style={{ margin: "0px 20px" }}>
                        <ReactSelect
                          data={this.state.siteNameList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))}
                          value={this.state.siteName}
                          placeholder={"Select Site"}
                          // isLoading={false}
                          handleChange={(value) =>
                            this.setState({ siteName: value })
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="mb-3 ">
                    {this.state.uploadeDataType == null ||
                    this.state.uploadeDataType.label == "WMS Data" ? (
                      <p className="mb-3">
                        Upload a CSV/XLSX file to import WMS Data.
                        <small className=" d-block">
                          <a
                            href={SAMPLE_WMS_CSV}
                            download
                            target="_blank"
                            rel="noreferrer"
                          >
                            Sample Upload WMS CSV &#8595;
                          </a>
                        </small>
                      </p>
                    ) : (
                      <p className="mb-3">
                        Upload a CSV/XLSX file to import Cleaning Data.
                        <small className=" d-block">
                          <a
                            href={SAMPLE_ICS_CSV}
                            download
                            target="_blank"
                            rel="noreferrer"
                          >
                            Sample Upload ISC CSV &#8595;
                          </a>
                        </small>
                      </p>
                    )}

                    <div>
                      <label htmlFor="upload-photo">
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          accept=".csv, .xlsx"
                          onChange={(event) => this.onFileChange(event)}
                          // onChange={(event)=> {
                          //   this.readFile(event)
                          // }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />

                        <Button
                          disabled={
                            (this.state.uploadeDataType?.label == "WMS Data" ||
                              this.state.uploadeDataType?.label == undefined) &&
                            this.state.siteName == null
                              ? true
                              : false
                          }
                          color="primary"
                          className="px-4 py-2"
                          variant="contained"
                          component="span"
                        >
                          Choose File
                        </Button>
                      </label>
                    </div>
                    <button
                      hidden={!file?.name}
                      className="px-4 py-2 mt-2 btn btn-primary text-light"
                      onClick={this.handleFileUpload}
                    >
                      Upload : {file?.name}
                    </button>
                  </div>
                </div>

                {/* Table Content */}

                {this.state.uploadeDataType == null ||
                this.state.uploadeDataType.label == "WMS Data"
                  ? uploadHistory.length !== 0 && (
                      <div className="custom_card px-3 mt-3 py-3">
                        <TableContainer>
                          <Table aria-label="CustomTable">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">File</TableCell>
                                <TableCell align="left">Size</TableCell>

                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">
                                  Uploaded On
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {uploadHistory.map((el) => {
                                return (
                                  <TableRow key={el?.id}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="left"
                                    >
                                      {this.sliceName(el?.file_name)}{" "}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="left"
                                    >
                                      {parseFloat(
                                        el?.file_size / 1048576
                                      )?.toFixed(1)}
                                      MB
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="center"
                                    >
                                      {el?.status}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="center"
                                    >
                                      {Moment(el.created_at).format(
                                        "MMM Do YYYY, h:mm:ss A"
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )
                  : uploadIscHistory.length !== 0 && (
                      <div className="custom_card px-3 mt-3 py-3">
                        <TableContainer>
                          <Table aria-label="CustomTable">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">File</TableCell>
                                <TableCell align="left">Size</TableCell>

                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">
                                  Uploaded On
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {uploadIscHistory.map((el) => {
                                return (
                                  <TableRow key={el?.id}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="left"
                                    >
                                      {this.sliceName(el?.file_name)}{" "}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="left"
                                    >
                                      {parseFloat(
                                        el?.file_size / 1048576
                                      )?.toFixed(1)}
                                      MB
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="center"
                                    >
                                      {el?.status}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="wrapText"
                                      align="center"
                                    >
                                      {Moment(el.created_at).format(
                                        "MMM Do YYYY, h:mm:ss A"
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
              </div>
            </div>
          </Container>
        )}
        <Footer />
        <ToastContainer />
        <Backdrop
          style={{
            zIndex: "10",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default UploadZone;
