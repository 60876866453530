import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import MyLoader from "../components/ContentLoader";
import { Col, Container, Row } from "react-bootstrap";
import backLogo from "../assets/images/back.png";
import refreshLogo from "../assets/images/refresh.png";
import axios from "axios";
import {
  CALIBRATE,
  CLEAR_CHANNEL_FEED,
  CLEAR_FEED_PVD,
  GET_CALIBRATION_CHANNEL,
  GET_CHANNEL_RAW_DATA,
  RESET_CALIBRATE,
  SAVE_CALIBRATE,
  UPDATE_CHANNEL_KEY,
  serverURL,
} from "../shared/ApiURLs";
import {
  InputLabel,
  Button,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { ReactSelect } from "../components/ReactSelect";
// import Table from "react-bootstrap/Table";
import { toast, ToastContainer } from "react-toastify";
import deletICO from "../assets/images/delete.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import saveIcon from "../assets/images/save.png";
import { Switch, FormControlLabel } from "@material-ui/core";
import "../App.css";

function Calibration(props) {
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [channelData, setChannelData] = useState([]);
  const [channel, setChannel] = useState(null);
  const [rawvoltage, setRawVoltage] = useState("");
  const [actualVoltage, setActualVoltage] = useState("");
  const [rawCurrent, setRawCurrent] = useState("");
  const [actualCurrent, setActualCurrent] = useState("");
  const [tableData, setTableData] = useState([]);
  const [result, setResult] = useState({});
  const [isModalOpen, setisModalOpen] = useState(false);
  const [alertModalContent, setalertModalContent] = useState({
    heading: "",
    text: "",
    onAgree: null,
  });
  const [checked, setChecked] = React.useState(false);
  const [key, setKey] = React.useState("");
  const [selectModel, setSelectModel] = useState("");
  const [refInstrument, setRefInstrument] = useState("");
  const [selectModelLists, setSelectModelLists] = useState([]);
  const [refInstrumentLists, setRefInstrumentLists] = useState([]);

  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    getChannelData();
    const interval = setInterval(() => {
      getRawData();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [channel]);

  useEffect(() => {
    getRawData();
    setTableData([]);
    setResult({});

    setChecked(channel?.is_private);
    setKey(channel?.key);
  }, [channel]);

  const getChannelData = async () => {
    await axios
      .get(GET_CALIBRATION_CHANNEL)
      .then((res) => {
        // console.log("resData", res);
        // console.log("response", res.data.channels);
        if (res.data.channels) {
          let temp = [];
          res.data.channels.forEach((el, index) => {
            temp.push({
              label: el.name,
              value: el.id,
              key: el.write_api_key,
              isCalibrated: el.is_calibrated,
              is_private: el.is_private,
            });
          });
          setChannelData(temp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  const getRawData = () => {
    if (channel == null) {
      //console.log("firstchannel", channel);
    } else {
      axios
        .get(GET_CHANNEL_RAW_DATA(channel.value))
        .then((res) => {
          // console.log("resDataRaw", res.data);
          setRawVoltage(res.data.raw_volatage);
          setRawCurrent(res.data.raw_current);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // const response = axios.get(GET_CHANNEL_RAW_DATA)
  };

  const backBtnHandler = () => {
    return props.history.replace("/channels");
  };

  const handleAddData = () => {
    let arr = tableData;
    arr.push({
      v_raw: parseFloat(rawvoltage)?.toFixed(2),
      v_act: parseFloat(actualVoltage)?.toFixed(2),
      c_raw: parseFloat(rawCurrent)?.toFixed(2),
      c_act: parseFloat(actualCurrent)?.toFixed(2),
    });

    setTableData([...arr]);
    setActualCurrent("");
    setActualVoltage("");
    getRawData();
  };

  //console.log("table", tableData);

  const handleDelete = (index) => {
    let temp = tableData.filter((el, i) => i !== index);
    setTableData([...temp]);
  };

  const onCalibrate = () => {
    setLoading(true);
    axios
      .post(CALIBRATE, {
        channel_id: channel.value,
        data: tableData,
      })
      .then((res) => {
        toast.success("Calibration Successfull");
        setResult(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Calibration Failed");
        setLoading(false);
      });
  };

  const handleClearChannelFeeds = () => {
    closetAlertModel();
    setLoading(true);
    const data = { channel_id: channel.value };
    axios
      .post(CLEAR_FEED_PVD, data)
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Failed to clear channel feed!");
      });
  };

  const opentAlertModel = (data) => {
    setisModalOpen(true);
    setalertModalContent(data);
  };

  const closetAlertModel = () => {
    setisModalOpen(false);
    setalertModalContent({
      heading: "",
      text: "",
      onAgree: null,
    });
  };

  const saveResult = () => {
    setLoading(true);
    const data = { channel_id: channel.value, result };
    axios
      .post(SAVE_CALIBRATE, data)
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Failed to save result in channel!");
      });
  };

  const resetResult = () => {
    setLoading(true);
    const data = { channel_id: channel.value };
    axios
      .post(RESET_CALIBRATE, data)
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Failed reset result in channel!");
      });
  };

  const updateChannelKey = () => {
    if (channel.value) {
      setLoading(true);
      const data = {
        channel_id: channel.value,
        api_key: key,
        is_private: checked,
      };
      axios
        .post(UPDATE_CHANNEL_KEY, data)
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((e) => {
          setLoading(false);
          let msg = "Something went wrong while updating channel api key";
          if (e.response.data.message) {
            msg = e.response.data.message;
          }
          toast.error(msg);
        });
    }
  };

  const selectModelList = () => {
    axios
      .get(
        `${serverURL}/channels/get_channels_models_for_calibration_certificate`,
        {
          Authorization: `Bearer ${token}`,
        }
      )
      .then((res) => {
        //console.log("resModel", res.data);
        setSelectModelLists(res.data.models);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const refInstrumentList = () => {
    axios
      .get(
        `${serverURL}/channels/get_ref_inst_data_for_calibration_certificate`,
        {
          Authorization: `Bearer ${token}`,
        }
      )
      .then((res) => {
        //console.log("resRef", res.data);
        setRefInstrumentLists(res.data.instrument);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDownloadCertificate = () => {
    let data = {
      channel_model_id: selectModel.value,
      ref_inst_id: refInstrument.value,
      channel_id: channel.value,
    };
    //console.log("res",data)
    axios
      .post(`${serverURL}/channels/download_calibration_certificate`, data, {
        responseType: "blob",
        Authorization: `Bearer ${token}`,
      })
      .then((response) => {
        //console.log("responserr1",response.status);
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const timeStamp = new Date().getTime();
          const fileName = `${channel.label}_CC_${timeStamp}.xlsx`;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
          // this.setState({ loading: false });
          toast.success("Certificate Generated Successfully");
        }
      })
      .catch((e, res) => {
        //this.setState({ loading: false });
        //console.log("responserr",res);
        //toast.error(e?.response);
        toast.error("Something Went Wrong, Please Try Again!");
        //this.handleToast("error", `Something Went Wrong, Please Try Again!`);
      });
  };

  useEffect(() => {
    selectModelList();
    refInstrumentList();
  }, []);

  return (
    <div className="main-wrapper">
      <Header {...props} />
      <ToastContainer />
      <Backdrop
        style={{
          zIndex: "10",
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.4)",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container fluid>
          <div className="mb-3 d-flex">
            <div>
              <img
                src={backLogo}
                alt="back"
                className="backBtn"
                onClick={backBtnHandler}
              />
            </div>
            <div>
              <h4 className="ml-2 mt-1">
                <b>Calibration Zone</b>
              </h4>
            </div>
          </div>

          <div className="my-1">
            <Row sm={12} className="custom_card m-1 p-4">
              <Col sm={6}>
                <Row>
                  <Col md={7}>
                    <InputLabel>
                      <b>Channel:</b>
                    </InputLabel>
                    <ReactSelect
                      data={channelData}
                      value={channel}
                      placeholder={"Select Channel..."}
                      isLoading={false}
                      handleChange={setChannel}
                    />
                  </Col>

                  <Col md={5} className="px-0">
                    <input
                      type="text"
                      className="form-control mt-4  w-100"
                      style={{ color: channel?.isCalibrated ? "green" : "red" }}
                      disabled={true}
                      value={
                        channel?.isCalibrated ? "CALIBRATED" : "NOT-CALIBRATED"
                      }
                    />
                  </Col>
                </Row>
                <div>
                  <Row className="mt-3">
                    <Col md={7}>
                      <input
                        type="text"
                        onChange={(e) => setKey(e.target.value)}
                        className="form-control"
                        value={key}
                      />
                    </Col>
                    <Col md={2}>
                      <FormControlLabel
                        className="d-flex my-0 w-100"
                        disabled={!channel?.value}
                        control={
                          <Switch
                            className="form-control"
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={checked ? "Private" : "Public"}
                      />
                    </Col>
                    <Col md={3}>
                      <Button
                        color="primary"
                        variant="outlined"
                        className="ml-2 w-100"
                        disabled={!channel?.value}
                        onClick={updateChannelKey}
                      >
                        Save
                        <img
                          src={saveIcon}
                          alt="save"
                          className="ml-2"
                          style={
                            !channel?.value ? { filter: "opacity(0.5)" } : {}
                          }
                        />
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div
                  className="w-100 mt-3"
                  style={{ borderRadius: "4px", overflow: "hidden" }}
                >
                  <table className="table table-striped w-100">
                    <thead className="thead-dark">
                      <tr>
                        <th align="left" className="p-2">
                          Raw Voltage
                        </th>
                        <th align="left" className="p-2">
                          Actual Voltage
                        </th>
                        <th align="left" className="p-2">
                          Raw Current
                        </th>
                        <th align="left" className="p-2">
                          Actual Current
                        </th>
                        <th align="left" className="p-2"></th>
                        <th align="left" className="p-2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          component="th"
                          className="wrapText p-1"
                          align="left"
                        >
                          <input
                            type="number"
                            className="w-100 form-control m-0"
                            value={rawvoltage}
                            disabled={true}
                          />
                        </td>

                        <td
                          component="th"
                          className="wrapText p-1"
                          align="left"
                        >
                          <input
                            type="number"
                            className="w-100 form-control m-0"
                            value={actualVoltage}
                            onChange={(e) => setActualVoltage(e.target.value)}
                          />
                        </td>

                        <td
                          component="th"
                          className="wrapText p-1"
                          align="center"
                        >
                          <input
                            type="number"
                            className="w-100 form-control m-0"
                            value={rawCurrent}
                            disabled={true}
                          />
                        </td>
                        <td
                          component="th"
                          className="wrapText p-1"
                          align="center"
                        >
                          <input
                            type="number"
                            className="w-100 form-control m-0"
                            value={actualCurrent}
                            onChange={(e) => setActualCurrent(e.target.value)}
                          />
                        </td>
                        <td
                          component="th"
                          className="text-center p-1"
                          align="left"
                        >
                          <img
                            className="mx-auto mt-1"
                            style={{ cursor: "pointer" }}
                            src={refreshLogo}
                            alt="refresh"
                            onClick={getRawData}
                          />
                        </td>
                        <td className="p-1">
                          <Button
                            disabled={!actualVoltage && !actualCurrent}
                            className=""
                            variant="contained"
                            color="primary"
                            onClick={handleAddData}
                          >
                            Add
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="btn btn-warning" onClick={resetResult}>
                  Reset Calibration
                </button>
                {channel?.isCalibrated ? (
                  <div className="generate_certification">
                    <div>
                      <h3>Generate Certificate</h3>
                    </div>

                    <div className="d-flex ">
                      <div className="mt-2 w-50">
                        <ReactSelect
                          data={selectModelLists.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))}
                          value={selectModel}
                          placeholder={"Select-Model"}
                          handleChange={(e) => {
                            setSelectModel(e);
                          }}
                        />
                      </div>
                      <div className="mt-2 w-50">
                        <ReactSelect
                          data={refInstrumentLists.map(({ id, make }) => ({
                            value: id,
                            label: make,
                          }))}
                          value={refInstrument}
                          placeholder={"Ref-Instrument"}
                          handleChange={(e) => {
                            setRefInstrument(e);
                          }}
                        />
                      </div>
                      <div className="mt-2">
                        <Button
                          disabled={selectModel === "" || refInstrument === ""}
                          className="h-100"
                          variant="contained"
                          color="primary"
                          onClick={handleDownloadCertificate}
                        >
                          Download
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>

              <Col sm={6}>
                {tableData.length !== 0 && (
                  <div
                    className=" px-3 mt-1 py-3"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <TableContainer style={{ color: "black" }}>
                      <Table aria-label="CustomTable">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" className="p-1">
                              Raw Voltage
                            </TableCell>
                            <TableCell align="center" className="p-1">
                              Actual Voltage
                            </TableCell>
                            <TableCell align="center" className="p-1">
                              Raw Current
                            </TableCell>
                            <TableCell align="center" className="p-1">
                              Actual Current
                            </TableCell>
                            <TableCell align="center" className="p-1">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData.map((el, index) => {
                            console.log("el", el);
                            return (
                              <TableRow key={el?.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="p-1"
                                  align="center"
                                >
                                  {el.v_raw}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="p-1"
                                  align="center"
                                >
                                  {el.v_act}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="p-1"
                                  align="center"
                                >
                                  {el?.c_raw}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="p-1"
                                  align="center"
                                >
                                  {el.c_act}
                                </TableCell>
                                <TableCell align="center" className="p-1">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={deletICO}
                                    alt="delete"
                                    onClick={() => {
                                      handleDelete(index);
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
                {tableData.length > 5 && (
                  <div className="my-3">
                    <div>
                      <button
                        className="btn btn-success"
                        variant="contained"
                        color=""
                        onClick={onCalibrate}
                      >
                        Calibrate
                      </button>
                      {Object.keys(result).length > 0 && (
                        <>
                          <button
                            className="btn btn-success mx-2"
                            onClick={saveResult}
                          >
                            Save Result
                          </button>

                          <button
                            className="btn btn-danger mx-2"
                            onClick={() =>
                              opentAlertModel({
                                heading:
                                  "You want to clear all feeds of this channel?",
                                text: "You will loose all feeds of this Channel.",
                                onAgree: handleClearChannelFeeds,
                              })
                            }
                          >
                            Clear Channel Feed Data
                          </button>
                        </>
                      )}
                    </div>

                    {Object.keys(result).length > 0 && (
                      <div
                        className="my-3"
                        style={{ borderRadius: "4px", overflow: "hidden" }}
                      >
                        <table className="table table-bordered">
                          <thead className="thead-dark p-0">
                            <tr>
                              <th align="center" className="text-center p-2">
                                m_v
                              </th>
                              <th align="center" className="text-center p-2">
                                c_v
                              </th>
                              <th align="center" className="text-center p-2">
                                R2_v
                              </th>
                              <th align="center" className="text-center p-2">
                                m_c
                              </th>
                              <th align="center" className="text-center p-2">
                                c_c
                              </th>
                              <th align="center" className="text-center p-2">
                                R2_c
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                component="th"
                                className="wrapText p-1"
                                align="center"
                              >
                                <input
                                  type="number"
                                  className="w-100 form-control m-0"
                                  value={parseFloat(result?.m_v)?.toFixed(4)}
                                  disabled={true}
                                />
                              </td>
                              <td
                                component="th"
                                className="wrapText p-1"
                                align="center"
                              >
                                <input
                                  type="number"
                                  className="w-100 form-control m-0"
                                  value={parseFloat(result?.c_v)?.toFixed(4)}
                                  disabled={true}
                                />
                              </td>

                              <td
                                component="th"
                                className="wrapText p-1"
                                align="center"
                              >
                                <input
                                  type="number"
                                  className="w-100 form-control m-0"
                                  value={parseFloat(result?.r_v)?.toFixed(4)}
                                  disabled={true}
                                />
                              </td>

                              <td
                                component="th"
                                className="wrapText p-1"
                                align="center"
                              >
                                <input
                                  type="number"
                                  className="w-100 form-control m-0"
                                  value={parseFloat(result?.m_c)?.toFixed(4)}
                                  disabled={true}
                                />
                              </td>

                              <td
                                component="th"
                                className="wrapText p-1"
                                align="center"
                              >
                                <input
                                  type="number"
                                  className="w-100 form-control m-0"
                                  value={parseFloat(result?.c_c)?.toFixed(4)}
                                  disabled={true}
                                />
                              </td>
                              <td
                                component="th"
                                className="wrapText p-1"
                                align="center"
                              >
                                <input
                                  type="number"
                                  className="w-100 form-control m-0"
                                  value={parseFloat(result?.r_c)?.toFixed(4)}
                                  disabled={true}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      )}

      <Dialog
        open={isModalOpen}
        onClose={closetAlertModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertModalContent.heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertModalContent.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closetAlertModel} color="primary">
            Disagree
          </Button>
          <Button onClick={alertModalContent.onAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Calibration;
