import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="grey"
    {...props}
  >
    <rect x="10" y="8" rx="3" ry="3" width="90" height="6" /> 
    <rect x="10" y="26" rx="3" ry="3" width="90" height="6" /> 
    <rect x="11" y="100" rx="3" ry="3" width="380" height="6" /> 
    <rect x="11" y="127" rx="3" ry="3" width="380" height="6" /> 
    <rect x="11" y="80" rx="3" ry="3" width="380" height="6" /> 
    <rect x="11" y="50" rx="3" ry="3" width="380" height="6" /> 
    {/* <rect x="11" y="164" rx="3" ry="3" width="178" height="6" />  */}
  </ContentLoader>
)

export default MyLoader

