import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  DOWNLOAD_EKO_DATA,
  GET_EKO_DATA,
  VERIFY_TOKEN,
} from "../shared/ApiURLs";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../components/Footer";
import { toast, ToastContainer } from "react-toastify";
import Header from "../components/Header";
import Moment from "moment";
import DateRangePickerModal from "../components/DateRangePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const EkoLogger = (props) => {
  const classes = useStyles();

  const [ekoData, setEkoData] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenValidated, setTokenValidated] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: yesterday,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [site, setSite] = useState(null);

  const token_req_msg = "Token is required to access this page!";
  const token_is_invalid = "Previously entered token is invalid, Try again";

  useEffect(() => {
    let token_ = props?.match?.params?.token;
    if (!token_) {
      return openPromptAndSetToken(token_req_msg);
    }

    console.log(token_);
    verifyToken(token_);
  }, []);

  useEffect(() => {
    const gameStartInternal = setInterval(() => {
      if (token || token !== "null") {
        return verifyToken(token);
      }
    }, 300000);

    return () => {
      clearInterval(gameStartInternal);
    };
  }, [token]);

  const openPromptAndSetToken = (message) => {
    if (!loading) {
      let token_ = prompt(message);
      if (!token_ || token_ === "null") {
        return openPromptAndSetToken(token_is_invalid);
      }

      return verifyToken(token_);
    }
  };

  const verifyToken = (token_) => {
    setLoading(true);
    axios
      .get(VERIFY_TOKEN(token_))
      .then((res) => {
        if (res.data.success) {
          setToken(token_);
          getEkoData(token_);
          setTokenValidated(true);
          setSite(res.data.site);
        } else {
          setLoading(false);
          setTokenValidated(false);
          openPromptAndSetToken(token_is_invalid);
        }
      })
      .catch((error) => {
        setLoading(false);
        openPromptAndSetToken(token_is_invalid);
      });
  };

  const getEkoData = (token) => {
    axios
      .get(GET_EKO_DATA(token))
      .then((res) => {
        setEkoData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const downloadData = () => {
    let startDate = new Date(dateRange[0].startDate);
    let endDate = new Date(dateRange[0].endDate);

    let data = {
      token,
      startDate: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
      },
      endDate: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
      },
    };
    setLoading(true);
    axios
      .post(DOWNLOAD_EKO_DATA, data, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const timeStamp = new Date().getTime();
        const fileName = `eko_${timeStamp}.xlsx`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e?.response);
        if (e?.response?.status === 404) {
          return toast.error("Not enough data to generate excel");
        } else {
          return toast.error("Something went wrong, Please try again");
        }
      });
  };

  return (
    <div className="main-wrapper overflow-hidden">
      <ToastContainer />

      {tokenValidated && ekoData.length && (
        <Container className="overflow-auto">
          <h1>EKO Data:</h1>
          <h6>Current Token: {token}</h6>
          <h6>Site Name: {site?.name}</h6>

          <div className="d-flex justify-content-between mt-2 mb-4">
            <h5>Last 15 values:</h5>

            <div className="d-flex justify-content-between">
              <input
                label="Date Range"
                type="text"
                className="form-control"
                readOnly
                onClick={() => setShowDatePicker(true)}
                value={`${Moment(dateRange[0].startDate).format(
                  "MMM D, YYYY"
                )} - ${Moment(dateRange[0].endDate).format("MMM D, YYYY")}`}
              />
              <button className="btn btn-success" onClick={downloadData}>
                Download
              </button>
            </div>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Device Created At</th>
                <th scope="col">Server Created At</th>
                <th scope="col">POA_C</th>
                <th scope="col">Ts</th>
                <th scope="col">ANGLE_X</th>
                <th scope="col">ANGLE_Y</th>
                <th scope="col">POA_R</th>
                <th scope="col">mV</th>
                <th scope="col">Ti</th>
                <th scope="col">RH</th>
              </tr>
            </thead>
            <tbody>
              {ekoData.map((row) => (
                <tr>
                  <td>
                    {Moment(new Date(row.device_created_at)).format(
                      "MMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td>
                    {Moment(new Date(row.created_at)).format(
                      "MMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td>{parseFloat(row.POA_C).toFixed(3)}</td>
                  <td>{parseFloat(row.Ts).toFixed(3)}</td>
                  <td>{parseFloat(row.ANGLE_X).toFixed(3)}</td>
                  <td>{parseFloat(row.ANGLE_Y).toFixed(3)}</td>
                  <td>{parseFloat(row.POA_R).toFixed(3)}</td>
                  <td>{parseFloat(row.mV).toFixed(3)}</td>
                  <td>{parseFloat(row.Ti).toFixed(3)}</td>
                  <td>{parseFloat(row.RH).toFixed(3)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      )}

      {tokenValidated && ekoData.length === 0 && (
        <h1 className="text-center">No EKO Data Found For Entered Token</h1>
      )}

      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DateRangePickerModal
        show={showDatePicker}
        onHide={() => setShowDatePicker(false)}
        date_range={dateRange}
        handleDateRangeChange={(item) => setDateRange([item?.selection])}
      />
    </div>
  );
};

export default EkoLogger;
