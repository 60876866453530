import React from "react";
import "./Notifications.css";
import closeIcon from "../assets/images/close.png";
import { Button, CardActionArea, CardContent } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";

const Notification = (props) => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="position-relative">
        <div className="notification_container">
          {!props.data.length ? (
            <p className="text-center p-2">
              <b>No Notifications Found</b>
            </p>
          ) : (
            <React.Fragment>
              <div className="d-flex justify-content-end">
                <Button
                  size="small"
                  onClick={() => props.onNotificationDelete("multi", null)}
                >
                  <small className="text-light">
                    <b>Clear All</b>
                  </small>
                </Button>
              </div>
              {props.data.map((item) => {
                console.log(JSON.parse(item?.description));
                let desc = JSON.parse(item?.description);
                return (
                  <CardActionArea key={item?.id}>
                    <CardContent
                      className="m-1 p-2"
                      style={{
                        color: "black",
                        borderRadius: 4,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        <img
                          className="c-pointer"
                          src={closeIcon}
                          alt="closeIcon"
                          style={{
                            position: "absolute",
                            right: "2%",
                            zIndex: 1000,
                          }}
                          onClick={() => {
                            props.onNotificationDelete("single", item?.id);
                          }}
                        />
                        <h6 className="d-flex mb-1">
                          <b>{item?.title}</b>
                          <small className="text-muted">
                            <b
                              style={{
                                marginLeft: 2,
                              }}
                            >
                              ({desc?.rules})
                            </b>
                          </small>
                        </h6>
                        <p
                          className="text-muted my-0 text-left"
                          onClick={() =>
                            props.history.push(`/channel/${item.channel}`)
                          }
                        >
                          From{" "}
                          <b>
                            {moment(new Date(desc?.from)).format(
                              "MMMM Do , H:mm:ss"
                            )}
                          </b>{" "}
                          to{" "}
                          <b>
                            {moment(new Date(desc?.to)).format(
                              "MMMM Do , H:mm:ss"
                            )}
                          </b>{" "}
                          in {desc?.channel_name}.
                        </p>

                        <small className="d-flex justify-content-end">
                          <small className="text-muted">
                            {moment(new Date(item.created_at)).calendar()}
                          </small>
                        </small>
                      </div>
                    </CardContent>
                  </CardActionArea>
                );
              })}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
