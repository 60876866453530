import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  aggregateFunc,
  combiTimeScale,
  combiWMSFeeds,
  datediff,
  parseDate,
  timeScale,
} from "../utils/Helpers";
import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import DateRangePickerModal from "../components/DateRangePicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Select Visualization", "Add Settings"];
}

export const SelectVisualizationModal = (props) => {
  const [visualtionType, setVisualtionType] = useState("numeric_value");
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const defaultDateRange = [
    {
      startDate: yesterday,
      endDate: today,
      key: "selection",
    },
  ];
  // Numeric Widget States
  const [numeric_input_name, setnumeric_input_name] = useState("");
  const [numeric_input_unit, setnumeric_input_unit] = useState("");
  const [numeric_input_field, setnumeric_input_field] =
    useState("Select Field");
  const [numeric_input_min, setnumeric_input_min] = useState("");
  const [numeric_input_max, setnumeric_input_max] = useState("");

  // Graph Widget States
  const [graph_title, setgraph_title] = useState("");
  const [graph_field, setgraph_field] = useState("Select Field");

  const [graph_x_label, setgraph_x_label] = useState("");
  const [graph_y_label, setgraph_y_label] = useState("");
  const [graph_point, setgraph_point] = useState(0);

  const [graph_timescale, setgraph_timescale] = useState("Select Function");
  const [graph_function, setgraph_function] = useState("Select Function");

  const [graph_y_min, setgraph_y_min] = useState(0);
  const [graph_y_max, setgraph_y_max] = useState(0);

  const [modalFirstLoad, setModalFirstLoad] = useState(true);

  // Combination Graph Widget States

  const [combi_title, setCombiTitle] = useState("");
  const [combi_feed, setCombi_feed] = useState("Select Feed Field");
  const [combi_wms, setCombi_wms] = useState("");

  const [combi_x_label, setcombi_x_label] = useState("");

  const [combi_y1_label, setcombi_y1_label] = useState("");
  const [combi_y2_label, setcombi_y2_label] = useState("");

  const [combi_date_range, setcombi_date_range] = useState(defaultDateRange);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [combi_timescale, setcombi_timescale] = useState(
    combiTimeScale[0].value
  );

  const [combiTimeScaleArr, setCombiTimeScaleArr] = useState(combiTimeScale);

  const [combi_function, setcombi_function] = useState("Select Function");

  const [combi_y1_min, setcombi_y1_min] = useState(0);
  const [combi_y1_max, setcombi_y1_max] = useState(0);
  const [combi_y2_min, setcombi_y2_min] = useState(0);
  const [combi_y2_max, setcombi_y2_max] = useState(0);

  useEffect(() => {
    if (modalFirstLoad) {
      handleReset();
      setcombi_timescale(combiTimeScaleArr[0].value);
      setgraph_timescale(timeScale[0].value);

    }
    console.log("from blank part",graph_timescale)
    if("Select Timescale"===graph_timescale){
      setgraph_timescale(timeScale[0].value);
    }
    setnumeric_input_field(props.availableNumericFields[0]);
    console.log("TimeScale Arr", combiTimeScaleArr);
    console.log("TimeScale value", combi_timescale);
  }, [
    props.availableNumericFields,
    combi_timescale,
    graph_timescale,
    combiTimeScaleArr,
  ]);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return select_visualtion();
      case 1:
        return show_visualtion_setting();
      default:
        return;
    }
  };

  

  const select_visualtion = () => {
    return (
      <div className="px-3">
        <FormControl className="w-100" component="fieldset">
          <RadioGroup
            value={visualtionType}
            onChange={handleVisualizationChange}
          >
            <div className="d-flex justify-content-around">
              <div>
                {" "}
                <FormControlLabel
                  key="numeric_value"
                  value="numeric_value"
                  control={<Radio />}
                  label="Numeric Value"
                  disabled={!props.availableNumericFields.length}
                />
              </div>
              <div>
                <FormControlLabel
                  key="time_value_chart"
                  value="time_value_chart"
                  control={<Radio />}
                  label="Chart"
                  disabled={!props.availableGraphFields.length}
                />
              </div>
              <div>
                <FormControlLabel
                  key="combi_graph_chart"
                  value="combi_graph_chart"
                  control={<Radio />}
                  label="Combination Graph"
                  disabled={!props.availableCombiGraphFields.length}
                />
              </div>
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const show_visualtion_setting = () => {
    if (visualtionType === "numeric_value") {
      return numeric_value_settings();
    } else if (visualtionType === "time_value_chart") {
      return time_value_chart_settings();
    } else if (visualtionType === "combi_graph_chart") {
      return combi_graph_value_settings();
    }
  };

  const numericFieldSelect = () => {
    return (
      <FormControl variant="outlined" className="w-50 m-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Select Field
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={numeric_input_field}
          onChange={(e) => setnumeric_input_field(e.target.value)}
          label="Select Field"
        >
          <MenuItem key="Select Field" value="Select Field">
            Select Field
          </MenuItem>
          {props.availableNumericFields.map((el, index) => (
            <MenuItem key={index} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const graphFieldSelect = () => {
    return (
      <FormControl variant="outlined" className="w-50 m-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Select Field
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={graph_field}
          onChange={(e) => setgraph_field(e.target.value)}
          label="Select Field"
        >
          <MenuItem key="Select Field" value="Select Field">
            Select Field
          </MenuItem>
          {props.availableGraphFields.map((el, index) => (
            <MenuItem key={index} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const onTimeScaleChange = (e, type) => {

    if (type === "graph") {
      setgraph_timescale(e.target.value);
      e.target.value === 0
        ? setgraph_function("Select Function")
        : console.log("check work");
    } else if (type === "combi_graph") {
      setcombi_timescale(e.target.value);
      e.target.value === 0
        ? setcombi_function("Select Function")
        : console.log("check work");
    }
  };

  const timeScaleSelect = (type) => {
    if (type === "graph") {
      return (
        <FormControl variant="outlined" className="w-50 m-2">
          <InputLabel id="demo-simple-select-outlined-label">
            Timescales
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select Timescale"
            value={graph_timescale}
            onChange={(e) => onTimeScaleChange(e, type)}
          >
            {timeScale.map((el, index) => (
              <MenuItem key={index} value={el.value}>
                {el.field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (type === "combi_graph") {
      return (
        <FormControl variant="outlined" className="w-50 m-2">
          <InputLabel id="demo-simple-select-outlined-label">
            Timescales
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select Timescale"
            value={combi_timescale}
            onChange={(e) => onTimeScaleChange(e, type)}
          >
            {combiTimeScaleArr.map((el, index) => (
              <MenuItem key={index} value={el.value} disabled={el.is_disabled}>
                {el.field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const disableTimeScaleOnDates = (day) => {
    console.log("day", day);
    let newTimeScale = combiTimeScale;
    if (day >= 1 && day <= 2) {
      console.log("at 1T");
      newTimeScale[1]["is_disabled"] = false;
      newTimeScale[2]["is_disabled"] = false;
      newTimeScale[3]["is_disabled"] = false;
      newTimeScale[4]["is_disabled"] = false;
      newTimeScale[5]["is_disabled"] = false;
    }
    if (day >= 3 && day <= 7) {
      newTimeScale[1]["is_disabled"] = true;
      newTimeScale[2]["is_disabled"] = false;
      newTimeScale[3]["is_disabled"] = false;
      newTimeScale[4]["is_disabled"] = false;
      newTimeScale[5]["is_disabled"] = false;
    }
    if (day >= 8 && day <= 31) {
      newTimeScale[1]["is_disabled"] = true;
      newTimeScale[2]["is_disabled"] = true;
      newTimeScale[3]["is_disabled"] = false;
      newTimeScale[4]["is_disabled"] = false;
      newTimeScale[5]["is_disabled"] = false;
    }
    if (day > 31) {
      newTimeScale[1]["is_disabled"] = true;
      newTimeScale[2]["is_disabled"] = true;
      newTimeScale[3]["is_disabled"] = true;
      newTimeScale[4]["is_disabled"] = true;
      newTimeScale[5]["is_disabled"] = false;
    }
    console.log("new timescale", newTimeScale);
    setCombiTimeScaleArr(newTimeScale);
    return true;
  };

  // const changeDisbaleTimescale = (value, is_disabled) => {
  //   let newTimeScale = combiTimeScaleArr;

  //   // combiTimeScaleArr.forEach((el, index, theArray) =>
  //   //   el.value === value
  //   //     ? (theArray[index].is_disabled = is_disabled)
  //   //     : (theArray[index].is_disabled = !is_disabled)
  //   // );

  //   console.log("new timescale", newTimeScale);
  //   setCombiTimeScaleArr(newTimeScale);
  //   return true;
  // };

  const aggreFunctionSelect = (type) => {
    if (type === "graph") {
      return (
        <FormControl variant="outlined" className="w-50 m-2">
          <InputLabel id="demo-simple-select-outlined-label">
            Functions
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select Function"
            value={graph_function}
            onChange={(e) => setgraph_function(e.target.value)}
            disabled={graph_timescale === "0"}
          >
            <MenuItem key="Select Function" value="Select Function">
              Select Function
            </MenuItem>
            {aggregateFunc.map((el, index) => (
              <MenuItem key={index} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (type === "combi_graph") {
      return (
        <FormControl variant="outlined" className="w-50 m-2">
          <InputLabel id="demo-simple-select-outlined-label">
            Functions
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select Function"
            value={combi_function}
            onChange={(e) => setcombi_function(e.target.value)}
          >
            <MenuItem key="Select Function" value="Select Function">
              Select Function
            </MenuItem>
            {aggregateFunc.map((el, index) => (
              <MenuItem key={index} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const numeric_value_settings = () => {
    return (
      <div>
        <form noValidate autoComplete="off">
          <div className="d-flex">
            <TextField
              label="Name"
              variant="outlined"
              className="w-50 m-2"
              value={numeric_input_name}
              onChange={(e) => setnumeric_input_name(e.target.value)}
            />
            <TextField
              label="Unit"
              variant="outlined"
              className="w-50 m-2"
              value={numeric_input_unit}
              onChange={(e) => setnumeric_input_unit(e.target.value)}
            />
          </div>
          <div className="d-flex">
            {numericFieldSelect()}
            <TextField
              label="Min"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className=" m-2"
              value={numeric_input_min}
              onChange={(e) => setnumeric_input_min(e.target.value)}
            />
            <TextField
              label="Max"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className=" m-2"
              value={numeric_input_max}
              onChange={(e) => setnumeric_input_max(e.target.value)}
            />
          </div>
        </form>
      </div>
    );
  };

  const time_value_chart_settings = () => {
    return (
      <div>
        <form noValidate autoComplete="off">
          <div className="d-flex">
            <TextField
              label="Title"
              variant="outlined"
              className="w-50 m-2"
              value={graph_title}
              onChange={(e) => setgraph_title(e.target.value)}
            />
            {graphFieldSelect()}
          </div>

          <div className="d-flex">
            <TextField
              label="X-Label"
              variant="outlined"
              className="w-50 m-2"
              value={graph_x_label}
              onChange={(e) => setgraph_x_label(e.target.value)}
            />

            <TextField
              label="Y-Label"
              variant="outlined"
              className="w-50 m-2"
              value={graph_y_label}
              onChange={(e) => setgraph_y_label(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between">
            <TextField
              label="Y-Min"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="m-2"
              value={graph_y_min}
              onChange={(e) => setgraph_y_min(e.target.value)}
            />
            <TextField
              label="Y-Max"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="m-2"
              value={graph_y_max}
              onChange={(e) => setgraph_y_max(e.target.value)}
            />
            <TextField
              label="Data Points"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className=" m-2"
              value={graph_point}
              onChange={(e) => setgraph_point(e.target.value)}
            />
          </div>

          <div className="d-flex">
            {timeScaleSelect("graph")}
            {aggreFunctionSelect("graph")}
          </div>
        </form>
      </div>
    );
  };

  const combiGraphFieldSelect = (type) => {
    if (type === "feed") {
      return (
        <FormControl variant="outlined" className="w-50 m-2">
          <InputLabel id="demo-simple-select-outlined-label">
            Select Field
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={combi_feed}
            onChange={(e) => {
              setCombi_feed(e.target.value);
              e.target.value === ""
                ? setCombi_wms("Select Function")
                : console.log("check work");
            }}
            label="Select Field"
          >
            <MenuItem key="Select Feed Field" value="Select Feed Field">
              Select Feed Field
            </MenuItem>
            {props.availableCombiGraphFields.map((el, index) => (
              <MenuItem key={index} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (type === "wms") {
      return (
        <FormControl variant="outlined" className="w-50 m-2">
          <InputLabel id="demo-simple-select-outlined-label">
            Select WMS Field
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select WMS Field"
            value={combi_wms}
            onChange={(e) => {
              setCombi_wms(e.target.value);
            }}
            disabled={combi_feed === "Select Feed Field"}
          >
            {combiWMSFeeds.map((el, index) => (
              <MenuItem key={index} value={el.value}>
                {el.field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const combi_graph_value_settings = () => {
    return (
      <div>
        <form noValidate autoComplete="off">
          <div className="d-flex">
            {combiGraphFieldSelect("feed")}
            {combiGraphFieldSelect("wms")}
          </div>

          <div className="d-flex">
            <TextField
              label="Title"
              variant="outlined"
              className="w-50 m-2"
              value={combi_title}
              onChange={(e) => setCombiTitle(e.target.value)}
            />

            <TextField
              label="X-Label"
              variant="outlined"
              className="w-50 m-2"
              value={combi_x_label}
              onChange={(e) => setcombi_x_label(e.target.value)}
            />
          </div>

          <div className="d-flex">
            <TextField
              label="Y1-Label"
              variant="outlined"
              className="w-50 m-2"
              value={combi_y1_label}
              onChange={(e) => setcombi_y1_label(e.target.value)}
            />

            <TextField
              label="Y2-Label"
              variant="outlined"
              className="w-50 m-2"
              value={combi_y2_label}
              onChange={(e) => setcombi_y2_label(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between">
            <TextField
              label="Y1-Min"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="m-2"
              value={combi_y1_min}
              onChange={(e) => setcombi_y1_min(e.target.value)}
            />
            <TextField
              label="Y1-Max"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="m-2"
              value={combi_y1_max}
              onChange={(e) => setcombi_y1_max(e.target.value)}
            />

            <TextField
              label="Y2-Min"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="m-2"
              value={combi_y2_min}
              onChange={(e) => setcombi_y2_min(e.target.value)}
            />
            <TextField
              label="Y2-Max"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="m-2"
              value={combi_y2_max}
              onChange={(e) => setcombi_y2_max(e.target.value)}
            />
          </div>

          <div className="d-flex">
            <span className="d-flex w-50">
              <TextField
                label="Date Range"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                className="m-2 w-100"
                readOnly
                onClick={() => setShowDatePicker(true)}
                value={`${moment(combi_date_range[0]?.startDate).format(
                  "MMM D, YYYY"
                )} - ${moment(combi_date_range[0]?.endDate).format(
                  "MMM D, YYYY"
                )}`}
              />
            </span>
            <span className="d-flex w-50">
              {timeScaleSelect("combi_graph")}
              {aggreFunctionSelect("combi_graph")}
            </span>
          </div>
        </form>
      </div>
    );
  };

  const handleVisualizationChange = (event) => {
    setVisualtionType(event.target.value);
    console.log(visualtionType);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setModalFirstLoad(false);
    setActiveStep(0);
    resetNumericValues();
    resetGraphValues();
    resetCombiGraphValues();
  };

  const handleFinish = () => {
    if (visualtionType === "numeric_value") {
      let obj = {
        name: numeric_input_name,
        field: numeric_input_field,
        unit: numeric_input_unit,
        min: numeric_input_min,
        max: numeric_input_max,
      };
      console.log(obj);

      if (!validateNumericValues(obj))
        return props.handleToast("error", "Invalid Values");
      props.addNumericWidget(obj);
      handleReset();
      props.onHide();
    } else if (visualtionType === "time_value_chart") {
      const data = {
        title: graph_title,
        field: graph_field,
        x_label: graph_x_label,
        y_label: graph_y_label,
        point: parseInt(graph_point),
        timeScale: graph_timescale,
        function: graph_function,
        y_min: parseFloat(graph_y_min),
        y_max: parseFloat(graph_y_max),
      };
      console.log(data);
      if (!validateGraphValues(data))
        return props.handleToast("error", "Invalid Values");
      props.addGraphWidget(data);
      handleReset();
      props.onHide();
      return;
    } else if (visualtionType === "combi_graph_chart") {
      let startDate = new Date(combi_date_range[0].startDate);
      let endDate = new Date(combi_date_range[0].endDate);

      const data = {
        title: combi_title,
        feed_field: combi_feed,
        wms_field: combi_wms,
        x_label: combi_x_label,
        y1_label: combi_y1_label,
        y2_label: combi_y2_label,
        timeScale: combi_timescale,
        function: combi_function,
        y1_min: parseFloat(combi_y1_min),
        y1_max: parseFloat(combi_y1_max),
        y2_min: parseFloat(combi_y2_min),
        y2_max: parseFloat(combi_y2_max),
        startDate: {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          day: startDate.getDate(),
        },
        endDate: {
          year: endDate.getFullYear(),
          month: endDate.getMonth() + 1,
          day: endDate.getDate(),
        },
      };
      console.log(data);
      if (!validateCombiGraphValues(data))
        return props.handleToast("error", "Please fill all input fields!");

      data["function"] =
        data.function === "Select Function" ? null : data.function;
      props.addCombiGraphWidget(data);
      handleReset();
      props.onHide();
      return;
    }
    return;
  };

  const resetNumericValues = () => {
    setnumeric_input_name("");
    setnumeric_input_unit("");
    setnumeric_input_field(props.availableNumericFields[0]);
    setnumeric_input_min(0);
    setnumeric_input_max(0);
  };

  const resetGraphValues = () => {
    setgraph_title("");
    setgraph_field("Select Field");
    setgraph_x_label("");
    setgraph_y_label("");
    setgraph_point(0);
    setgraph_timescale("Select Timescale");
    setgraph_function("Select Function");
    setgraph_y_min(0);
    setgraph_y_max(0);
  };

  const resetCombiGraphValues = () => {
    setCombiTitle("");
    setCombi_feed("Select Feed Field");
    setCombi_wms("");
    setcombi_x_label("");
    setcombi_y1_label("");
    setcombi_y2_label("");
    setcombi_date_range(defaultDateRange);
    setcombi_function("Select Function");
    setcombi_y1_min(0);
    setcombi_y1_max(0);
    setcombi_y2_min(0);
    setcombi_y2_max(0);
    disableTimeScaleOnDates(1);
    setcombi_timescale(combiTimeScale[0].value);
  };

  const validateNumericValues = (data) => {
    if (
      data.name === "" ||
      data.field === "" ||
      data.field === "Select Field" ||
      data.field === undefined ||
      data.unit === "" ||
      data.unit === undefined ||
      data.unit === null ||
      data.min === null ||
      data.min === undefined ||
      data.min === null ||
      data.max === undefined
    ) {
      return false;
    }
    return true;
  };

  const validateGraphValues = (data) => {
    if (
      data.title === "" ||
      data.x_label === "" ||
      data.y_label === "" ||
      data.point === "" ||
      data.point < 0 ||
      data.field === "" ||
      data.field === "Select Field" ||
      data.field === undefined ||
      data.timeScale === "" ||
      data.timeScale === undefined ||
      data.function === "" ||
      data.function === undefined ||
      data.y_min === undefined ||
      data.y_min === null ||
      data.y_max === undefined ||
      data.y_max === null
    ) {
      return false;
    } else if (data.timeScale !== "0" && data.function === "Select Function") {
      return false;
    } else {
      return true;
    }
  };

  const validateCombiGraphValues = (data) => {
    if (
      data.title === "" ||
      data.x_label === "" ||
      data.y1_label === "" ||
      data.y2_label === "" ||
      data.feed_field === "" ||
      data.feed_field === "Select Feed Field" ||
      data.feed_field === undefined ||
      data.timeScale === "" ||
      data.timeScale === "0" ||
      data.timeScale === undefined ||
      data.function === "" ||
      data.function === undefined ||
      data.function === "Select Function" ||
      data.y1_min === undefined ||
      data.y1_min === null ||
      data.y1_max === undefined ||
      data.y1_max === null ||
      data.y2_min === undefined ||
      data.y2_min === null ||
      data.y2_max === undefined ||
      data.y2_max === null
    ) {
      return false;
    } else {
      return true;
    }
  };


  const handleDateRangeSelect = (item) => {
    setcombi_date_range([item?.selection]);
    setcombi_timescale(combiTimeScale[0].value);
    let startDate = new Date(item?.selection?.startDate);
    let endDate = new Date(item?.selection?.endDate);
    let day = datediff(parseDate(startDate), parseDate(endDate));
    disableTimeScaleOnDates(day);
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Visualization
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className="mt-0 pt-0"
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div>
            {activeStep !== steps.length ? (
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            {activeStep === steps.length ? (
              <div>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>

                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleFinish}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <Button
            onClick={() => {
              props.onHide();
              handleReset();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <DateRangePickerModal
        show={showDatePicker}
        onHide={() => setShowDatePicker(false)}
        date_range={combi_date_range}
        handleDateRangeChange={handleDateRangeSelect}
      />
    </React.Fragment>
  );
};
