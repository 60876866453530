import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import Moment from "moment";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import { Button, Tooltip } from "@material-ui/core";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GET_CHANNELS } from "../shared/ApiURLs";
import { addHeaderInAxios, checkSignature } from "../utils/Helpers";

import currentIcon from "../assets/images/currentIcon.png";
import tempIcon from "../assets/images/tempIcon.png";
import powerIcon from "../assets/images/powerIcon.png";
import humidityIcon from "../assets/images/humidityIcon.png";
import voltageIcon from "../assets/images/voltageIcon.png";
import ReactPaginate from "react-paginate";
import refreshIco from "../assets/images/refresh.png";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "white",
        border: "0.5px solid black",
      },
    },
  },
});

class Channels extends Component {
  state = {
    channels: [],
    filteredChannels: [],
    searchBarValue: "",
    apiRefreshRateMs: 15000,


    num_of_pages: 0,
    limit: 0,
    current_page: 0,
  };

  componentDidMount = () => {
    addHeaderInAxios(this.props)
      ? this.get_channels()
      : this.props.history.replace("/");
  };

  get_channels = () => {
    
    axios
      .get(GET_CHANNELS(this.state.current_page+1,this.state.searchBarValue))
      .then((res) => {
        this.setState({
          channels: res.data.data,
          filteredChannels: res.data.data,

          num_of_pages: res.data.num_of_pages,
            limit: res.data.limit,
        });
      })
      .catch((error) => {
        checkSignature(error);
      });
  };

  handlePageClick = (event) => {
    console.log(event);
    this.setState(
      {
        ...this.state,
        current_page: event.selected,
      },
      () => {
        this.get_channels();
      }
    );
  };
  handleSearch = () => {
    this.get_channels();
    // let newChannelsArr = this.state.channels.filter((el) => {
    //   console.log("tavi search",el)
    //   return String(el.channel.name)
    //     .toLowerCase()
    //     .includes(this.state.searchBarValue.toLowerCase());
    // });

    // this.setState({ filteredChannels: newChannelsArr });
    // return newChannelsArr;
  };

  setFilteredChannelToChannel = () =>
    this.setState({ filteredChannels: this.state.channels });

  loadChannels = () => {
    if (this.state.channels.length) {
      if (this.state.filteredChannels.length) {
        const cardStyleActive = { maxWidth: "100%", marginBottom: "1rem" };
        return this.state.filteredChannels.map((el) => {
          let { channel, feed } = el;
          return (
            <Col sm={12} key={channel.id}>
              <Card
                style={cardStyleActive}
                className={channel.status ? "borderSuccess" : "borderDanger"}
              >
                <Row>
                  <Col sm={6}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          <Link to={`channel/${channel.id}`}>
                            {channel.name}
                          </Link>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="div"
                        >
                          <Typography component="p">
                            <small>
                              <b>Channel Created At : </b>
                              {Moment(channel.created_at).format("MMM Do YYYY")}
                            </small>
                          </Typography>
                          <Typography component="p">
                            <small>
                              <b>Feed Updated At : </b>
                              {Moment(feed.created_at).format(
                                "MMM Do YYYY, h:mm:ss A"
                              )}
                            </small>
                          </Typography>
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Col>
                  <Col sm={6}>
                    <Row className="px-2 py-3">
                      <Col sm={12}>
                        <Button
                          size="small"
                          style={{
                            width: "47%",
                            marginRight: "10px",
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          <Link to={`channel/${channel.id}/private`}>
                            Private
                          </Link>
                        </Button>
                        <Button
                          size="small"
                          className="w-50"
                          variant="outlined"
                          color="primary"
                        >
                          <Link to={`channel/${channel.id}/public`}>
                            Public
                          </Link>
                        </Button>
                      </Col>
                      <Col sm={12} className="my-2">
                        <Button
                          size="small"
                          style={{
                            width: "47%",
                            marginRight: "10px",
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          <Link to={`channel/${channel.id}/settings`}>
                            Settings
                          </Link>
                        </Button>
                        <Button
                          size="small"
                          className="w-50"
                          variant="outlined"
                          color="primary"
                        >
                          <Link to={`channel/${channel.id}/sharing`}>
                            Sharing
                          </Link>
                        </Button>
                      </Col>
                      <Col sm={12}>
                        <Button
                          size="small"
                          style={{
                            width: "47%",
                            marginRight: "10px",
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          <Link to={`channel/${channel.id}/api_keys`}>
                            API Keys
                          </Link>
                        </Button>
                        <Button
                          size="small"
                          className="w-50"
                          variant="outlined"
                          color="primary"
                        >
                          <Link
                            to={`channel/${channel.id}/import_export`}
                            className="wrapText"
                          >
                            Data Import / Export
                          </Link>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="d-flex py-2">
                  <Tooltip
                    title={`Current : ${feed?.current?.toFixed(2)}`}
                    arrow
                  >
                    <Card className="px-2 py-1 mx-2 w-100  d-flex justify-content-between">
                      <span className="float-left">
                        <img src={currentIcon} alt="Current" />
                      </span>
                      <span className="float-right">
                        <b>{feed?.current?.toFixed(2)}</b>
                      </span>
                    </Card>
                  </Tooltip>
                  <MuiThemeProvider theme={theme}>
                    <Tooltip
                      title={`Voltage : ${feed?.voltage?.toFixed(2)}`}
                      arrow
                    >
                      <Card className="px-2 py-1 mx-2 w-100  d-flex justify-content-between">
                        <span className="float-left">
                          <img src={voltageIcon} alt="voltage" />
                        </span>
                        <span className="float-right">
                          <b>{feed?.voltage?.toFixed(2)}</b>
                        </span>
                      </Card>
                    </Tooltip>
                  </MuiThemeProvider>
                  <Tooltip title={`Power : ${feed?.power?.toFixed(2)}`} arrow>
                    <Card className="px-2 py-1 mx-2 w-100  d-flex justify-content-between">
                      <span className="float-left">
                        <img src={powerIcon} alt="powerIcon" />
                      </span>
                      <span className="float-right">
                        <b>{feed?.power?.toFixed(2)}</b>
                      </span>
                    </Card>
                  </Tooltip>
                  <Tooltip
                    title={`Temperature : ${feed?.temperature?.toFixed(2)}`}
                    arrow
                  >
                    <Card className="px-2 py-1 mx-2 w-100  d-flex justify-content-between">
                      <span className="float-left">
                        <img src={tempIcon} alt="tempIcon" />
                      </span>
                      <span className="float-right">
                        <b>{feed?.temperature?.toFixed(2)}</b>
                      </span>
                    </Card>
                  </Tooltip>
                  <Tooltip title={`Humidity : ${feed?.rh?.toFixed(2)}`} arrow>
                    <Card className="px-2 py-1 mx-2 w-100  d-flex justify-content-between">
                      <span className="float-left">
                        <img src={humidityIcon} alt="humidityIcon" />
                      </span>
                      <span className="float-right">
                        <b>{feed?.rh?.toFixed(2)}</b>
                      </span>
                    </Card>
                  </Tooltip>
                </div>
              </Card>
            </Col>
          );
        });
      } else {
        return (
          <Col md={12}>
            <h3 className="text-center">
              No Channels Found with name {this.state.searchBarValue}!
            </h3>
            <div className="text-center mt-2">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.setFilteredChannelToChannel}
              >
                Get All Chennels
              </Button>
            </div>
          </Col>
        );
      }
    } else {
      return (
        <Col md={12}>
          <h3 className="text-center">No Channels Found!</h3>
        </Col>
      );
    }
  };

  refreshInterval = setInterval(() => {
    this.get_channels();
  }, this.state.apiRefreshRateMs);

  componentWillUnmount = () => {
    clearInterval(this.refreshInterval);
  };

  render() {
    return (
      <div className="main-wrapper">
        <Header {...this.props} />

        <Row style={{ margin: "20px" }}>
          <Col sm={8}>
            <div className="mb-3">
              <h2>My Channels</h2>
            </div>

            <Row>
              <Col sm={12} md={3} className="mb-3">
                <Button
                  className="py-2 w-100"
                  variant="contained"
                  color="primary"
                >
                  <Link className="makeTextFromA" to="/create_channel">
                    Create Channel
                  </Link>
                </Button>
              </Col>
              <Col sm={12} md={9} className="mb-5">
                <div className="mb-3">
                  <InputGroup className="search-bar">
                    <FormControl
                      placeholder="Search Channel by Name"
                      aria-label="Search Channel by Name"
                      aria-describedby="basic-addon2"
                      value={this.state.searchBarValue}
                      onChange={(e) => {
                        this.setState({ searchBarValue: e.target.value });
                      }}
                    />
                    <small
                      onClick={() => {
                        this.setState(
                          {
                            ...this.state,
                            searchBarValue: "",
                          },
                          () => this.get_channels()
                        );
                      }}
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        padding: "5px",
                        right: "16%",
                        top: "17%",
                      }}
                    >
                      <img
                        className="mr-1"
                        style={{
                          cursor: "pointer",
                          width: "11px",
                          filter: "brightness(0)",
                        }}
                        src={refreshIco}
                        alt="refresh"
                      />
                      <b>Clear</b>
                    </small>
                    <Button
                      style={{ borderRadius: 0 }}
                      variant="contained"
                      color="primary"
                      id="button-addon2"
                      onClick={this.handleSearch}
                    >
                     
                      Search
                    </Button>
                  </InputGroup>
                </div>
              </Col>
            </Row>

            <Row className="">{this.loadChannels()}</Row>
          </Col>
          <Col sm={4}>
            <div className="mb-3">
              <h2>Help</h2>
            </div>
            <div>
              <p>
                Collect data in a Flare channel from a device, from another
                channel, or from the web.
              </p>

              <p>
                Click <b>New Channel</b> to create a new Flare channel.
              </p>

              <p>
                Click on the column headers of the table to sort by the entries
                in that column or click on a tag to show channels with that tag.
              </p>
            </div>
          </Col>
        </Row>

        {this.state.num_of_pages > 1 && (
              <div className="d-flex justify-content-end">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={this.state.limit}
                  pageCount={this.state.num_of_pages}
                  previousLabel="< previous"
                  renderOnZeroPageCount={false}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            )}

        <Footer />
      </div>
    );
  }
}

export default Channels;
