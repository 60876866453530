import React from "react";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
const InputCheckGroup = ({ name, handleEvent }) => {
  return (
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text id="inputGroup-sizing-default" style={{ width: 140 }}>
          <span>{name}</span>
          <span className="mx-auto">
            <Form.Check
              key={name}
              aria-label={name}
              name={String(name).toLocaleLowerCase()}
              onChangeCapture={(e) => handleEvent(e.target.checked)}
            />{" "}
          </span>
        </InputGroup.Text>
      </InputGroup.Prepend>
    </InputGroup>
  );
};

export default InputCheckGroup;
