import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Col, Container, Row, Table } from "react-bootstrap";
import backLogo from "../assets/images/back.png";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberRangePicker from "../components/NumberRangePicker";
import {
  Backdrop,
  InputLabel,
  Button,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from "@material-ui/core";
import axios from "axios";
import {
  GENERATE_MMB_WMS_PLOTS,
  GET_ANALYTICS_HISTORY,
  GET_DATE_RANGE,
  GET_DOWNLOAD_ZONE_CHANNELS,
  SOILING_TRENDS_WITHOUT_CLEANING,
  SOILING_TRENDS_WITH_CLEANING_DATA,
  CHECK_SOILING_RATE_DATA,
  DOWNLOAD_CLEANING_DETECTION,
  serverURL,
  GET_ANALYTICS_ZONE_CHANNELS,
  GET_CLIPPING_LOSS,
  CLIPPING_LOSS_FETCH_DATA_OR_GENERATE_PLOTS,
  GENERATE_WMS_PLOTS,
} from "../shared/ApiURLs";
import { addHeaderInAxios, checkSignature } from "../utils/Helpers";
import { CustomMultiSelect, ReactSelect } from "../components/ReactSelect";
import Moment from "moment";
import DateRangePickerModal from "../components/DateRangePicker";
import { DOWNLOAD_FETCH_DATA_GENERATE_PLOTS } from "../shared/ApiURLs";
import { DOWNLOAD_FETCH_DATA_GENERATE_PLOTS_FOR_DEGRADATION } from "../shared/ApiURLs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        overflowX: "auto",
        minHeight: "350px",
        marginBottom: "2rem",
      }}
      className="card"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const Analytics = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [isContentLoading, setContentLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [numberOfDay, setNumberOfDay] = useState([1]);
  const [interval, setInterval] = useState([15]);
  const [plotsFor, setPlotsFor] = useState(null);
  const [userChannels, setUserChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [availableDates, setAvailableDates] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [mmbWmsHistory, setMmbWmsHistory] = useState([]);
  const [checkAvailibityList, setCheckAvailabilityList] = useState([]);
  const [autoDetectedSoilingRate, setAutoDetectedSoilingRate] = useState([]);
  const [alpha, setAlpha] = useState("0.0006");
  const [gammaMMB, setGammaMMB] = useState("-0.0041");
  const [beta, setBeta] = useState("-0.0032");
  const [gamma, setGamma] = useState("-0.0041");
  const [iscDataSheetValue, setISCDataSheetValue] = useState("");
  const [cleaningDataAvailable, setCleaningDataAvailable] = useState(false);

  const [siteNameFirst, setSiteNameFirst] = useState(null);
  const [siteNameListFirst, setSiteNameListFirst] = useState([]);

  const [siteNameSecond, setSiteNameSecond] = useState(null);
  const [siteNameListSecond, setSiteNameListSecond] = useState([]);

  const [siteNameThird, setSiteNameThird] = useState(null);
  const [siteNameListThird, setSiteNameListThird] = useState([]);

  const [siteNameFourth, setSiteNameFourth] = useState(null);
  const [siteNameListFourth, setSiteNameListFourth] = useState([]);

  const [clipplingSiteList, setClippingSiteList] = useState([]);
  const [siteClipSelect, setSiteClipSelect] = useState(null);
  const [selectChannelsClippingLoss, setSelectChannelsClippingLoss] =
    useState(null);

  const token = localStorage.getItem("auth_token");

  const [selectDetectionType, setSelectDetectionType] = useState({
    value: "user_uploaded",
    label: "User Uploaded",
  });

  useEffect(() => {
    addHeaderInAxios(props);
  }, []);

  useEffect(() => {
    setInterval([15]);
    setNumberOfDay([1]);

    // setUserChannels([]);
    setAvailableDates(null);
    setDateRange(null);
    setShowDatePicker(false);
    getHistory(value);
    setSelectChannelsClippingLoss(null);
    setSiteClipSelect(null);
    setSiteNameFourth(null);
    setUserChannels([]);
    setSiteNameThird(null);
    setSelectedChannels([]);
    setSiteNameSecond(null);
    setSiteNameFirst(null);
    setPlotsFor(null);
  }, [value]);

  const handleChange = (event, newValue) => {
    console.log("tab changed ----", newValue);
    setValue(newValue);
    // ravi todo
  };

  const handleChangeIndex = (index) => {
    console.log("tab changed ----", index);
    setValue(index);
  };

  const backBtnHandler = () => {
    return props.history.replace("/channels");
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const getChannels = () => {
    axios
      .post(GET_ANALYTICS_ZONE_CHANNELS, {
        site_id:
          value === 0
            ? siteNameFirst.value
            : value === 1
            ? siteNameSecond.value
            : value === 2
            ? siteNameThird.value
            : value === 3
            ? siteNameFourth.value
            : siteClipSelect.value,
        Authorization: `Bearer ${token}`,
      })
      .then((res) => {
        let sorted = res.data.data
          .map((e) => {
            return { label: e.name, value: e.id };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
        setUserChannels(sorted);
        setContentLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        setContentLoading(false);
      });
  };

  useEffect(() => {
    if (
      siteNameFirst !== null ||
      siteNameSecond !== null ||
      siteNameThird !== null ||
      siteNameFourth !== null ||
      siteClipSelect !== null
    ) {
      getChannels();
      setSelectedChannels([]);
    }
  }, [
    siteNameFirst,
    siteNameSecond,
    siteNameThird,
    siteNameFourth,
    siteClipSelect,
  ]);

  const getHistory = (tab_value) => {
    let query = "";
    setMmbWmsHistory([]);
    if (tab_value === 0) {
      query = "mmb_wms";
    } else if (tab_value === 1) {
      query = "max_curr_max_rad";
    } else if (tab_value === 2) {
      query = "soiling-trends";
    } else if (tab_value === 3) {
      query = "degradation-analysis";
    } else if (tab_value === 4) {
      query = "clipping-loss";
    }
    axios
      .get(GET_ANALYTICS_HISTORY(query))
      .then((res) => {
        setMmbWmsHistory(res.data.history);
        setContentLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        setContentLoading(false);
      });
  };

  const handleDateRangeSelect = (item) => {
    setDateRange([item?.selection]);
  };

  const showDateRangePicker = () => {
    setShowDatePicker(true);
  };

  const getDateRange = () => {
    if (selectedChannels.length) {
      const selectedChannelIds = selectedChannels.map((e) => e.value);
      setLoading(true);
      axios
        .post(GET_DATE_RANGE, {
          channels: selectedChannelIds,
        })
        .then((res) => {
          let range = res.data.date_range;
          const startDate = new Date(range.minDate);
          const endDate = new Date(range.minDate);
          endDate.setDate(endDate.getDate() + 1);
          setDateRange([
            {
              startDate: startDate,
              endDate: endDate,
              key: "selection",
            },
          ]);
          setAvailableDates({
            minDate: new Date(range.minDate),
            maxDate: new Date(range.maxDate),
          });
          setLoading(false);
          handleToast("success", res.data.message);
        })
        .catch((error) => {
          checkSignature(error);
          handleToast("error", error.response.data.message);
          setLoading(false);
        });
    }
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const onGenerateMmbWmsPlots = () => {
    if (selectedChannels.length) {
      const selectedChannelIds = selectedChannels.map((e) => e.value);
      let data = {
        channels: selectedChannelIds,
        minDate: new Date(dateRange[0].startDate).toLocaleDateString(),
        maxDate: new Date(dateRange[0].endDate).toLocaleDateString(),
        numOfDays: numberOfDay[0], //'fetch_data','gen_plots'
        plotsFor: plotsFor.value,
        gamma: +gammaMMB,
      };
      //console.log(data);
      setLoading(true);
      axios
        .post(GENERATE_MMB_WMS_PLOTS, data)
        .then((res) => {
          setLoading(false);
          openInNewTab(
            `${window.location.origin}/image_gallery/${plotsFor.label}/${res.data?.history}`
          );
          getHistory(value);
        })
        .catch((error) => {
          checkSignature(error);
          handleToast("error", error?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const selectSiteList = () => {
    axios
      .get(`${serverURL}/channels/get_all_site`, {
        Authorization: `Bearer ${token}`,
      })
      .then((res) => {
        //console.log("resModel", res.data);
        setSiteNameListFirst(res.data.sites);
        setSiteNameListSecond(res.data.sites);
        setSiteNameListThird(res.data.sites);
        setSiteNameListFourth(res.data.sites);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    selectSiteList();
  }, []);

  const renderMMB_WMS = () => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
      <div className="w-100 h-100 p-5">
        <Row>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b>Plots For:</b>
            </InputLabel>
            <ReactSelect
              data={[
                {
                  label: "Current-Rad",
                  value: "current-rad plots",
                },
                {
                  label: "Voltage-Temp",
                  value: "voltage-temp plots",
                },
                {
                  label: "V Norm",
                  value: "V-norm plots",
                },
                {
                  label: "Power-Rad",
                  value: "Power-Rad",
                },
              ]}
              value={plotsFor}
              placeholder={"Select Plot For..."}
              isLoading={false}
              handleChange={setPlotsFor}
            />
          </Col>
          {plotsFor?.label == "V Norm" && (
            <Col sm={12} md={6} lg={4} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label my-10">
                <b>GAMMA:</b>
              </InputLabel>
              <input
                type="number"
                placeholder="enter gamma no."
                className="w-100 form-control -webkit-outer-spin-button"
                value={gammaMMB}
                onChange={(e) => setGammaMMB(e.target.value)}
              />
            </Col>
          )}
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Site:</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <ReactSelect
                  data={siteNameListFirst.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  value={siteNameFirst}
                  placeholder={"Select Site"}
                  isLoading={false}
                  handleChange={(e) => {
                    setSiteNameFirst(e);
                    getClippingDate(e);
                  }}
                />
              </div>
            </div>
          </Col>
          {/* todo */}
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Channel:</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <CustomMultiSelect
                  isDisabled={!plotsFor}
                  data={userChannels}
                  value={selectedChannels}
                  placeholder={"Search..."}
                  handleChange={(d) => {
                    setSelectedChannels(d);
                  }}
                  multi={true}
                />
                {/* todo add */}
              </div>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={4}
            className="my-2"
            style={{ position: "relative" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Number of Days:</b>
            </InputLabel>
            <NumberRangePicker
              isDisabled={!availableDates}
              min={1}
              max={7}
              step={1}
              value={numberOfDay}
              onChange={setNumberOfDay}
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Dates:</b>
            </InputLabel>
            <input
              label=""
              type="text"
              className="w-100 form-control"
              readOnly
              disabled={!availableDates}
              onClick={() => {
                if (availableDates?.minDate && availableDates?.maxDate) {
                  showDateRangePicker();
                }
              }}
              value={
                availableDates?.minDate && availableDates?.maxDate
                  ? `${Moment(dateRange[0].startDate).format(
                      "MMM D, YYYY"
                    )} - ${Moment(dateRange[0].endDate).format("MMM D, YYYY")}`
                  : "Dates Not Available"
              }
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <Button
              className="w-100 mt-4"
              variant="outlined"
              color="primary"
              disabled={!availableDates}
              onClick={onGenerateMmbWmsPlots}
            >
              Generate MMB-WMS Plots
            </Button>
          </Col>
        </Row>
        {/* ravi */}
        {mmbWmsHistory.length > 0 && (
          <React.Fragment>
            <div className="mt-5">
              <h5 className="text-center">
                <b>History</b>
              </h5>
            </div>
            <TableContainer
              component={Paper}
              className="mb-3"
              style={{ border: "1px solid #cab8e5" }}
            >
              <Table aria-label="simple-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Analysis</TableCell>

                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="center">Number of Plots</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mmbWmsHistory.map((el) => {
                    return (
                      <TableRow key={el?.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {String(el?.analysis).toUpperCase()}{" "}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {Moment(el.created_at).format(
                            "MMM Do YYYY, h:mm:ss A"
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {el?.images?.length}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          <Button
                            className="w-100"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              // props.history.push(
                              //   `/image_gallery/${el.analysis}/${el.id}`,
                              //   {
                              //     images: el.images,
                              //     analysis: el?.analysis,
                              //     history_id: el?.id,
                              //   }
                              // );
                              openInNewTab(
                                `${window.location.origin}/image_gallery/${el.analysis}/${el.id}`
                              );
                            }}
                          >
                            View Plots
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderMax_Curr_Vs_Max_Rad = () => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
      <div className="w-100 h-100 p-5">
        <Row>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Site :</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <ReactSelect
                  data={siteNameListSecond.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  value={siteNameSecond}
                  placeholder={"Select Site"}
                  isLoading={false}
                  handleChange={(e) => {
                    setSiteNameSecond(e);
                    getClippingDate(e);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Channel:</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <CustomMultiSelect
                  // isDisabled={!plotsFor}
                  data={userChannels}
                  value={selectedChannels}
                  placeholder={"Search..."}
                  handleChange={(d) => {
                    setSelectedChannels(d);
                  }}
                  multi={true}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Dates:</b>
            </InputLabel>
            <input
              label=""
              type="text"
              className="w-100 form-control"
              readOnly
              disabled={!availableDates}
              onClick={() => {
                if (availableDates?.minDate && availableDates?.maxDate) {
                  showDateRangePicker();
                }
              }}
              value={
                availableDates?.minDate && availableDates?.maxDate
                  ? `${Moment(dateRange[0].startDate).format(
                      "MMM D, YYYY"
                    )} - ${Moment(dateRange[0].endDate).format("MMM D, YYYY")}`
                  : "Dates Not Available"
              }
            />
          </Col>

          <Col
            sm={12}
            md={6}
            lg={4}
            className="d-flex my-2 justify-content-between"
          >
            <Button
              className="w-50 mt-4"
              variant="outlined"
              color="primary"
              disabled={!availableDates}
              onClick={() => onGenerateFetchDataorPlots("fetch_data")}
            >
              Fetch Data
            </Button>
            <Button
              className="w-50 mt-4 ml-2"
              variant="outlined"
              color="primary"
              disabled={!availableDates}
              onClick={() => onGenerateFetchDataorPlots("gen_plots")}
            >
              Generate Plots
            </Button>
          </Col>
        </Row>

        {mmbWmsHistory.length > 0 && (
          <React.Fragment>
            <div className="mt-5">
              <h5 className="text-center">
                <b>History</b>
              </h5>
            </div>
            <TableContainer
              component={Paper}
              className="mb-3"
              style={{ border: "1px solid #cab8e5" }}
            >
              <Table aria-label="simple-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Analysis</TableCell>

                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="center">Number of Plots</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mmbWmsHistory.map((el) => {
                    return (
                      <TableRow key={el?.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {String(el?.analysis).toUpperCase()}{" "}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {Moment(el.created_at).format(
                            "MMM Do YYYY, h:mm:ss A"
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {el?.images?.length}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          <Button
                            className="w-100"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              // props.history.push("/image_gallery", {
                              //   images: el.images,
                              //   analysis: el?.analysis,
                              //   history_id: el?.id,
                              // });
                              openInNewTab(
                                `${window.location.origin}/image_gallery/${el.analysis}/${el.id}`
                              );
                            }}
                          >
                            View Plots
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderSoiling_Trends = () => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
      <div className="w-100 h-100 p-5">
        <Row>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Site:</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <ReactSelect
                  data={siteNameListThird.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  value={siteNameThird}
                  placeholder={"Select Site"}
                  isLoading={false}
                  handleChange={(e) => {
                    setSiteNameThird(e);
                    getClippingDate(e);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Channel:</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <CustomMultiSelect
                  // isDisabled={!plotsFor}
                  data={userChannels}
                  value={selectedChannels}
                  placeholder={"Search..."}
                  handleChange={(d) => {
                    setSelectedChannels(d);
                  }}
                  multi={true}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Dates:</b>
            </InputLabel>
            <input
              label=""
              type="text"
              className="w-100 form-control"
              readOnly
              disabled={!availableDates}
              onClick={() => {
                if (availableDates?.minDate && availableDates?.maxDate) {
                  showDateRangePicker();
                }
              }}
              value={
                availableDates?.minDate && availableDates?.maxDate
                  ? `${Moment(dateRange[0].startDate).format(
                      "MMM D, YYYY"
                    )} - ${Moment(dateRange[0].endDate).format("MMM D, YYYY")}`
                  : "Dates Not Available"
              }
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> IM-Datasheet:</b>
            </InputLabel>
            <input
              type="number"
              placeholder="enter im data no."
              disabled={!availableDates}
              className="w-100 form-control -webkit-outer-spin-button"
              value={iscDataSheetValue}
              onChange={(e) => setISCDataSheetValue(e.target.value)}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={4}
            className="d-flex my-2 justify-content-between"
          >
            <Button
              className="w-50 mt-4"
              variant="outlined"
              color="primary"
              disabled={!iscDataSheetValue}
              onClick={() =>
                onGenerateFetchDataorPlotsForSoilingTrends("fetch_data")
              }
            >
              Fetch Data
            </Button>
            <Button
              className="w-50 mt-4 ml-2"
              variant="outlined"
              color="primary"
              disabled={!iscDataSheetValue}
              onClick={() =>
                onGenerateFetchDataorPlotsForSoilingTrends("gen_plots")
              }
            >
              Generate Plots
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={4} md={4} lg={4}>
            <Button
              className="w-100 mt-2"
              variant="outlined"
              color="primary"
              disabled={!availableDates || !iscDataSheetValue}
              onClick={checkAvailibityData}
            >
              Check Cleaning Data Availability
            </Button>
          </Col>

          {cleaningDataAvailable && (
            <>
              <Col sm={4} md={4} lg={4}>
                <Button
                  className="w-100 mt-2 ml-2"
                  variant="outlined"
                  color="primary"
                  disabled={!iscDataSheetValue}
                  onClick={() =>
                    onGenerateFetchDataorPlotsWithCleaningForSoilingTrends(
                      "gen_plots"
                    )
                  }
                >
                  Generate Plots With Cleaning
                </Button>
              </Col>

              <Col sm={4} md={4} lg={4}>
                <div className="w-100 mt-2">
                  <ReactSelect
                    // isDisabled={!plotsFor}
                    data={[
                      { value: "user_uploaded", label: "User Uploaded" },
                      { value: "auto_detected", label: "Auto Detected" },
                    ]}
                    value={selectDetectionType}
                    placeholder={"Select Detection Type"}
                    handleChange={(e) => {
                      setSelectDetectionType(e);
                    }}
                  />
                </div>
              </Col>

              <Col sm={4} md={4} lg={4}>
                <Button
                  className="w-100 mt-2 ml-0"
                  variant="outlined"
                  color="primary"
                  disabled={!iscDataSheetValue}
                  onClick={() =>
                    onGenerateFetchDataorPlotsWithCleaningForSoilingTrends(
                      "fetch_data"
                    )
                  }
                >
                  Fetch Data With Cleaning Data
                </Button>
              </Col>
            </>
          )}
        </Row>

        {/* autoDetectedSoilingRate */}
        {selectDetectionType.value === "auto_detected" &&
          autoDetectedSoilingRate.length > 0 && (
            <React.Fragment>
              <div className="mt-5">
                <h5 className="text-center">
                  <b>Auto Detected Soiling Rate Summary</b>
                </h5>
              </div>
              <TableContainer
                component={Paper}
                className="mb-3"
                style={{ border: "1px solid #cab8e5" }}
              >
                <Table aria-label="simple-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Channel</TableCell>
                      <TableCell align="center">From Date</TableCell>
                      <TableCell align="center">To Date</TableCell>
                      <TableCell align="center">
                        Average Soiling Rate (%)
                      </TableCell>
                      {/* <TableCell align="center">Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {autoDetectedSoilingRate.map((el) => {
                      return (
                        <TableRow key={el?.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {String(el?.channel).toUpperCase()}{" "}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {el?.fromDate}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {el.toDate}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {parseFloat(
                              parseFloat(el?.soiling_rate) * 100
                            )?.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}

        {selectDetectionType.value === "user_uploaded" &&
          checkAvailibityList.length > 0 && (
            <React.Fragment>
              <div className="mt-5">
                <h5 className="text-center">
                  <b>Soiling Rate Summary</b>
                </h5>
              </div>
              <TableContainer
                component={Paper}
                className="mb-3"
                style={{ border: "1px solid #cab8e5" }}
              >
                <Table aria-label="simple-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Channel</TableCell>
                      <TableCell align="center">From Date</TableCell>
                      <TableCell align="center">To Date</TableCell>
                      <TableCell align="center">
                        Average Soiling Rate (%)
                      </TableCell>
                      {/* <TableCell align="center">Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {checkAvailibityList.map((el) => {
                      return (
                        <TableRow key={el?.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {String(el?.channel).toUpperCase()}{" "}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {el?.fromDate}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {el.toDate}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {parseFloat(
                              parseFloat(el?.soiling_rate) * 100
                            )?.toFixed(2)}
                          </TableCell>

                          {/* <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          <Button
                            className="w-100"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              props.history.push("/image_gallery", {
                                images: el.images,
                                analysis: el?.analysis,
                                history_id: el?.id,
                              });
                            }}
                          >
                            View Plots
                          </Button>
                        </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}

        {mmbWmsHistory.length > 0 && (
          <React.Fragment>
            <div className="mt-5">
              <h5 className="text-center">
                <b>History</b>
              </h5>
            </div>
            <TableContainer
              component={Paper}
              className="mb-3"
              style={{ border: "1px solid #cab8e5" }}
            >
              <Table aria-label="simple-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Analysis</TableCell>
                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="center">Number of Plots</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mmbWmsHistory.map((el) => {
                    return (
                      <TableRow key={el?.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {String(el?.analysis).toUpperCase()}{" "}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {Moment(el.created_at).format(
                            "MMM Do YYYY, h:mm:ss A"
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {el?.images?.length}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          <Button
                            className="w-100"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              // props.history.push("/image_gallery", {
                              //   images: el.images,
                              //   analysis: el?.analysis,
                              //   history_id: el?.id,
                              // });
                              openInNewTab(
                                `${window.location.origin}/image_gallery/${el.analysis}/${el.id}`
                              );
                            }}
                          >
                            View Plots
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </div>
    );
  };

  const checkAvailibityData = () => {
    //console.log("checkAvailibityData");
    if (selectedChannels.length) {
      setLoading(true);
      const selectedChannelIds = selectedChannels.map((e) => e.value);
      let data = {
        channels: selectedChannelIds,
        minDate: new Date(dateRange[0].startDate).toLocaleDateString(),
        maxDate: new Date(dateRange[0].endDate).toLocaleDateString(),
        im_datasheet: +iscDataSheetValue,
        event: "checkAvailability",
        detection_type: selectDetectionType.value,
      };
      axios
        .post(CHECK_SOILING_RATE_DATA, data)
        .then((res) => {
          setLoading(false);
          console.log("CHECK_SOILING_RATE_DATA", res);
          console.log("CHECK_SOILING_RATE_DATA", res.data.soilingRateData);
          setCleaningDataAvailable(true);
          setCheckAvailabilityList(res.data.soilingRateData);
          setAutoDetectedSoilingRate(res.data.autoSoilingRateData);
          handleToast("success", res.data.message);
        })
        .catch((e) => {
          setLoading(false);
          setCleaningDataAvailable(false);
          let msg = `Something Went Wrong, Please Try Again!`;
          if (e.response.data.message) {
            msg = e.response.data.message;
          }
          toast.error(msg);
        });
    }
  };

  const onGenerateFetchDataorPlotsWithCleaningForSoilingTrends = (event_) => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    if (selectedChannels.length) {
      const selectedChannelIds = selectedChannels.map((e) => e.value);
      let data = {
        channels: selectedChannelIds,
        minDate: new Date(dateRange[0].startDate).toLocaleDateString(),
        maxDate: new Date(dateRange[0].endDate).toLocaleDateString(),
        event: event_,
        im_datasheet: +iscDataSheetValue,
        detection_type: selectDetectionType.value,
      };
      setLoading(true);
      if (event_ === "fetch_data") {
        axios
          .post(SOILING_TRENDS_WITH_CLEANING_DATA, data, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const timeStamp = new Date().getTime();
            const fileName = `Data_${timeStamp}.xlsx`;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            console.log(e?.response.data);
            toast.error(`Something Went Wrong, Please Try Again!`);
          });
      } else {
        axios
          .post(SOILING_TRENDS_WITH_CLEANING_DATA, data)
          .then((res) => {
            setLoading(false);
            console.log("plots");
            openInNewTab(
              `${window.location.origin}/image_gallery/soiling-trends plots/${res.data?.history}`
            );
            getHistory(value);
          })
          .catch((error) => {
            checkSignature(error);
            handleToast("error", error?.response?.data?.message);
            setLoading(false);
          });
      }
    }
  };

  const renderDegradation = () => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
      <div className="w-100 h-100 p-5">
        <Row>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Site:</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <ReactSelect
                  data={siteNameListFourth.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  value={siteNameFourth}
                  placeholder={"Select Site"}
                  isLoading={false}
                  handleChange={(e) => {
                    setSiteNameFourth(e);
                    getClippingDate(e);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Channel:</b>
            </InputLabel>
            <div className="d-flex w-100">
              <div className="w-100">
                <CustomMultiSelect
                  // isDisabled={!plotsFor}
                  data={userChannels}
                  value={selectedChannels}
                  placeholder={"Search..."}
                  handleChange={(d) => {
                    setSelectedChannels(d);
                  }}
                  multi={true}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label">
              <b> Select Dates:</b>
            </InputLabel>
            <input
              type="text"
              className="w-100 form-control"
              readOnly
              disabled={!availableDates}
              onClick={() => {
                if (availableDates?.minDate && availableDates?.maxDate) {
                  showDateRangePicker();
                }
              }}
              value={
                availableDates?.minDate && availableDates?.maxDate
                  ? `${Moment(dateRange[0].startDate).format(
                      "MMM D, YYYY"
                    )} - ${Moment(dateRange[0].endDate).format("MMM D, YYYY")}`
                  : "Dates Not Available"
              }
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="my-2">
            <InputLabel id="demo-simple-select-outlined-label my-10">
              <b>ALPHA/BETA/GAMMA:</b>
            </InputLabel>
            <Col className="d-flex justify-content-between">
              <input
                type="number"
                placeholder="enter alpha no."
                className="w-50 form-control -webkit-outer-spin-button"
                value={alpha}
                onChange={(e) => setAlpha(e.target.value)}
              />
              <input
                type="number"
                className="w-50 form-control ml-2 -webkit-outer-spin-button"
                placeholder="enter beta no."
                value={beta}
                onChange={(e) => setBeta(e.target.value)}
              />
              <input
                type="number"
                className="w-50 form-control ml-2 -webkit-outer-spin-button"
                placeholder="enter gamma no."
                value={gamma}
                onChange={(e) => setGamma(e.target.value)}
              />
            </Col>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={4}
            className="d-flex my-2 justify-content-between"
          >
            <Button
              className="w-50 mt-4"
              variant="outlined"
              color="primary"
              disabled={!availableDates}
              onClick={() => onGenerateFetchDataForDegradation("fetch_data")}
            >
              Fetch Data
            </Button>
            <Button
              className="w-50 mt-4 ml-2"
              variant="outlined"
              color="primary"
              disabled={!availableDates}
              onClick={() => onGenerateFetchDataForDegradation("gen_plots")}
            >
              Generate Plots
            </Button>
          </Col>
        </Row>

        {mmbWmsHistory.length > 0 && (
          <React.Fragment>
            <div className="mt-5">
              <h5 className="text-center">
                <b>History</b>
              </h5>
            </div>
            <TableContainer
              component={Paper}
              className="mb-3"
              style={{ border: "1px solid #cab8e5" }}
            >
              <Table aria-label="simple-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Analysis</TableCell>
                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="center">Number of Plots</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mmbWmsHistory.map((el) => {
                    return (
                      <TableRow key={el?.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {String(el?.analysis).toUpperCase()}{" "}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {Moment(el.created_at).format(
                            "MMM Do YYYY, h:mm:ss A"
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          {el?.images?.length}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          className="wrapText"
                          align="center"
                        >
                          <Button
                            className="w-100"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              // props.history.push("/image_gallery", {
                              //   images: el.images,
                              //   analysis: el?.analysis,
                              //   history_id: el?.id,
                              // });
                              openInNewTab(
                                `${window.location.origin}/image_gallery/${el.analysis}/${el.id}`
                              );
                            }}
                          >
                            View Plots
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </div>
    );
  };

  const onGenerateFetchDataForDegradation = (event_) => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    if (selectedChannels.length) {
      const selectedChannelIds = selectedChannels.map((e) => e.value);
      let data = {
        channels: selectedChannelIds,
        minDate: new Date(dateRange[0].startDate).toLocaleDateString(),
        maxDate: new Date(dateRange[0].endDate).toLocaleDateString(),
        event: event_,
        alpha: +alpha,
        beta: +beta,
        gamma: +gamma,
      };
      setLoading(true);
      if (event_ === "fetch_data") {
        axios
          .post(DOWNLOAD_FETCH_DATA_GENERATE_PLOTS_FOR_DEGRADATION, data, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const timeStamp = new Date().getTime();
            const fileName = `Data_${timeStamp}.xlsx`;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            console.log(e?.response.data);
            toast.error(`Something Went Wrong, Please Try Again!`);
          });
      } else {
        axios
          .post(DOWNLOAD_FETCH_DATA_GENERATE_PLOTS_FOR_DEGRADATION, data)
          .then((res) => {
            setLoading(false);
            console.log("plots");
            openInNewTab(
              `${window.location.origin}/image_gallery/degradation-analysis/${res.data?.history}`
            );
            getHistory(value);
          })
          .catch((error) => {
            checkSignature(error);
            handleToast("error", error?.response?.data?.message);
            setLoading(false);
          });
      }
    }
  };

  const onGenerateFetchDataorPlotsForSoilingTrends = (event_) => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    if (selectedChannels.length) {
      const selectedChannelIds = selectedChannels.map((e) => e.value);
      let data = {
        channels: selectedChannelIds,
        minDate: new Date(dateRange[0].startDate).toLocaleDateString(),
        maxDate: new Date(dateRange[0].endDate).toLocaleDateString(),
        event: event_,
        im_datasheet: +iscDataSheetValue,
      };
      setLoading(true);
      if (event_ === "fetch_data") {
        axios
          .post(SOILING_TRENDS_WITHOUT_CLEANING, data, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const timeStamp = new Date().getTime();
            const fileName = `Data_${timeStamp}.xlsx`;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            console.log(e?.response.data);
            toast.error(`Something Went Wrong, Please Try Again!`);
          });
      } else {
        axios
          .post(SOILING_TRENDS_WITHOUT_CLEANING, data)
          .then((res) => {
            setLoading(false);
            openInNewTab(
              `${window.location.origin}/image_gallery/soiling-trends plots/${res.data?.history}`
            );
            getHistory(value);
          })
          .catch((error) => {
            checkSignature(error);
            handleToast("error", error?.response?.data?.message);
            setLoading(false);
          });
      }
    }
  };

  const onGenerateFetchDataorPlots = (event_) => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    if (selectedChannels.length) {
      const selectedChannelIds = selectedChannels.map((e) => e.value);
      let data = {
        channels: selectedChannelIds,
        minDate: new Date(dateRange[0].startDate).toLocaleDateString(),
        maxDate: new Date(dateRange[0].endDate).toLocaleDateString(),
        event: event_,
      };
      setLoading(true);
      if (event_ === "fetch_data") {
        axios
          .post(DOWNLOAD_FETCH_DATA_GENERATE_PLOTS, data, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const timeStamp = new Date().getTime();
            const fileName = `Data_${timeStamp}.xlsx`;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            console.log(e?.response.data);
            toast.error(`Something Went Wrong, Please Try Again!`);
          });
      } else {
        axios
          .post(DOWNLOAD_FETCH_DATA_GENERATE_PLOTS, data)
          .then((res) => {
            setLoading(false);
            openInNewTab(
              `${window.location.origin}/image_gallery/max-curr-vs-max-rad plots/${res.data?.history}`
            );
            getHistory(value);
          })
          .catch((error) => {
            checkSignature(error);
            handleToast("error", error?.response?.data?.message);
            setLoading(false);
          });
      }
    }
  };

  // const onFetchDataCleaningDetection = () => {
  //   if (selectedChannels.length) {
  //     const selectedChannelIds = selectedChannels.map((e) => e.value);
  //     let data = {
  //       channels: selectedChannelIds,
  //       minDate: new Date(dateRange[0].startDate).toLocaleDateString(),
  //       maxDate: new Date(dateRange[0].endDate).toLocaleDateString(),
  //       interval: interval
  //     };
  //     setLoading(true);
  //     axios
  //       .post(DOWNLOAD_CLEANING_DETECTION, data, {
  //         responseType: "blob"
  //       })
  //       .then((response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         const timeStamp = new Date().getTime();
  //         const fileName = `Data_${timeStamp}.xlsx`;
  //         link.setAttribute("download", `${fileName}`);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();
  //         setLoading(false);
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //         console.log(e?.response.data);
  //         toast.error(`Something Went Wrong, Please Try Again!`);
  //       });
  //   }
  // };
  //  yash todo
  const [plotsForClipping, setPlotsForClipping] = useState(null);
  const [chnnelClipping, setChannelClipping] = useState([]);
  const [siteClipping, setSiteClipping] = useState(null);

  const getDateForClippingLoss = () => {
    if (chnnelClipping.length) {
      const selectedChannelIds = chnnelClipping.map((e) => e.value);
      setLoading(true);
      axios
        .post(GET_DATE_RANGE, {
          channels: selectedChannelIds,
        })
        .then((res) => {
          let range = res.data.date_range;
          console.log(range);
          // const startDate = new Date(range.minDate);
          // const endDate = new Date(range.minDate);
          // endDate.setDate(endDate.getDate() + 1);
          // setDateRange([
          //   {
          //     startDate: startDate,
          //     endDate: endDate,
          //     key: "selection",
          //   },
          // ]);
          // setAvailableDates({
          //   minDate: new Date(range.minDate),
          //   maxDate: new Date(range.maxDate),
          // });
          setLoading(false);
          handleToast("success", res.data.message);
        })
        .catch((error) => {
          checkSignature(error);
          handleToast("error", error.response.data.message);
          setLoading(false);
        });
    }
  };

  const getClippingDate = (e) => {
    setAvailableDates(null);
    setDateRange(null);
    setLoading(true);
    axios
      .post(GET_CLIPPING_LOSS(""), {
        site: e.value,
      })
      .then((res) => {
        let range = res.data.date_range;
        const startDate = new Date(range.minDate);
        const endDate = new Date(range.minDate);
        endDate.setDate(endDate.getDate() + 1);

        setDateRange([
          {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
          },
        ]);
        setAvailableDates({
          minDate: new Date(range.minDate),
          maxDate: new Date(range.maxDate),
        });
        setLoading(false);
        handleToast("success", res.data.message);
      })
      .catch((error) => {
        checkSignature(error);
        handleToast("error", error.response.data.message);
        setLoading(false);
      });
  };

  const clippingLossApiFetchData = (event) => {
    if (dateRange != null || dateRange != undefined) {
      var ch =
        selectChannelsClippingLoss != null ||
        selectChannelsClippingLoss != undefined
          ? selectChannelsClippingLoss.map((e) => e.value)
          : [];

      let minDate = new Date(dateRange[0]?.startDate);
      let maxDate = new Date(dateRange[0]?.endDate);

      let data = {
        event: event,
        site: siteClipSelect.value,
        channels: ch,
        // availableDates: availableDates?.maxDate,
        minDate: `${minDate.getDate()}/${
          minDate.getMonth() + 1
        }/${minDate.getFullYear()}`,
        maxDate: `${maxDate.getDate()}/${
          maxDate.getMonth() + 1
        }/${maxDate.getFullYear()}`,
      };

      setLoading(true);
      try {
        axios
          .post(CLIPPING_LOSS_FETCH_DATA_OR_GENERATE_PLOTS, data, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const timeStamp = new Date().getTime();
            const fileName = `Data_${timeStamp}.xlsx`;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      toast.error(`Date range is not selected!`);
    }
  };
  const clippingLossApiGenratePlot = (event) => {
    if (dateRange != null || dateRange != undefined) {
      var ch =
        selectChannelsClippingLoss != null ||
        selectChannelsClippingLoss != undefined
          ? selectChannelsClippingLoss.map((e) => e.value)
          : [];

      let minDate = new Date(dateRange[0]?.startDate);
      let maxDate = new Date(dateRange[0]?.endDate);

      let data = {
        event: event,
        site: siteClipSelect.value,
        channels: ch,
        // availableDates: availableDates?.maxDate,
        minDate: `${minDate.getDate()}/${
          minDate.getMonth() + 1
        }/${minDate.getFullYear()}`,
        maxDate: `${maxDate.getDate()}/${
          maxDate.getMonth() + 1
        }/${maxDate.getFullYear()}`,
      };

      setLoading(true);
      try {
        axios
          .post(CLIPPING_LOSS_FETCH_DATA_OR_GENERATE_PLOTS, data)
          .then((response) => {
            if (response?.data.status === true) {
              getHistory(value);
              toast.success("Wms-Plot created");
              setLoading(false);
            } else {
              toast.error("Wms-Plot not created");
              setLoading(false);
            }
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      toast.error("Date is not selected");
    }
  };

  const generate_wms_plots = (event) => {
    setLoading(true);
    if (dateRange != null || dateRange != undefined) {
      var ch =
        selectChannelsClippingLoss != null ||
        selectChannelsClippingLoss != undefined
          ? selectChannelsClippingLoss.map((e) => e.value)
          : [];

      let minDate = new Date(dateRange[0]?.startDate);
      let maxDate = new Date(dateRange[0]?.endDate);

      let data = {
        event: event,
        site: siteClipSelect.value,
        channels: ch,
        // availableDates: availableDates?.maxDate,
        minDate: `${minDate.getDate()}/${
          minDate.getMonth() + 1
        }/${minDate.getFullYear()}`,
        maxDate: `${maxDate.getDate()}/${
          maxDate.getMonth() + 1
        }/${maxDate.getFullYear()}`,
      };

      try {
        axios
          .post(GENERATE_WMS_PLOTS, data)
          .then((response) => {
            if (response?.data.status === true) {
              toast.success("Wms-Plot created");
              getHistory(value);
              setLoading(false);
            } else {
              toast.error("Wms-Plot not created");
              setLoading(false);
            }
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Date is not seleted");
      setLoading(false);
    }
  };
  // ravi todo
  const clippingLoss = (clipplingSiteList) => {
    // console.log("data checked", dateRange);
    return (
      <>
        <div className="w-100 h-100 p-5">
          <Row>
            <Col sm={12} md={6} lg={4} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select Site:</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <ReactSelect
                    data={siteNameListThird.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={siteClipSelect}
                    placeholder={"Select Site"}
                    isLoading={false}
                    handleChange={(e) => {
                      setSiteClipSelect(e);
                      getClippingDate(e);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select Channel:</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <CustomMultiSelect
                    // isDisabled={!plotsFor}
                    data={userChannels}
                    value={selectChannelsClippingLoss}
                    placeholder={"Search..."}
                    handleChange={(d) => {
                      setSelectChannelsClippingLoss(d);
                    }}
                    multi={true}
                  />
                </div>
              </div>
            </Col>

            {/* <Col sm={12} md={6} lg={4} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select Dates:</b>
              </InputLabel>
              <input
                label=""
                type="text"
                className="w-100 form-control"
                // readOnly
                disabled={!availableDates}
                onClick={() => {
                  if (availableDates?.minDate && availableDates?.maxDate) {
                    showDateRangePicker();
                  }
                }}
                value={
                  availableDates?.minDate && availableDates?.maxDate
                    ? `${Moment(dateRange[0].startDate).format(
                        "MMM D, YYYY"
                      )} - ${Moment(dateRange[0].endDate).format(
                        "MMM D, YYYY"
                      )}`
                    : "Dates Not Available"
                }
              />
            </Col> */}

            <Col sm={12} md={6} lg={4} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select Dates:</b>
              </InputLabel>
              <input
                label=""
                type="text"
                className="w-100 form-control"
                readOnly
                disabled={!availableDates}
                onClick={() => {
                  if (availableDates?.minDate && availableDates?.maxDate) {
                    showDateRangePicker();
                  }
                }}
                value={
                  availableDates?.minDate && availableDates?.maxDate
                    ? `${Moment(dateRange[0].startDate).format(
                        "MMM D, YYYY"
                      )} - ${Moment(dateRange[0].endDate).format(
                        "MMM D, YYYY"
                      )}`
                    : "Dates Not Available"
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4} className="my-2">
              <Button
                disabled={
                  !siteClipSelect ||
                  !selectChannelsClippingLoss ||
                  !selectChannelsClippingLoss?.length
                }
                className="float-left"
                variant="contained"
                color="primary"
                title="Get Date Range"
                style={{ maxHeight: "40px", borderRadius: "none" }}
                onClick={() => clippingLossApiFetchData("fetchData")}
              >
                Fetch data
              </Button>
            </Col>
            <Col sm={12} md={6} lg={4} className="my-2">
              <Button
                disabled={
                  !siteClipSelect ||
                  !selectChannelsClippingLoss ||
                  !selectChannelsClippingLoss?.length
                }
                className="float-left"
                variant="contained"
                color="primary"
                title="Get Date Range"
                style={{ maxHeight: "40px", borderRadius: "none" }}
                onClick={() => clippingLossApiGenratePlot("generatePlot")}
              >
                Generate Plots
              </Button>
            </Col>
            <Col sm={12} md={6} lg={4} className="my-2">
              <Button
                disabled={
                  !siteClipSelect ||
                  !selectChannelsClippingLoss ||
                  !selectChannelsClippingLoss?.length
                }
                className="float-left"
                variant="contained"
                color="primary"
                title="Get Date Range"
                style={{ maxHeight: "40px", borderRadius: "none" }}
                onClick={() => generate_wms_plots("generatePlot")}
              >
                Generate Wms plots
              </Button>
            </Col>
          </Row>

          {mmbWmsHistory.length > 0 && (
            <React.Fragment>
              <div className="mt-5">
                <h5 className="text-center">
                  <b>History</b>
                </h5>
              </div>
              <TableContainer
                component={Paper}
                className="mb-3"
                style={{ border: "1px solid #cab8e5" }}
              >
                <Table aria-label="simple-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Analysis</TableCell>

                      <TableCell align="center">Created At</TableCell>
                      <TableCell align="center">Number of Plots</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mmbWmsHistory.map((el) => {
                      return (
                        <TableRow key={el?.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {String(el?.analysis).toUpperCase()}{" "}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {Moment(el.created_at).format(
                              "MMM Do YYYY, h:mm:ss A"
                            )}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {el?.images?.length}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            <Button
                              className="w-100"
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                // props.history.push(
                                //   `/image_gallery/${el.analysis}/${el.id}`,
                                //   {
                                //     images: el.images,
                                //     analysis: el?.analysis,
                                //     history_id: el?.id,
                                //   }
                                // );
                                openInNewTab(
                                  `${window.location.origin}/image_gallery/${el.analysis}/${el.id}`
                                );
                              }}
                            >
                              View Plots
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="main-wrapper overflow-hidden">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      <Container fluid>
        <div className="mb-3 d-flex">
          <div>
            <img
              src={backLogo}
              alt="back"
              className="backBtn"
              onClick={backBtnHandler}
            />
          </div>
          <div>
            <h4 className="ml-2">
              <b>Analytics</b>
            </h4>
          </div>
        </div>
        <div>
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="MMB-WMS" {...a11yProps(0)} />
                <Tab label="Max Curr vs Max Rad" {...a11yProps(1)} />
                <Tab label="Soiling Trends" {...a11yProps(2)} />
                <Tab label="degradation analysis" {...a11yProps(3)} />
                <Tab label="Clipping Loss" {...a11yProps(4)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {renderMMB_WMS()}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {renderMax_Curr_Vs_Max_Rad()}
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                {renderSoiling_Trends()}
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                {renderDegradation()}
              </TabPanel>

              <TabPanel value={value} index={4} dir={theme.direction}>
                {clippingLoss(clipplingSiteList)}
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
      </Container>
      <Footer />
      <ToastContainer />
      <DateRangePickerModal
        minDate={availableDates?.minDate}
        maxDate={availableDates?.maxDate}
        show={showDatePicker}
        onHide={() => setShowDatePicker(false)}
        date_range={dateRange}
        handleDateRangeChange={(e) => {
          handleDateRangeSelect(e);
          // console.log("date", e);
        }}
      />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Analytics;
