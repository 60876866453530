import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Backdrop,
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import backLogo from "../assets/images/back.png";

// library imported lightbox gallery
import {
  DOWNLOAD_MMB_WMS_PLOT_ZIP,
  serverURL,
  GET_IMAGES,
} from "../shared/ApiURLs";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const ImageGallery = () => {
  const classes = useStyles();

  const [imageData, setImageData] = useState([]);
  const [imgIndex, setimgIndex] = useState(0);
  const [isLightBoxOpen, setisLightBoxOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [analysisName, setAnalysisName] = useState("");

  const param = useParams();
  //const { history_id, analysis } = props?.location?.state;

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    // console.log("history", param);
    setAnalysisName(param.analysis);
    axios
      .post(`${serverURL}/channels/get_analytics_history_with_plots`, {
        Authorization: `Bearer ${token}`,
        history_id: param.id,
      })
      .then((res) => {
        //console.log("res", res.data);
        let images_ = res.data.history.images.map((el) => `${serverURL}/${el}`);
        setImageData(images_);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleDownloadData = () => {
    setLoading(true);
    axios
      .get(DOWNLOAD_MMB_WMS_PLOT_ZIP(param.id), {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const timeStamp = new Date().getTime();
        const fileName = `${String(
          analysisName
        ).toLocaleUpperCase()}${timeStamp}.zip`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        toast.success("Download Successful");
        setLoading(false);
      })
      .catch((e) => {
        if (e?.response.data.message) {
          toast.error(e?.response.data.message);
          return setLoading(false);
        }

        toast.error("Something went wrong!");
        return setLoading(false);
      });
  };

  return (
    <div className="main-wrapper overflow-hidden">
      <Header />
      <div
        className="d-flex flex-column shadow-lg mx-3 p-3 rounded  overflow-hidden"
        style={{ minHeight: "60vh", position: "relative" }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {/* <div>
              <img
                src={backLogo}
                alt="back"
                className="backBtn"
                // onClick={() => {
                //   return props.history.goBack();
                // }}
              />
            </div> */}
            <div>
              <h4 className="ml-2">
                <b>{String(analysisName).toUpperCase().replaceAll("-", " ")}</b>
              </h4>
            </div>
          </div>
          <div className="align-self-end">
            <Button
              className="mr-3 float-left"
              variant="contained"
              color="primary"
              onClick={handleDownloadData}
            >
              Download Plot Zip
            </Button>
          </div>
        </div>

        <div className="my-3">
          <Row>
            {imageData.map((img, i) => (
              <Col sm={1} md={3} key={i}>
                <div
                  className="card m-2"
                  style={{
                    boxShadow:
                      "0px 2px 4px -1px rgb(0 0 0 / 20%),0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                  }}
                >
                  <img
                    src={img}
                    // src={`${serverURL}/${img}`}
                    alt="plot"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setimgIndex(i);
                      setisLightBoxOpen(true);
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      {isLightBoxOpen && (
        <Lightbox
          mainSrc={imageData[imgIndex]}
          nextSrc={imageData[(imgIndex + 1) % imageData.length]}
          prevSrc={
            imageData[(imgIndex + imageData.length - 1) % imageData.length]
          }
          onCloseRequest={() => setisLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setimgIndex((imgIndex + imageData.length - 1) % imageData.length)
          }
          onMoveNextRequest={() =>
            setimgIndex((imgIndex + 1) % imageData.length)
          }
        />
      )}
      <ToastContainer />

      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ImageGallery;
