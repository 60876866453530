import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import currentIcon from "../assets/images/currentIcon.png";
import tempIcon from "../assets/images/tempIcon.png";
import powerIcon from "../assets/images/powerIcon.png";
import humidityIcon from "../assets/images/humidityIcon.png";
import voltageIcon from "../assets/images/voltageIcon.png";
import { createMuiTheme } from "@material-ui/core/styles";

import { MuiThemeProvider, Tooltip } from "@material-ui/core";
import { checkSignature } from "../utils/Helpers";
import {
  GRAPH_WIDGET_GET,
  NUMERIC_WIDGET_GET,
  COMBI_GRAPH_WIDGET_GET,
} from "../shared/ApiURLs";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import LineChart from "../shared/lineChart";
import CombiLineChart from "../shared/Multi_YAxislineChart";
import { Spinner } from "react-bootstrap";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "white",
        border: "0.5px solid black",
      },
    },
  },
});

const PublicViewWidgets = (props) => {
  const [numericVisualizationArr, setnumericVisualizationArr] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(true);
  const [graphVisualizationArr, setgraphVisualizationArr] = useState([]);
  const [combiGraphVisualizationArr, setCombiGraphVisualizationArr] = useState(
    []
  );

  console.log("graphLoading", graphLoading);

  useEffect(() => {
    // Anything in here is fired on component mount.
    getAllNumericWidgets(props.channel);
    getAllGraphWidgets(props.channel);
    getAllCombiGraphWidgets(props.channel);
  }, [props]);

  const getAllGraphWidgets = (channel) => {
    // setGraphLoading(true);
    axios
      .get(GRAPH_WIDGET_GET(channel.id))
      .then((res) => {
        console.log("GRAPH_WIDGET_GET", res);
        setgraphVisualizationArr(res.data.data);
        setGraphLoading(false);
      })
      .catch((error) => {
        setGraphLoading(false);
        checkSignature(error);
        console.log("api error: ", error);
      });
  };

  const getAllNumericWidgets = (channel) => {
    axios
      .get(NUMERIC_WIDGET_GET(channel.id))
      .then((res) => {
        setnumericVisualizationArr(res.data.data);
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
      });
  };

  const getAllCombiGraphWidgets = (channel) => {
    // setGraphLoading(true);
    axios
      .get(COMBI_GRAPH_WIDGET_GET(channel.id))
      .then((res) => {
        console.log("COMBI_GRAPH_WIDGET_GET", res);
        setCombiGraphVisualizationArr(res.data.data);
        // setGraphLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        // setGraphLoading(false);
        console.log("api error: ", error);
      });
  };

  const renderGraphWidgets = () => {
    if (graphVisualizationArr.length) {
      return graphVisualizationArr.map((el) => {
        return (
          <Col sm={12} md={6} key={el?.id} className="mb-5">
            <div className="graphWidgetCard card">
              <div className="card-header mb-3">
                <div className="b_radius d-flex justify-content-between">
                  <h6 className="mb-0 pl-2 pt-2 wrapText w-75">{el.title}</h6>
                </div>
              </div>
              <div className="p-0">
                <LineChart
                  data={el.feed_data}
                  yAxisTitle={el.y_label}
                  xAxisTitle={el.x_label}
                  name={el.title}
                  chartType={"line"}
                  y_min={el.y_min}
                  y_max={el.y_max}
                />
              </div>
            </div>
          </Col>
        );
      });
    }
  };

  const renderCombiGraphWidgets = () => {
    if (combiGraphVisualizationArr.length) {
      return combiGraphVisualizationArr.map((el) => {
        return (
          <Col sm={12} md={6} key={el?.id} className="mb-5">
            <div className="graphWidgetCard card">
              <div className="card-header mb-3">
                <div className="b_radius d-flex justify-content-between">
                  <h6 className="mb-0 pl-2 pt-2 wrapText w-75">{el.title}</h6>
                </div>
              </div>
              <div className="p-0">
                <CombiLineChart
                  feed_data={el?.feed_data}
                  wms_data={el?.wms_data}
                  x_label={el?.x_label}
                  y1_label={el?.y1_label}
                  y2_label={el?.y2_label}
                  title={el?.title}
                  y1_min={el?.y1_min}
                  y1_max={el?.y1_max}
                  y2_min={el?.y2_min}
                  y2_max={el?.y2_max}
                  widget={el}
                />
              </div>
            </div>
          </Col>
        );
      });
    }
  };

  console.log(
    "combiGraphVisualizationArr",
    combiGraphVisualizationArr,
    graphVisualizationArr
  );

  // if (graphLoading) {
  //   <div>
  //     <Spinner animation="border" role="status" />
  //   </div>;
  // }

  return (
    <React.Fragment>
      <div className="mb-5">
        {numericVisualizationArr.length > 0 && (
          <div className="d-flex py-2">
            <MuiThemeProvider theme={theme}>
              {numericVisualizationArr.map((el, index) => {
                return (
                  <Tooltip
                    title={`${el.field} : ${props.feed[el.field]?.toFixed(2)} ${
                      el.unit
                    } `}
                    arrow
                    key={el.field}
                  >
                    <div className="numericVisualBox mr-2 w-100 ">
                      <div className="p-2 b_radius d-flex justify-content-between">
                        <h6 className="mb-0 pl-2 pt-2 wrapText w-75">
                          {el.name}({el.unit})
                        </h6>
                      </div>

                      <div className="d-flex justify-content-between pt-3 p-2">
                        <b>{el.minValue?.toFixed(2)}</b>{" "}
                        <MuiThemeProvider theme={theme}>
                          <ProgressBar
                            style={{ width: "70%", marginTop: "3px" }}
                            now={props.feed[el.field]}
                            min={el.minValue?.toFixed(2)}
                            max={el.maxValue?.toFixed(2)}
                            label={`${props.feed[el.field]?.toFixed(2)}`}
                          />
                        </MuiThemeProvider>
                        <b>{el.maxValue?.toFixed(2)}</b>
                      </div>
                      <div className="d-flex justify-content-between p-2">
                        <span className="float-left">
                          {el.field === "current" && (
                            <img src={currentIcon} alt="humidityIcon" />
                          )}
                          {el.field === "voltage" && (
                            <img src={voltageIcon} alt="humidityIcon" />
                          )}
                          {el.field === "rh" && (
                            <img src={humidityIcon} alt="humidityIcon" />
                          )}
                          {el.field === "power" && (
                            <img src={powerIcon} alt="humidityIcon" />
                          )}
                          {el.field === "temperature" && (
                            <img src={tempIcon} alt="humidityIcon" />
                          )}
                        </span>
                        <span className="float-right">
                          <b>{props.feed[el.field]?.toFixed(2)}</b>
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </MuiThemeProvider>
          </div>
        )}
      </div>

      <Row id="publicViewGraphCardRow" className="mb-5">
        {graphLoading ? (
          <div
            style={{
              position: "absolute",
              left: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center ",
              flexDirection: "column",
            }}
            // className="d-flex position-absolute top-0 start-50 translate-middle flex-column justify-content-center align-item-center"
          >
            <Spinner animation="border" role="status" />
            <h6>Widgets are loading....</h6>
          </div>
        ) : (
          renderGraphWidgets()
        )}
        {renderCombiGraphWidgets()}
      </Row>
    </React.Fragment>
  );
};

export default PublicViewWidgets;
