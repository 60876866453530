import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

import LocationOnIcon from "@material-ui/icons/LocationOnTwoTone";
import 'mapbox-gl/dist/mapbox-gl.css';

import MapGL, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  Marker,
} from "react-map-gl";

import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const fullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: "10px",
};

const navStyle = {
  top: 72,
  left: 0,
  padding: "10px",
};

const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: "10px",
};

export const ChannelLocationCard = (props) => {
  const [viewport, setViewport] = useState({
    latitude: 21.7679,
    longitude: 78.8718,
    zoom: 3.5,
    bearing: 0,
    pitch: 0,
  });

  useEffect(() => {
    setViewport({
      latitude: props.channel.latitude,
      longitude: props.channel.longitude,
      zoom: 3.5,
      bearing: 0,
      pitch: 0,
    });
  }, [props.channel.latitude, props.channel.longitude]);

  return (
    <Col sm={12} md={6} className="mb-3">
      <div className="card">
        <div className="card-header">
          <span className="float-left mt-1 ">Channel Location</span>
        </div>
        <div className="p-0 card-body">
          <MapGL
            {...viewport}
            width="100%"
            height="415px"
            mapStyle="mapbox://styles/mapbox/streets-v9"
            // mapStyle="mapbox://styles/mapbox/dark-v9"
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapboxApiAccessToken={
              "pk.eyJ1IjoieWFzaDEzMSIsImEiOiJja3IxemM0bHcyNmY0MnpxYXBoZTRnZThkIn0.abB-uMRAuLm8KAWicDRt3A"
            }
          >
            <Marker
              latitude={props.channel.latitude}
              longitude={props.channel.longitude}
              // offsetLeft={-20}
              // offsetTop={-10}
            >
              <LocationOnIcon style={{ color: "red " }} />
            </Marker>

            <FullscreenControl style={fullscreenControlStyle} />
            <NavigationControl style={navStyle} />
            <ScaleControl style={scaleControlStyle} />
          </MapGL>
        </div>
      </div>
    </Col>
  );
};
