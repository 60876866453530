import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { CREATE_CHANNEL } from "../shared/ApiURLs";
import InputCheckGroup from "../components/InputGroup";
import { Backdrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactSelect } from "../components/ReactSelect";
import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";
import { serverURL } from "../shared/ApiURLs";
import { moduleLibraryFields } from "../utils/Helpers";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  }
}));

const CreateChannel = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [current, setCurrent] = useState(false);
  const [voltage, setVoltage] = useState(false);
  const [power, setPower] = useState(false);
  const [temperature, setTemperature] = useState(false);
  const [rh, setRh] = useState(false);

  const [channel_name, setChannel_name] = useState("");
  const [channel_description, setChannel_description] = useState("");

  const [channel_metadata, setChannel_metadata] = useState("");

  const [channel_status] = useState(true);
  const [is_channel_location_enabled, setIs_channel_location_enabled] =
    useState(false);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);

  const [siteName, setSiteName] = useState(null);
  const [siteNameList, setSiteNameList] = useState([]);
  const [moduleDetailsData, setModuleDetailsData] = useState({});
  const [moduleDetails, setModuleDetails] = useState(moduleLibraryFields);

  const token = localStorage.getItem("auth_token");

  const handleFieldDisable = (e) => {
    if (e.target.name === "current") {
      setCurrent(e.target.checked);
    } else if (e.target.name === "voltage") {
      setVoltage(e.target.checked);
    } else if (e.target.name === "power") {
      setPower(e.target.checked);
    } else if (e.target.name === "temperature") {
      setTemperature(e.target.checked);
    } else if (e.target.name === "rh") {
      setRh(e.target.checked);
    } else if (e.target.name === "is_channel_location_enabled") {
      setLongitude(is_channel_location_enabled ? "" : longitude);
      setLatitude(is_channel_location_enabled ? "" : latitude);
      setIs_channel_location_enabled(e.target.checked);
    }
  };

  const validateForm = () => {
    if (
      channel_name === "" ||
      channel_description === "" ||
      channel_metadata === "" ||
      !moduleDetails.model ||
      !siteName.value
    ) {
      setLoading(false);
      toast.error("Please fill all the mandatory fields!");
      return false;
    } else {
      return true;
    }
  };

  const createChannel = () => {
    setLoading(true);
    if (!validateForm()) return;

    let temp = moduleDetails;
    temp.model = temp.model.value;
    temp.make = temp.make.value;

    const channel = {
      name: channel_name,
      description: channel_description,
      current: current,
      voltage: voltage,
      power: power,
      temperature: temperature,
      rh: rh,
      cleaning_block: channel_metadata,
      status: channel_status,
      is_channel_location: is_channel_location_enabled,
      is_private: true,
      site_id: siteName.value,
      moduleDetails: temp
    };

    axios
      .post(CREATE_CHANNEL, channel)
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        setTimeout(() => {
          props.history.replace("/channels");
        }, 1000);
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Please fill valid values!");
      });
  };

  const selectSiteList = () => {
    axios
      .get(`${serverURL}/channels/get_all_site`, {
        Authorization: `Bearer ${token}`
      })
      .then((res) => {
        //console.log("resModel", res.data);
        setSiteNameList(res.data.sites);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const selectModuleList = () => {
    axios
      .get(`${serverURL}/channels/get-all-module-details`, {
        Authorization: `Bearer ${token}`
      })
      .then((res) => {
        setModuleDetailsData(res.data.dropdown_data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    selectSiteList();
    selectModuleList();
  }, []);

  const handleModuleDetailsChanges = (key, value) => {
    let tempModuleDetails = null;
    if (key === "model") {
      tempModuleDetails = value.details;
      tempModuleDetails.model = value;
      tempModuleDetails.make = moduleDetails.make;
      setModuleDetails({ ...tempModuleDetails });
      return true;
    } else if (key === "make") {
      tempModuleDetails = moduleLibraryFields;
    } else {
      tempModuleDetails = moduleDetails;
    }
    tempModuleDetails[key] = value;
    setModuleDetails({ ...tempModuleDetails });
    return true;
  };

  return (
    <React.Fragment>
      <div className="main-wrapper">
        <Header {...props} />
        <div className="px-4 pb-5 pt-3">
          <Row>
            <Col sm={12} md={8}>
              <div>
                <h2 className="mb-3">Create Channel</h2>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    createChannel();
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <InputGroup className="mb-3 w-85">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroup-sizing-default">
                            Name
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          aria-label="channel_name"
                          name="channel_name"
                          aria-describedby="inputGroup-sizing-default"
                          value={channel_name}
                          onChange={(e) => setChannel_name(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </Col>

                    <Col md={6}>
                      <InputGroup className="mb-3 w-85">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroup-sizing-default">
                            Cleaning Block
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          aria-label="channel_metadata"
                          name="channel_metadata"
                          aria-describedby="inputGroup-sizing-default"
                          value={channel_metadata}
                          onChange={(e) => setChannel_metadata(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <InputGroup className="mb-3 w-100">
                        <InputGroup.Prepend>
                          <InputGroup.Text>Address</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          name="channel_description"
                          as="textarea"
                          aria-label="With textarea"
                          value={channel_description}
                          onChange={(e) =>
                            setChannel_description(e.target.value)
                          }
                          required
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <InputCheckGroup
                        name="Current"
                        handleEvent={(checked) => setCurrent(checked)}
                      />
                    </Col>
                    <Col>
                      <InputCheckGroup
                        name="Voltage"
                        handleEvent={(checked) => setVoltage(checked)}
                      />
                    </Col>
                    <Col>
                      <InputCheckGroup
                        name="Power"
                        handleEvent={(checked) => setPower(checked)}
                      />
                    </Col>
                    <Col>
                      <InputCheckGroup
                        name="Temperature"
                        handleEvent={(checked) => setTemperature(checked)}
                      />
                    </Col>
                    <Col>
                      <InputCheckGroup
                        name="RH"
                        handleEvent={(checked) => setRh(checked)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="w-100 mb-3">
                        <ReactSelect
                          data={moduleDetailsData?.makes}
                          value={moduleDetails?.make}
                          placeholder={"Select Make..."}
                          isDisabled={false}
                          isLoading={false}
                          handleChange={(e) =>
                            handleModuleDetailsChanges("make", e)
                          }
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="w-100 mb-3">
                        <ReactSelect
                          data={moduleDetails?.make?.models}
                          value={moduleDetails?.model}
                          placeholder={"Select Model..."}
                          isDisabled={!moduleDetails?.make}
                          isLoading={!moduleDetails?.make}
                          handleChange={(e) =>
                            handleModuleDetailsChanges("model", e)
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="w-100 mb-3">
                        <ReactSelect
                          data={siteNameList.map(({ id, name }) => ({
                            value: id,
                            label: name
                          }))}
                          value={siteName}
                          placeholder={"Select Site"}
                          isLoading={false}
                          handleChange={setSiteName}
                        />
                      </div>
                    </Col>

                    <Col md={2}>
                      <InputGroup className="mt-2 w-75">
                        <span className="mr-2">Status </span>
                        <span>
                          {" "}
                          <Form.Check
                            name="channel_status"
                            aria-label="status"
                            value={channel_status}
                            onChange={(e) => {
                              handleFieldDisable(e);
                            }}
                            checked={channel_status}
                          />
                        </span>
                      </InputGroup>
                    </Col>

                    <Col md={4}>
                      <div>
                        <button
                          type="submit"
                          className="py-2 px-4 btn btn-success w-100"
                        >
                          Create Channel
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
            <Col sm={12} lg={4}>
              <div>
                <h2 className="mb-3">Help</h2>
                <p>
                  Channels store all the data that a Flare application collects.
                  Each channel includes eight fields that can hold any type of
                  data, plus three fields for location data and one for status
                  data. Once you collect data in a channel, you can use Flare
                  apps to analyze and visualize it.
                </p>

                <h4>Channel Settings</h4>
                <ul className="helpUL">
                  <li>
                    <b>Channel Name: </b>
                    Enter a unique name for the Flare channel.
                  </li>
                  <li>
                    <b>Address: </b>
                    Enter a address of the Flare channel.
                  </li>
                  <li>
                    <b>Current, Voltage, Power, Temperature and RH: </b>
                    Enable them for receiving feed data.
                  </li>
                  <li>
                    <b>Cleaning Block: </b>
                    Enter the cleaning block.
                  </li>
                  <li>
                    <b>Site :</b>
                    Select the site under which channel comes.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default CreateChannel;
