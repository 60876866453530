import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Col, Container, Row, Table } from "react-bootstrap";
import { CustomMultiSelect, ReactSelect } from "../components/ReactSelect";
import {
  GET_DEPLOYEMENT_ZONE,
  WIDGET_CREATION_DATA,
  POST_GRAPH_WIDGET,
} from "../shared/ApiURLs";
import { timeScale } from "../utils/Helpers";
import axios from "axios";
import {
  Backdrop,
  InputLabel,
  Button,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Tab,
  Input,
} from "@material-ui/core";
import backLogo from "../assets/images/back.png";
import { toast, ToastContainer } from "react-toastify";
import { max } from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        overflowX: "auto",
        minHeight: "350px",
        marginBottom: "2rem",
      }}
      className="card"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

// main
const DeploymentZone = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [userData, setUser] = useState(null);
  const [siteData, setSiteData] = useState(null);
  const [selected_User, setSelectedUser] = useState(null);
  const [selected_site, setSelectedSiter] = useState(null);
  const [channelsData, setChannelsData] = useState([]);
  const [UserChannel, setUserChannel] = useState([]);

  const [isSiteDisbled, setisSiteDisbled] = useState(false);
  const [isSiteChannel, setisSiteChannel] = useState(false);
  const [allocatedChannel, setallocatedChannel] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [btnActive, setBtnActive] = useState(false);

  const [totalSelect, setTotalSelect] = useState([]);

  // For Widget creation

  const [currUserWidget, setCurrUserWidget] = useState(null);
  const [currSiteWidget, setCurrSiteWidget] = useState(null);
  const [channelWidget, setChannelWidget] = useState([]);
  const [selectAllWidget, setSelectAllWidget] = useState(false);
  const [resambling, setresambling] = useState(null);
  const resamblingTest = useRef({});
  const maxpower = useRef(null);
  const maxcurrent = useRef(null);
  const maxvoltage = useRef(null);
  const noSample = useRef(null);

  const backBtnHandler = () => {
    return props.history.replace("/channels");
  };

  const handleChange = (event, newValue) => {
    console.log("tab changed ----", newValue);
    setValue(newValue);
  };
  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };
  const handleChangeIndex = (index) => {
    console.log("tab changed ----", index);
    setValue(index);
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    get_all_user_site("", "");
    timeScale.shift();
  }, []);
  const get_all_user_site = (userid, siteid) => {
    axios
      .get(GET_DEPLOYEMENT_ZONE(userid, siteid))
      .then((res) => {
        if (res.data.status) {
          if (res.data.message === "datafetch") {
            setUser(res.data.user);
            setSiteData(res.data.site);
            setUserChannel(res.data.user_channel);
            if (res?.data?.channel) {
              var channel = [];
              res.data.channel.map((e) => {
                e["status"] = false;
                channel.push(e);
              });
              setChannelsData(channel);
            }
          } else {
            console.log(res);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleCurrentAllocatedUser = (val, callfrom) => {
    if (callfrom === "site") {
      setallocatedChannel(true);
      get_all_user_site(selected_User.value, val.value);
    } else {
      selected_site && get_all_user_site(val.value, selected_site.value);
    }
  };

  const handleAllocation = () => {
    var temp = [];
    channelsData.map((e) => {
      e.status && temp.push(e);
    });
    let data = {
      user: selected_User,
      site: selected_site,
      channels: temp,
    };

    axios
      .post(GET_DEPLOYEMENT_ZONE("", ""), data)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SelelectTest = (temp) => {
    var val = temp.some((e) => {
      return e.status === true;
    });
    setBtnActive(val);
  };

  const handleMultipleSelect = (data, index) => {
    var temp = channelsData;
    if (temp[index].status === false) {
      temp[index].status = true;
    } else {
      temp[index].status = false;
    }
    console.log("");
    setChannelsData(temp);
    SelelectTest(temp);
  };

  const handleSelectAll = (data) => {
    var temp = channelsData;
    if (data) {
      setTotalSelect([...totalSelect, 1]);
      for (let i = 0; i < temp.length; i++) {
        temp[i].status = true;
      }
    } else {
      setTotalSelect([]);
      for (let i = 0; i < temp.length; i++) {
        temp[i].status = false;
      }
    }
    setChannelsData(temp);
    SelelectTest(temp);
  };

  const ChannelAllocation = () => {
    return (
      <>
        <div className="w-100 h-100 p-5">
          <Row>
            <Col sm={12} md={6} lg={6} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select User :</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <ReactSelect
                    data={
                      userData &&
                      userData.map(({ id, full_name }) => ({
                        value: id,
                        label: full_name,
                      }))
                    }
                    value={selected_User && selected_User}
                    placeholder={"Select User"}
                    handleChange={(e) => {
                      setSelectedUser(e);
                      setisSiteDisbled(true);
                      handleCurrentAllocatedUser(e, "user");
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={6} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select Site :</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <ReactSelect
                    data={
                      siteData &&
                      siteData.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    isDisabled={!isSiteDisbled}
                    value={selected_site && selected_site}
                    placeholder={"Select Site"}
                    handleChange={(e) => {
                      setSelectedSiter(e);
                      setisSiteChannel(true);
                      handleCurrentAllocatedUser(e, "site");
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={12} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Current Allocated channels :</b>
              </InputLabel>
              <div className="d-flex w-100">
                <Col
                  style={{
                    width: "100%",
                    minHeight: "7vh",
                    maxHeight: "25vh",
                    overflow: "auto",
                    border: "1px solid rgba(0,0,0,0.1)",
                    boxShadow: " inset 0.5px 0.5px 2px  rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    background: isSiteChannel ? "" : "rgba(0,0,0,0.1)",
                  }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    {allocatedChannel &&
                      UserChannel.length <= 0 &&
                      "User have no Allocated channels"}
                    {UserChannel.map((e) => {
                      return (
                        <>
                          <div
                            style={{
                              margin: "7px",
                              display: "inline-flex",
                            }}
                          >
                            {e.name} &nbsp;
                          </div>
                        </>
                      );
                    })}
                  </InputLabel>
                </Col>
              </div>
            </Col>

            <Col sm={12} md={12} lg={12} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select Channels :</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <Col
                    style={{
                      width: "100%",
                      minHeight: "5vh",
                      maxHeight: "35vh",
                      overflow: "auto",
                      border: "1px solid rgba(0,0,0,0.1)",
                      boxShadow: " inset 0.5px 0.5px 2px  rgba(0,0,0,0.1)",
                      borderRadius: "5px",
                      background: isSiteChannel ? "" : "rgba(0,0,0,0.1)",
                    }}
                  >
                    {isSiteChannel ? (
                      <>
                        <div
                          style={{
                            margin: "7px",
                            display: "inline-flex",
                          }}
                        >
                          <span>
                            <input
                              type="checkbox"
                              defaultChecked={selectAll}
                              onChange={(val) => {
                                setSelectAll(!selectAll);
                                handleSelectAll(!selectAll);
                              }}
                            />{" "}
                            &nbsp; Select All
                          </span>
                        </div>

                        {channelsData.map((e, index) => {
                          return (
                            <>
                              <div
                                style={{
                                  margin: "7px",
                                  display: "inline-flex",
                                }}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    defaultChecked={channelsData[index].status}
                                    onChange={(val) => {
                                      handleMultipleSelect(e, index);
                                    }}
                                  />{" "}
                                  &nbsp;
                                  {e.name}
                                </span>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4} className="my-2">
              <Button
                className="float-left"
                variant="contained"
                color="primary"
                title="Get Date Range"
                style={{ maxHeight: "40px", borderRadius: "none" }}
                onClick={handleAllocation}
                disabled={!btnActive}
              >
                Allocate
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  // widget creation part
  const Get_Data_forWidgetCreation = (userid, siteid) => {
    axios
      .get(GET_DEPLOYEMENT_ZONE(userid, siteid))
      .then((res) => {
        if (res.data.status) {
          if (res.data.message === "datafetch") {
            var temp = [];
            res?.data?.user_channel.map((e) => {
              e["status"] = false;
              temp.push(e);
            });
            if ((temp.length > 0, temp)) {
              temp.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
              );
            }
            setChannelWidget(temp);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const HandleChangeForWidget = (e, check) => {
    if (check) {
      Get_Data_forWidgetCreation(currUserWidget.value, e.value);
    } else {
      currSiteWidget &&
        Get_Data_forWidgetCreation(e.value, currSiteWidget.value);
    }
  };

  const HnadleSelectAll_Widget = (check) => {
    var temp = channelWidget;
    if (check) {
      for (let i = 0; i < temp.length; i++) {
        temp[i].status = true;
      }
    } else {
      for (let i = 0; i < temp.length; i++) {
        temp[i].status = false;
      }
    }
  };
  const handleSingleSelect_Widget = (index, check) => {
    var temp = channelWidget;
    if (!check) {
      temp[index].status = true;
    } else {
      temp[index].status = false;
    }
  };

  const Numericwidgets = (power, current, voltage) => {
    let maxval = {
      power: power,
      current: current,
      voltage: voltage,
    };
    let temp = [];
    channelWidget?.map((e) => {
      if (e.status) {
        temp.push(e);
      }
    });

    let data = {
      user: currUserWidget,
      site: currSiteWidget,
      maxval: maxval,
      channels: temp,
    };

    axios
      .post(WIDGET_CREATION_DATA, data)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // POST_GRAPH_WIDGET
  const CreateGraphWidget = (
    power,
    current,
    voltage,
    point,
    resamblingTest
  ) => {
    let temp = [];
    channelWidget?.map((e) => {
      if (e.status) {
        temp.push(e);
      }
    });
    let data = {
      resambling: resamblingTest,
      maxval: {
        power: power,
        current: current,
        voltage: voltage,
        point: point,
      },
      channels: temp,
    };
    axios
      .post(POST_GRAPH_WIDGET, data)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const WidgetCreation = () => {
    return (
      <>
        <div className="w-100 h-100 p-5">
          <Row>
            <Col sm={12} md={6} lg={6} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select User :</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <ReactSelect
                    data={
                      userData &&
                      userData.map(({ id, full_name }) => ({
                        value: id,
                        label: full_name,
                      }))
                    }
                    value={currUserWidget && currUserWidget}
                    placeholder={"Select User"}
                    handleChange={(e) => {
                      setCurrUserWidget(e);
                      HandleChangeForWidget(e, false);
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={6} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Select Site :</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <ReactSelect
                    data={
                      siteData &&
                      siteData.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    value={currSiteWidget && currSiteWidget}
                    placeholder={"Select Site"}
                    handleChange={(e) => {
                      setCurrSiteWidget(e);
                      HandleChangeForWidget(e, true);
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={12} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Existing Allocated Channels :</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  {/* multi select box for widget */}
                  <Col
                    style={{
                      width: "100%",
                      minHeight: "9vh",
                      maxHeight: "35vh",
                      overflow: "auto",
                      border: "1px solid rgba(0,0,0,0.1)",
                      boxShadow: " inset 0.5px 0.5px 2px  rgba(0,0,0,0.1)",
                      borderRadius: "3px",
                    }}
                  >
                    {channelWidget.length > 0 ? (
                      <>
                        <div
                          style={{
                            margin: "7px",
                            display: "inline-flex",
                          }}
                        >
                          <span>
                            <input
                              type="checkbox"
                              defaultChecked={selectAllWidget}
                              onChange={(val) => {
                                setSelectAllWidget(!selectAllWidget);
                                HnadleSelectAll_Widget(!selectAllWidget);
                              }}
                            />{" "}
                            &nbsp; Select All
                          </span>
                        </div>

                        {channelWidget.map((e, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                margin: "7px",
                                display: "inline-flex",
                              }}
                            >
                              <span>
                                <input
                                  type="checkbox"
                                  defaultChecked={channelWidget[index].status}
                                  onChange={(val) => {
                                    handleSingleSelect_Widget(
                                      index,
                                      channelWidget[index].status
                                    );
                                  }}
                                />{" "}
                                &nbsp; {e.name}
                              </span>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      " "
                    )}
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/* for second row  */}
            <Col sm={6} md={4} lg={2} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Max Current </b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <input
                    type="number"
                    ref={maxcurrent}
                    className="form-control"
                  />
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={2} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Max Voltage</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <input
                    type="number"
                    ref={maxvoltage}
                    className="form-control"
                  />
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={2} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Max Power</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <input
                    type="number"
                    ref={maxpower}
                    className="form-control"
                  />
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> No of samples</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <input type="text" ref={noSample} className="form-control" />
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3} className="my-2">
              <InputLabel id="demo-simple-select-outlined-label">
                <b> Resampling window</b>
              </InputLabel>
              <div className="d-flex w-100">
                <div className="w-100">
                  <ReactSelect
                    data={timeScale.map(({ value, field }, index) => ({
                      value: value,
                      label: field,
                    }))}
                    handleChange={(e) => {
                      resamblingTest.current = e;
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row className="my-3">
            <Button
              className="float-left ml-3"
              variant="contained"
              color="primary"
              onClick={() => {
                Numericwidgets(
                  maxpower.current.value,
                  maxcurrent.current.value,
                  maxvoltage.current.value
                );
              }}
            >
              Create Numeric widgets
            </Button>
            <Button
              className="float-left ml-3"
              variant="contained"
              color="primary"
              onClick={() =>
                CreateGraphWidget(
                  maxpower.current.value,
                  maxcurrent.current.value,
                  maxvoltage.current.value,
                  noSample.current.value,
                  resamblingTest.current
                )
              }
            >
              Create Graph widgets
            </Button>
          </Row>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="main-wrapper overflow-hidden">
        <Header {...props} />
        <Container fluid>
          <div className="mb-3 d-flex">
            <div>
              <img
                src={backLogo}
                alt="back"
                className="backBtn"
                onClick={backBtnHandler}
              />
            </div>
            <div>
              <h4 className="ml-2">
                <b>Deployment Zone</b>
              </h4>
            </div>
          </div>

          {/* extra box */}
          <div>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Channel Allocation" {...a11yProps(0)} />
                  <Tab label=" Widget Creation" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <ChannelAllocation />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <WidgetCreation />
                </TabPanel>
              </SwipeableViews>
            </div>
          </div>
        </Container>
        <ToastContainer />
        <Footer {...props} />
      </div>
    </>
  );
};

export default DeploymentZone;
