import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import { aggregateFunc, timeScale } from "../utils/Helpers";

export const EditGraphWidgetModal = (props) => {
  const { selectedWidget: widget } = props;

  const [title, setTitle] = React.useState("");
  const [x_label, set_x_label] = React.useState("");
  const [y_label, set_y_label] = React.useState("");
  const [point, setPoint] = React.useState(0);

  const [timescale, setTimescale] = React.useState(timeScale[0].value);
  const [graph_function, setGraph_function] = React.useState("Select Function");
  const [y_min, set_y_min] = React.useState(0);
  const [y_max, set_y_max] = React.useState(0);

  useEffect(() => {
    setTitle(props.selectedWidget.title);
    set_x_label(props.selectedWidget.x_label);
    set_y_label(props.selectedWidget.y_label);
    setPoint(props.selectedWidget.point);
    setTimescale(props.selectedWidget.timeScale);
    setGraph_function(props.selectedWidget.function);
    set_y_min(props.selectedWidget.y_min);
    set_y_max(props.selectedWidget.y_max);
  }, [props?.selectedWidget]);

  const handleSave = () => {
    const data = {
      channel_id: props?.selectedWidget.channel,
      widget_id: props?.selectedWidget.id,
      title: title,
      x_label: x_label,
      y_label: y_label,
      point: parseInt(point),
      timeScale: timescale,
      function: graph_function,
      y_min: parseFloat(y_min),
      y_max: parseFloat(y_max),
    };

    if (!validateForm(data))
      return props.handleToast("error", "Invalid Values");
    props.onClose();
    props.onSave(data);
  };

  const validateForm = (data) => {
    if (
      data.title === "" ||
      data.x_label === "" ||
      data.y_label === "" ||
      data.point === "" ||
      data.point < 0 ||
      data.timeScale === "" ||
      data.timeScale === undefined ||
      data.function === "" ||
      data.function === undefined ||
      data.y_min === undefined ||
      data.y_min === null ||
      data.y_max === undefined ||
      data.y_max === null
    ) {
      return false;
    } else if (data.timeScale !== "0" && data.function === "Select Function") {
      return false;
    } else {
      return true;
    }
  };

  const timeScaleSelect = () => {
    return (
      <FormControl variant="outlined" className="w-50 m-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Timescales
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Timescale"
          value={timescale}
          onChange={(e) => {
            setTimescale(e.target.value);
            e.target.value === 0
              ? setGraph_function("Select Function")
              : console.log("check work");
          }}
        >
          {timeScale.map((el, index) => (
            <MenuItem key={el.value} value={el.value}>
              {el.field}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const aggreFunctionSelect = () => {
    return (
      <FormControl variant="outlined" className="w-50 m-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Functions
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Function"
          value={graph_function}
          onChange={(e) => setGraph_function(e.target.value)}
          disabled={timescale === "0"}
        >
          <MenuItem key="Select Function" value="Select Function">
            Select Function
          </MenuItem>
          {aggregateFunc.map((el, index) => (
            <MenuItem key={index} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderModal = () => {
    if (Object.keys(widget).length) {
      return (
        <Dialog
          open={props.isGraphEditModalOpen}
          onClose={props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Edit {String(widget.title)}
          </DialogTitle>
          <DialogContent>
            <div>
              <form noValidate autoComplete="off">
                <div className="d-flex">
                  <TextField
                    label="Title"
                    variant="outlined"
                    className="w-50 m-2"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <TextField
                    label="Data Points"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="w-50 m-2"
                    value={point}
                    onChange={(e) => setPoint(e.target.value)}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <TextField
                    label="X-Label"
                    variant="outlined"
                    className="w-50 m-2"
                    value={x_label}
                    onChange={(e) => set_x_label(e.target.value)}
                  />

                  <TextField
                    label="Y-Label"
                    variant="outlined"
                    className="w-50 m-2"
                    value={y_label}
                    onChange={(e) => set_y_label(e.target.value)}
                  />
                </div>

                <div className="d-flex">
                  <TextField
                    label="Y-Min"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className=" m-2"
                    value={y_min}
                    onChange={(e) => set_y_min(e.target.value)}
                  />
                  <TextField
                    label="Y-Max"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className=" m-2"
                    value={y_max}
                    onChange={(e) => set_y_max(e.target.value)}
                  />
                </div>
                <div className="d-flex">
                  {timeScaleSelect()}
                  {aggreFunctionSelect()}
                </div>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return false;
  };
  return renderModal();
};
