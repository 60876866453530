import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import axios from "axios";

import { Button } from "@material-ui/core";
import {
  CHANNEL_FEED_EXPORT,
  CHANNEL_FEED_IMPORT,
  CSV_IMPORT_TEMPLATE,
} from "../shared/ApiURLs";

const DataImportExportView = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const onFileUploadHandler = () => {
    props.handleLoading(true);

    if (selectedFile) {
      const formdata = new FormData();
      formdata.append("channel_id", props.channelData.id);
      formdata.append("file", selectedFile);
      formdata.append("file_name", selectedFile.name);
      axios
        .post(CHANNEL_FEED_IMPORT, formdata)
        .then((res) => {
          console.log(res);
          setSelectedFile();
          setIsSelected(false);
          props.handleLoading(false);
          props.handleToast("success", res.data.message);
        })
        .catch((e) => {
          console.log(e);
          setSelectedFile();
          setIsSelected(false);
          props.handleLoading(false);
          props.handleToast("error", "Please Choose a CSV File!");
        });
    } else {
      props.handleLoading(false);
      props.handleToast("error", "Please Choose a CSV File!");
    }
  };

  return (
    <Container fluid>
      <Row className="my-5">
        <Col sm={12} md={6} className="col_divider text-center">
          <h3 className="">Import</h3>
          <p className="mb-3">
            Upload a CSV file to import data into this channel's Feed.
            <small className=" d-block">
              <a
                href={CSV_IMPORT_TEMPLATE}
                download
                target="_blank"
                rel="noreferrer"
              >
                Sample Import CSV File &#8595;
              </a>
            </small>
          </p>

          <div>
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                accept=".csv"
                onChange={(event) => changeHandler(event)}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <Button
                color="primary"
                className="px-4 py-2"
                variant="contained"
                component="span"
              >
                Choose File
              </Button>
            </label>
          </div>
          <button
            hidden={!isFilePicked}
            className="px-4 py-2 btn btn-primary mt-3"
            onClick={onFileUploadHandler}
          >
            Upload : {selectedFile?.name}
          </button>
        </Col>
        <Col sm={12} md={6} className="text-center">
          <h3 className="mb-3">Export</h3>
          <p className="mb-3">
            Download all of this Channel's feeds in CSV format.
          </p>
          <div className="mx-auto">
            <a
              className="px-4 py-2 btn btn-success"
              href={CHANNEL_FEED_EXPORT(props.channelData.id)}
              download
              target="_blank"
              rel="noreferrer"
            >
              DOWNLOAD
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DataImportExportView;
