export const serverURL = process.env.REACT_APP_BASE_URL;

// User Management
export const SEND_OTP = `${serverURL}/send_otp/`;
export const LOGIN = `${serverURL}/login/`;
export const FORGOT_PASSWORD = `${serverURL}/forget/`;
export const RESET_PASSWORD = `${serverURL}/reset/`;

// Channels

//  ------------------------Private--------------------------------------
export const GET_CHANNELS = (page_no = 0, search_by_name = "") =>
  `${serverURL}/channels/all?page_no=${page_no}&search_by_name=${search_by_name}`;

export const GET_DEPLOYEMENT_ZONE = (user = null, siteObj = null) =>
  `${serverURL}/channels/get-Deployement-Zone?user=${user}&site=${siteObj}`;
export const POST_GRAPH_WIDGET = `${serverURL}/channels/Graph-Widget-Creation-Data`;

export const WIDGET_CREATION_DATA = `${serverURL}/channels/widget-creation-data`;

export const GET_CHANNEL_BY_ID = `${serverURL}/channels/get_channel_by_id`;
export const CREATE_CHANNEL = `${serverURL}/channels/create_channel`;
export const UPDATE_CHANNEL_SETTING = `${serverURL}/channels/update_channel_setting`;
export const DELETE_CHANNEL = `${serverURL}/channels/delete_channel`;
export const CLEAR_CHANNEL_FEED = `${serverURL}/channels/clear_channel_feed`;
export const CHANGE_CHANNEL_SHARING = `${serverURL}/channels/change_channel_sharing`;
//  ------------------------Public---------------------------------------
export const GET_PUBLIC_CHANNELS = (page_no = 0, search_by_name = "") =>
  `${serverURL}/channels/public?page_no=${page_no}&search_by_name=${search_by_name}`;

export const GET_PUBLIC_CHANNEL_BY_ID = `${serverURL}/channels/get_public_channel_by_id`;
// -----------------------------------------------------------------------------------------

//  ------------------------Soling Zone---------------------------------------
export const GET_SOILING_CHANNELS = (
  page_no = 0,
  search_by_name = "",
  Spoling_block = ""
) =>
  `${serverURL}/channels/get_channels_for_soiling_zone?page_no=${page_no}&search_by_name=${search_by_name}&Spoling_block=${Spoling_block}`;
// -----------------------------------------------------------------------------------------

// Channel Numeric Widget
export const NUMERIC_WIDGET_GET = (id) =>
  `${serverURL}/channels/numeric_widget?channel_id=${id}`;
export const NUMERIC_WIDGET_DELETE = (channel_id, widget_id) =>
  `${serverURL}/channels/numeric_widget?channel_id=${channel_id}&widget_id=${widget_id}`;
export const NUMERIC_WIDGET = `${serverURL}/channels/numeric_widget`;

// Channel Graph Widget
export const GRAPH_WIDGET_GET = (id) =>
  `${serverURL}/channels/graph_widget?channel_id=${id}`;
export const GRAPH_WIDGET_DELETE = (channel_id, widget_id) =>
  `${serverURL}/channels/graph_widget?channel_id=${channel_id}&widget_id=${widget_id}`;
export const GRAPH_WIDGET = `${serverURL}/channels/graph_widget`;

// Channel Feed Export-Import
export const CHANNEL_FEED_EXPORT = (channel_id) =>
  `${serverURL}/channels/export_channel_feed_data?channel_id=${channel_id}`;
export const CHANNEL_FEED_IMPORT = `${serverURL}/channels/import_channel_feed_data`;
export const CSV_IMPORT_TEMPLATE = `${serverURL}/channels/csv_import_template`;

// Download Zone
export const DOWNLOAD_CHANNELS_DATA = `${serverURL}/channels/download_data`;
export const GET_DOWNLOAD_ZONE_CHANNELS = `${serverURL}/channels/get_downloadable_channels`;
export const DOWNLOAD_ZONE_WMS_DATA = `${serverURL}/channels/Download-WMS-data`;

// Upload Zone
export const GET_WMS_HISTORY = `${serverURL}/channels/get_wms_upload_history`;
export const WMS_FILE_UPLOAD = `${serverURL}/channels/upload_wms_file`;
export const SAMPLE_WMS_CSV = `${serverURL}/channels/wms_csv_import_template`;

// Channel Combination Graph Widget
export const COMBI_GRAPH_WIDGET_GET = (id) =>
  `${serverURL}/channels/combi_graph_widget?channel_id=${id}`;
export const COMBI_GRAPH_WIDGET_DELETE = (channel_id, widget_id) =>
  `${serverURL}/channels/combi_graph_widget?channel_id=${channel_id}&widget_id=${widget_id}`;
export const COMBI_GRAPH_WIDGET = `${serverURL}/channels/combi_graph_widget`;

// Analytics
// export const GET_DATE_RANGE = `${serverURL}/channels/get_mmb_wms_date_range`;
export const GET_DATE_RANGE = `${serverURL}/channels/get_analytics_date_range`;
export const GENERATE_MMB_WMS_PLOTS = `${serverURL}/channels/generate_mmb_wms_plots`;
export const GET_ANALYTICS_HISTORY = (tab) =>
  `${serverURL}/channels/${tab}/get_analytics_history`;
export const DOWNLOAD_MMB_WMS_PLOT_ZIP = (historyID) =>
  `${serverURL}/channels/${historyID}/download_mmb_wms_plots`;

export const GET_CLIPPING_LOSS = (selectSite) =>
  `${serverURL}/channels/Get-date-Clipping-Loss&selectSite=${selectSite}`;

export const DOWNLOAD_FETCH_DATA_GENERATE_PLOTS = `${serverURL}/channels/max_curr_vs_max_rad`;
export const DOWNLOAD_FETCH_DATA_GENERATE_PLOTS_FOR_DEGRADATION = `${serverURL}/channels/degradation_analysis`;
export const GET_ANALYTICS_ZONE_CHANNELS = `${serverURL}/channels/get_channels_for_analytics_zone`;
export const GENERATE_WMS_PLOTS = `${serverURL}/channels/ClippingLoss-Generation-Plots`;

export const GET_DSB_DONGLE_SIM_ZONE = (modelName) =>
  `${serverURL}/channels/DSB_Sim_DongalCreation?modelName=${modelName}`;
export const GET_EXISTING_DSB_DONGLE_SIM = (modelName) =>
  `${serverURL}/channels/GetData-Existing-Sim-Dongal-DSB?modelName=${modelName}`;
export const CLIPPING_LOSS_FETCH_DATA_OR_GENERATE_PLOTS = `${serverURL}/channels/ClippingLoss-FetchData-GenrateReport`;

// notification
export const NOTIFICATION_ALL = `${serverURL}/channels/notifications/all`;
export const NOTIFICATION_DELETE = `${serverURL}/channels/notifications/delete`;

// calibration
export const GET_CALIBRATION_CHANNEL = `${serverURL}/channels/get_all_channels`;
export const GET_CHANNEL_RAW_DATA = (channel_id) =>
  `${serverURL}/channels/get_raw_current_voltage?channel_id=${channel_id}`;

export const CALIBRATE = `${serverURL}/channels/add-calibration`;

export const GET_ICS_HISTORY = `${serverURL}/channels/get_cleaning_upload_history`;
export const ICS_FILE_UPLOAD = `${serverURL}/channels/upload_cleaning_data_file`;
export const MODULE_DETAILS_UPLOAD = `${serverURL}/channels/upload_module_details_files`;
export const SAMPLE_ICS_CSV = `${serverURL}/channels/cleaning_csv_import_template`;
export const SAMPLE_MODULE_CSV = `${serverURL}/channels/module_details_import_template`;
export const SAVE_CALIBRATE = `${serverURL}/channels/save-calibration-result`;
export const RESET_CALIBRATE = `${serverURL}/channels/reset-calibration-result`;
export const CLEAR_FEED_PVD = `${serverURL}/channels/clear-feed-pvd`;
export const SOILING_TRENDS_WITHOUT_CLEANING = `${serverURL}/channels/soiling_trends_without_cleaning`;
export const SOILING_TRENDS_WITH_CLEANING_DATA = `${serverURL}/channels/soiling_trends_with_cleaning_data`;
export const CHECK_SOILING_RATE_DATA = `${serverURL}/channels/soiling_trends_with_cleaning_data`;
export const UPDATE_CHANNEL_KEY = `${serverURL}/channels/update_channel_key`;

export const DOWNLOAD_CLEANING_DETECTION = `${serverURL}/channels/cleaning_detection_data`;
export const GET_EKO_DATA = (token) =>
  `${serverURL}/channels/eko_data/${token}`;
export const VERIFY_TOKEN = (token) =>
  `${serverURL}/verify-user-token/${token}`;
export const DOWNLOAD_EKO_DATA = `${serverURL}/channels/download-eko-data`;

export const GET_CLEANING_BLOCKS = `${serverURL}/channels/get-cleaning-blocks`;
