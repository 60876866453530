import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Button, Container, Tooltip } from "@material-ui/core";
import { FormControl } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Moment from "moment";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { GET_CLEANING_BLOCKS, GET_SOILING_CHANNELS } from "../shared/ApiURLs";
import { addHeaderInAxios, checkSignature } from "../utils/Helpers";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import MyLoader from "../components/ContentLoader";
import ReactPaginate from "react-paginate";

import { Nav } from "react-bootstrap";
import { blue } from "@material-ui/core/colors";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "white",
        border: "0.5px solid black",
      },
    },
  },
});

class SoilingZone extends Component {
  state = {
    searchBarValue: "",
    filteredChannels: [],
    isContentLoading: true,
    cleaning_blocks: [],
    clening_BlockValue_Store: "",
    isLoading: true,

    num_of_pages: 0,
    limit: 0,
    current_page: 0,
  };

  // componentDidUpdate = () => {
  //   if(this.state.clening_BlockValue_check){
  //     this.get_cleaning_blocks()
  //   }
  //   // this.get_cleaning_blocks()

  // }
  // update

  get_channel_by_block = () => {
    this.setState({
      ...this.state,
      loading: true,
      isLoading: true,
    });

    axios
      .get(
        GET_SOILING_CHANNELS(
          this.state.current_page + 1,
          "",
          this.state.clening_BlockValue_Store
        )
      )
      .then((res) => {
        this.setState(
          {
            filteredChannels: res.data.soiling_data,
            isContentLoading: false,
            isLoading: false,
            num_of_pages: res.data.num_of_pages,
            limit: res.data.limit,
          },
          () => console.log(this.state.num_of_pages)
        );
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({
          isContentLoading: false,
          isLoading: false,
        });
      });
  };

  get_cleaning_blocks = () => {
    axios
      .get(GET_CLEANING_BLOCKS)
      .then((res) => {
        this.setState({
          ...this.state,

          cleaning_blocks: res.data.cleaning_blocks,
        });
        this.state.clening_BlockValue_Store = this.state.cleaning_blocks[0];
        this.get_channel_by_block();
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({
          isContentLoading: false,
        });
      });
  };

  Set_cleaning_blocks = (val) => {
    this.state.clening_BlockValue_Store = val;
    this.state.current_page = 0;
    this.state.num_of_pages = 1;
    this.get_channel_by_block();
  };

  componentDidMount = () => {
    addHeaderInAxios(this.props)
      ? // ? this.get_channel_by_block()
        this.get_cleaning_blocks()
      : this.props.history.replace("/");
  };

  handlePageClick = (event) => {
    console.log(event);
    this.setState(
      {
        ...this.state,
        current_page: event.selected,
      },
      () => {
        this.get_channel_by_block();
      }
    );
  };

  render() {
    console.log(this.state.isLoading);
    return (
      <div className="main-wrapper">
        <Header {...this.props} />
        {this.state.isContentLoading && <MyLoader />}
        {!this.state.isContentLoading && (
          <Container>
            <div className="SideNavigation">
              <div className="SoilingNav_Head">
                <Typography component="p" style={{ fontSize: "16px" }}>
                  <b>Select Cleaning Block </b>
                </Typography>
              </div>

              <div className="SoilingNav_Body">
                <ul>
                  {this.state.cleaning_blocks.map((e, index) => {
                    {
                      /* console.log(index, e) */
                    }
                    return (
                      <>
                        <li
                          className="SoilingNav_Body_Btn"
                          style={
                            e == this.state?.clening_BlockValue_Store
                              ? { color: "blue" }
                              : {}
                          }
                          onClick={(event) => {
                            this.Set_cleaning_blocks(e);
                          }}
                        >
                          <span>{e} </span>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* added navigation   */}
            <div className="mb-3">
              <h2>Underperformance Zone</h2>
            </div>

            <div className="mb-5">
              {this.state.filteredChannels.length > 0 ? (
                this.state.filteredChannels.map((el) => {
                  return (
                    <Col className="p-3 my-2 publicChannelCard">
                      {this.state.isLoading ? (
                        <MyLoader />
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            component="div"
                            className="p-3"
                          >
                            {/*  todo */}

                            {el.channels.length > 0 && (
                              <>
                                <Typography
                                  component="p"
                                  style={{ fontSize: "16px" }}
                                >
                                  <b>Cleaning Block : </b>
                                  {el.cleaning_block}
                                </Typography>

                                <Typography
                                  component="p"
                                  style={{ fontSize: "16px" }}
                                >
                                  <b>Average Soiling Percentage : </b>
                                  {(
                                    parseFloat(el.average_soiling) * 100
                                  )?.toFixed(2)}
                                  %
                                </Typography>
                              </>
                            )}
                          </Typography>
                          <div className="mainSoilingChannelCard mb-4">
                            {el.channels.length > 0 ? (
                              el.channels.map((ele) => {
                                return (
                                  <>
                                    <Col
                                      sm={12}
                                      md={4}
                                      className="my-1"
                                      key={ele.id}
                                    >
                                      <div
                                        className={
                                          ele.status
                                            ? "borderSuccess soilingChannelCard"
                                            : "borderDanger soilingChannelCard"
                                        }
                                      >
                                        <CardActionArea>
                                          <CardContent>
                                            <Typography
                                              gutterBottom
                                              variant="h5"
                                              component="h2"
                                            >
                                              <Link
                                                to={`public_channel/${ele.id}`}
                                              >
                                                {ele.name}
                                              </Link>
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              color="textSecondary"
                                              component="div"
                                            >
                                              <Typography component="p">
                                                <small>
                                                  <b>Channel Created At : </b>
                                                  {Moment(
                                                    ele.channel_created_at
                                                  ).format("MMM Do YYYY")}
                                                </small>
                                              </Typography>

                                              <Typography component="p">
                                                <small>
                                                  <b>Feed Updated At : </b>
                                                  {Moment(
                                                    ele.feed_updated_at
                                                  ).format(
                                                    "MMM Do YYYY, h:mm:ss A"
                                                  )}
                                                </small>
                                              </Typography>
                                            </Typography>
                                          </CardContent>
                                        </CardActionArea>
                                        <div className="card-body p-2">
                                          <div className="d-flex py-2">
                                            <Card className="p-2 w-100 mx-1 text-center ">
                                              <span className="px-2 pb-2"></span>
                                              <span className="">
                                                <b>
                                                  {Moment(ele.date).format(
                                                    "MMM Do YYYY"
                                                  )}
                                                </b>
                                              </span>
                                            </Card>
                                            <MuiThemeProvider theme={theme}>
                                              <Card className="p-2 w-100 mx-1 text-center">
                                                <span className="px-2 pb-2">
                                                  Soiling (%) :
                                                </span>
                                                <span className="">
                                                  <b>
                                                    {(
                                                      parseFloat(
                                                        ele.soiling_percent
                                                      ) * 100
                                                    ).toFixed(2)}
                                                  </b>
                                                </span>
                                              </Card>
                                            </MuiThemeProvider>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </>
                                );
                              })
                            ) : (
                              <h4
                                className="text-center py-1"
                                style={{ margin: "auto" }}
                              >
                                No Channels Found!{" "}
                              </h4>
                            )}
                          </div>

                          {this.state.num_of_pages > 1 && (
                            <div className="d-flex justify-content-end ">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={this.state.limit}
                                pageCount={this.state.num_of_pages}
                                previousLabel="< previous"
                                renderOnZeroPageCount={false}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </Col>
                  );
                })
              ) : (
                <h5 className="text-center" style={{ padding: "5%" }}>
                  No Cleaning Block Group Found!{" "}
                </h5>
              )}
            </div>
          </Container>
        )}
        <Footer />
      </div>
    );
  }
}

export default SoilingZone;
