import React from "react";
import { Container } from "react-bootstrap";
import PublicViewWidgets from "./publicViewWidgets";

const PublicView = (props) => {
  const { handleChangeIndex, channelData } = props;

  const handlePublicView = () => {
    if (channelData.is_private) {
      return (
        <div>
          <h2>This channel is not public.</h2>
          <p>
            To make this channel public, navigate to{" "}
            <span
              className="makeLink"
              onClick={() => {
                handleChangeIndex(3);
              }}
            >
              Sharing
            </span>
            .{" "}
          </p>
        </div>
      );
    } else {
      return (
        <Container fluid={true}>
          <h2>Public View</h2>
          <p>
            Other users will be able to see your channel data and export it but
            wont be able to write data on your channel.
          </p>
          <PublicViewWidgets
            channel={props.channelData}
            feed={props.feedData}
          />
        </Container>
      );
    }
  };

  return handlePublicView();
};

export default PublicView;
