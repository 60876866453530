import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";

export const EditNumericWidgetModal = (props) => {
  const { selectedWidget: widget } = props;
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    setName(props.selectedWidget.name);
    setUnit(props.selectedWidget.unit);
    setMin(props.selectedWidget.minValue);
    setMax(props.selectedWidget.maxValue);
  }, [props?.selectedWidget]);

  const handleSave = () => {
    let obj = {
      channel_id: widget.channel,
      widget_id: widget.id,
      name: name,
      unit: unit,
      min: parseInt(min),
      max: parseInt(max),
    };

    if (!validateForm(obj)) return props.handleToast("error", "Invalid Values");
    props.onClose();
    props.onSave(obj);
  };

  const validateForm = (data) => {
    if (
      data.name === "" ||
      data.unit === "" ||
      data.unit === undefined ||
      data.unit === null ||
      data.min === null ||
      data.min === undefined ||
      data.min === null ||
      data.max === undefined
    ) {
      return false;
    }
    return true;
  };

  const renderModal = () => {
    if (Object.keys(widget).length) {
      return (
        <Dialog
          open={props.isNumEditModalOpen}
          onClose={props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Edit {String(widget.name)}
          </DialogTitle>
          <DialogContent>
            <div>
              <form noValidate autoComplete="off">
                <TextField
                  label="Name"
                  variant="outlined"
                  className="w-100 mb-2"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  label="Unit"
                  variant="outlined"
                  className="w-100 mb-2"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                />
                <div>
                  <TextField
                    label="Min"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="mr-1"
                    value={min}
                    onChange={(e) => setMin(e.target.value)}
                  />
                  <TextField
                    label="Max"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="ml-1"
                    value={max}
                    onChange={(e) => setMax(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return false;
  };
  return renderModal();
};
