import React from "react";
import closeIcon from "../assets/images/close.png";
import editIcon from "../assets/images/edit.png";
import CombiLineChart from "../shared/Multi_YAxislineChart";

const CombiGraphChartCard = (props) => {
  const {
    feed_data,
    wms_data,
    title,
    x_label,
    y1_label,
    y2_label,
    y1_min,
    y1_max,
    y2_min,
    y2_max,
    widget,
  } = props;

  return (
    <div className="graphWidgetCard card">
      <div className="card-header mb-3">
        <div className="b_radius d-flex justify-content-between">
          <h6 className="mb-0 pl-2 pt-2 wrapText w-75">{title}</h6>
          <span className="float-right">
            <img
              className="mr-2 c-pointer"
              style={{ filter: "brightness(2)" }}
              src={editIcon}
              alt="edit"
              onClick={() => props.onEdit(widget)}
            />
            <img
              className="c-pointer"
              src={closeIcon}
              alt="closeIcon"
              onClick={() => props.onDelete(widget)}
            />
          </span>
        </div>
      </div>
      <div className="p-0">
        <CombiLineChart
          {...props}
          feed_data={feed_data}
          wms_data={wms_data}
          x_label={x_label}
          y1_label={y1_label}
          y2_label={y2_label}
          title={title}
          y1_min={y1_min}
          y1_max={y1_max}
          y2_min={y2_min}
          y2_max={y2_max}
          widget={widget}
        />
      </div>
    </div>
  );
};

export default CombiGraphChartCard;
