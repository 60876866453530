import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toast = ({isOpen,message,color,handleClose}) => {
    return(
        <Snackbar open={isOpen} onClose={handleClose}
            autoHideDuration={2000} anchorOrigin={{ vertical:'top', horizontal:'right' }}>
            <Alert severity={color}>{message}</Alert>
        </Snackbar>
    )
}

export default Toast