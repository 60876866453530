import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { RESET_PASSWORD } from "../shared/ApiURLs";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import logo from "../assets/images/logo.png";
import loginImage from "../assets/images/login.png";
import Toast from "../shared/Toast";

const inputStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const ResetPassword = (props) => {
  const inputClasses = inputStyles();

  const [password, setPassword] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cPassHelper, setCPassHelper] = useState("");
  const [errors, setErrors] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [message, setMessage] = useState("");
  const [toastColor, setToastColor] = useState("");

  const hassError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const validate = () => {
    var temp_errors = [...errors];

    if (password === "") {
      temp_errors.push("password_blank");
      setPasswordHelper("Password is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "password_blank");
      setPasswordHelper("");
    }

    if (password.length < 8) {
      temp_errors.push("password_minLength");
      setPasswordHelper("Atleast 8 characters are required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "password_minLength");
      setPasswordHelper("");
    }

    if (confirmPassword === "") {
      temp_errors.push("c_pass_blank");
      setCPassHelper("Confirm password is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "c_pass_blank");
      setCPassHelper("");
    }

    if (confirmPassword !== password) {
      temp_errors.push("passwords_mismatch");
      setCPassHelper("Passwords not matched");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "passwords_mismatch");
      setCPassHelper("");
    }

    setErrors(temp_errors);
    if (errors.length > 0) {
      return false;
    } else {
    }
  };

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    if (password === "") {
      setPasswordHelper("Password is required");
    } else if (password !== "") {
      setPassword(password);
      // setLogin(false);
    }
  };

  const handleConfirmPasswordChange = (event) => {
    let cPassword = event.target.value;
    if (cPassword === "") {
      // setCPassHelper('Confirm Password is required');
    } else if (password !== "" && cPassword !== "") {
      setConfirmPassword(cPassword);
      setDisableBtn(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validate();
    const requestData = {
      token: props.match.params.token,
      password: password,
      confirm_password: confirmPassword,
    };
    console.log("requestData: ", requestData);
    axios
      .post(RESET_PASSWORD, requestData)
      .then((res) => {
        console.log("response: ", res.data);
        setToastColor("success");
        setMessage(res.data.message);
        localStorage.setItem("auth_token", res.data.token);
        setTimeout(() => {
          props.history.push("/");
        }, 900);
      })
      .catch((error) => {
        console.log("api error: ", error);
      });
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage("");
    setToastColor("");
  };

  return (
    <>
      <Toast
        message={message}
        color={toastColor}
        handleClose={handleSnackClose}
      />

      <div className="container" style={{ marginTop: "50px" }}>
        <div className="row">
          <div className="col-12 mb-5">
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <h3>Reset Password</h3>
            <form className={inputClasses.root} onSubmit={handleSubmit}>
              <div className="form-group mt-5 mb-5">
                <TextField
                  error={
                    hassError("password_blank") ||
                    hassError("password_minLength")
                  }
                  onChange={(e) => handlePasswordChange(e)}
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  helperText={passwordHelper}
                  inputProps={{ maxLength: 8 }}
                  variant="outlined"
                />
              </div>
              <div className="form-group mt-5 mb-5">
                <TextField
                  error={
                    hassError("c_pass_blank") || hassError("passwords_mismatch")
                  }
                  onChange={(e) => handleConfirmPasswordChange(e)}
                  id="outlined-password-input"
                  label="Confirm Password"
                  type="password"
                  helperText={cPassHelper}
                  inputProps={{ maxLength: 8 }}
                  variant="outlined"
                />
              </div>
              <Button
                disabled={disableBtn}
                type="submit"
                variant="contained"
                color="primary"
              >
                Change Password
              </Button>
            </form>
          </div>
          <div
            className="col-lg-8 col-md-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={loginImage} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
