import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Col, Container, Row, Table } from "react-bootstrap";
import { CustomMultiSelect, ReactSelect } from "../components/ReactSelect";
import {
  GET_DSB_DONGLE_SIM_ZONE,
  GET_EXISTING_DSB_DONGLE_SIM,
} from "../shared/ApiURLs";
import "../App.css";
import axios from "axios";
import {
  Backdrop,
  InputLabel,
  Button,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Tab,
  Input,
  Modal,
} from "@material-ui/core";
import backLogo from "../assets/images/back.png";
import { toast, ToastContainer } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        overflowX: "auto",
        minHeight: "350px",
        marginBottom: "2rem",
      }}
      className="card"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

// main
const DsbDongleSimManagement = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [SimList, setSimList] = useState([]);
  const [DongleList, setDongleList] = useState([]);
  const [SiteList, setSiteList] = useState([]);
  const [AssebledList, setAssebledList] = useState([]);
  const [dsbList, setDsbList] = useState([]);
  const [ChannelValObj, setChannelValObj] = useState([]);
  const [value, setValue] = useState(0);

  //   Extra funtion start

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    console.log("tab changed ----", newValue);
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    console.log("tab changed ----", index);
    setValue(index);
  };
  function handleToast(event_type, message) {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  }
  useEffect(() => {
    onGettingDSB_Sim_dongal();
  }, []);
  //   Api calling
  const onGettingDSB_Sim_dongal = () => {
    axios
      .get(GET_DSB_DONGLE_SIM_ZONE())
      .then((res) => {
        if (res.data.status) {
          let res_ = res.data.data;
          setSimList(res_.simValObj);
          setDongleList(res_.doggleValObj);
          setAssebledList(res_.userValObj);
          setDsbList(res_.dsbValobj);
          setSiteList(res_.siteValObj);
          // setChannelValObj();
          var temp = res_?.channelValObj.map((val) => ({
            label: val?.name,
            value: val?.id,
          }));
          setChannelValObj(temp);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  todo SimMainFunction

  const SimMainFunction = (props) => {
    // const SimList = [];
    const SimList = props?.props?.SimList;
    const channelData = props?.props?.ChannelValObj;
    // console.log(channelData);

    const [openSim, setOpenSim] = useState(false);

    const [mobileNum, setMobileNum] = useState(null);
    const [carrierSim, setCarrierSim] = useState(null);
    const [iccidNum, setIccidNum] = useState(null);
    const [plan, setPaln] = useState(null);
    const [lastRechargeDate, setLastRechargeDate] = useState(null);
    const [lastRechargeAmount, setLastRechargeAmount] = useState(null);
    const [rechargeValidity, setRechargeValidity] = useState(null);
    const [apn, setApn] = useState(null);
    const [is_Iot, set_Is_Iot] = useState(false);
    const [bassket, setBasket] = useState(null);
    const [imeiNum, setImeiNum] = useState(null);
    const [simStatus, setSimStatus] = useState(false);
    const [channelVal, setChannelVal] = useState(null);
    const [Remark, setRemark] = useState(null);

    const [EditUniqueKey, setEditUniqueKey] = useState(null);
    const [updateCheck, setUpdateChect] = useState(false);

    // post api
    const onPostDSB_Sim_dongal = async (data) => {
      axios
        .post(GET_DSB_DONGLE_SIM_ZONE("sim"), data)
        .then((res) => {
          if (res.data.status) {
            toast.success(res.data.message);

            props?.props?.onGettingDSB_Sim_dongal();
          } else {
            if (res?.data?.message?.non_field_errors) {
              res?.data?.message?.non_field_errors.map((e) => {
                toast.error(e);
              });
            } else {
              let error = Object.entries(res?.data?.message);
              error?.map((e) => {
                toast.error(`${e[0]} ${e[1]}`);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onFormHnadle = () => {
      let data = {
        openSim: openSim,
        mobileNum: mobileNum,
        carrierSim: carrierSim?.value,
        iccidNum: iccidNum,
        plan: plan,
        lastRechargeDate: lastRechargeDate,
        lastRechargeAmount: lastRechargeAmount,
        rechargeValidity: rechargeValidity,
        apn: apn?.label,
        is_Iot: is_Iot,
        bassket: bassket,
        imeiNum: imeiNum,
        simStatus: simStatus,
        channelVal: channelVal?.value,
        remark: Remark,
      };
      onPostDSB_Sim_dongal(data);
    };

    const handleSimFormBtn = () => {
      setMobileNum(null);
      setIccidNum(null);
      setPaln(null);
      setLastRechargeDate(null);
      setLastRechargeAmount(null);
      setRechargeValidity(null);
      setApn(null);
      set_Is_Iot(false);
      setBasket(null);
      setImeiNum(null);
      setSimStatus(false);
      setChannelVal(null);
      setRemark(null);

      setOpenSim(!openSim);
      setCarrierSim(null);
    };

    const carrierOption = [
      {
        label: "Airtel",
        value: "Airtel",
      },
      {
        label: "Airtel IOT",
        value: "Airtel IOT",
      },
      {
        label: "Jio",
        value: "Jio",
      },
      {
        label: "Jio Business",
        value: "Jio Business",
      },
    ];
    const apnMapping = [
      {
        label: "jionet",
        value: "jio",
      },
      {
        label: "iot.com",
        value: "Airtel",
      },
      {
        label: "airtelgprs.com",
        value: "Airtel",
      },
    ];

    const ApnMappingFuntion = (e) => {
      if (e === "Jio" || e === "Jio Business") {
        var data = apnMapping.find((x) => x.value === "jio");
        setApn(data);
      } else if (e === "Airtel IOT") {
        var data = apnMapping.find((x) => x.label === "iot.com");
        setApn(data);
        // } else {

        // }
      } else if (e === "Airtel") {
        var data = apnMapping.find((x) => x.label === "airtelgprs.com");
        setApn(data);
      }
    };

    // For GETTING DATA EDIT
    const EditHandleGetData = (id) => {
      let data = { id: id };
      axios
        .post(GET_EXISTING_DSB_DONGLE_SIM("sim"), data)
        .then((res) => {
          if (res.data?.status) {
            let data = res.data?.data[0];
            setEditUniqueKey(data.id);

            data.mobile_no ? setMobileNum(data.mobile_no) : setMobileNum("");
            data.iccid ? setIccidNum(data.iccid) : setIccidNum("");
            data.plan ? setPaln(data.plan) : setPaln("");

            if (data.last_recharge_date !== null) {
              setLastRechargeDate(data.last_recharge_date);
            } else {
              setLastRechargeDate("dd/mm/yyyy");
            }
            if (data.recharge_validity !== null) {
              setRechargeValidity(data.recharge_validity);
            } else {
              setRechargeValidity("dd/mm/yyyy");
            }

            data.last_recharge_amount
              ? setLastRechargeAmount(data.last_recharge_amount)
              : setLastRechargeAmount("");
            data.is_iot ? set_Is_Iot(true) : set_Is_Iot(false);
            // set_Is_Iot(data.is_iot);
            data.basket ? setBasket(data.basket) : setBasket("");
            data.imei ? setImeiNum(data.imei) : setImeiNum("");

            data?.remark ? setRemark(data?.remark) : setRemark("");
            setSimStatus(data.sim_status);

            if (data.carrier !== null) {
              var temp = carrierOption.find((x) => x.value === data.carrier);
              setCarrierSim(temp);
            } else {
              setCarrierSim(null);
            }
            if (data.apn !== null) {
              var temp = apnMapping.find((x) => x.label === data.apn);
              setApn(temp);
            } else {
              setApn(null);
            }

            if (data.channel_id__id !== null) {
              var temp = channelData.find(
                (x) => x.value == data.channel_id__id
              );
              setChannelVal(temp);
            } else {
            }
            setUpdateChect(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const UpdateExistingData = () => {
      let data = {
        EditUniqueKey: EditUniqueKey,
        openSim: openSim,
        mobileNum: mobileNum,
        carrierSim: carrierSim?.value.length > 1 ? carrierSim?.value : null,
        iccidNum: iccidNum.toString(2).length > 1 ? iccidNum : null,
        plan: plan.length > 1 ? plan : null,
        lastRechargeDate: lastRechargeDate ? lastRechargeDate : null,
        lastRechargeAmount:
          lastRechargeAmount?.toString(2).length > 1
            ? lastRechargeAmount
            : null,
        rechargeValidity: rechargeValidity ? rechargeValidity : null,
        apn: apn?.label.length > 1 ? apn?.label : null,
        is_Iot: is_Iot,
        bassket: bassket.length > 1 ? bassket : null,
        imeiNum: imeiNum.toString(2)?.length > 1 ? imeiNum : null,
        simStatus: simStatus,
        remark: Remark.length > 1 ? Remark : null,
        channelVal: channelVal?.value ? channelVal?.value : null,
      };

      axios
        .put(GET_EXISTING_DSB_DONGLE_SIM("sim"), data)
        .then((res) => {
          if (res.data?.status) {
            toast.success(res?.data?.message);
            setUpdateChect(false);
            props?.props?.onGettingDSB_Sim_dongal();
          } else {
            if (res?.data?.message?.non_field_errors) {
              res?.data?.message?.non_field_errors.map((e) => {
                toast.error(e);
              });
            } else {
              let error = Object.entries(res?.data?.message);
              error?.map((e) => {
                toast.error(`${e[0]} ${e[1]}`);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return (
      <>
        <div className="w-100 h-10 p-1">
          {!openSim && (
            <Row className=" d-flex flex-row-reverse">
              <Col sm={6} md={4} lg={2} className="my-2">
                <div className="d-flex w-100">
                  <div className="w-100">
                    <Button
                      className="float-left"
                      variant="contained"
                      color="primary"
                      title="Get Date Range"
                      style={{ maxHeight: "40px", borderRadius: "none" }}
                      onClick={handleSimFormBtn}
                    >
                      Create SIM
                    </Button>
                  </div>
                </div>
              </Col>
              {/* Table for Existing User */}
            </Row>
          )}
          {/* Form Sim - Add */}
          {/* todo ravi */}
          {openSim && (
            <div>
              <Row>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      {" "}
                      Mobile No<span style={{ color: "red" }}>*</span>:
                    </b>
                  </InputLabel>
                  <input
                    type="number"
                    placeholder="enter  mobile no."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={mobileNum}
                    onChange={(e) => {
                      setMobileNum(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> ICCID</b>
                  </InputLabel>
                  <input
                    type="number"
                    placeholder="enter iccid no."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={iccidNum}
                    onChange={(e) => setIccidNum(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      {" "}
                      Carrier<span style={{ color: "red" }}>*</span>:
                    </b>
                  </InputLabel>

                  <ReactSelect
                    data={carrierOption}
                    value={carrierSim}
                    isLoading={false}
                    handleChange={(e) => {
                      setCarrierSim(e);
                      ApnMappingFuntion(e.label);
                    }}
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> Plan</b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter plan."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={plan}
                    onChange={(e) => setPaln(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> Last Recharge Date </b>
                  </InputLabel>
                  <input
                    type="date"
                    placeholder="enter  last recharge date"
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={lastRechargeDate}
                    onChange={(e) => setLastRechargeDate(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> Last Recharge Amount</b>
                  </InputLabel>
                  <input
                    type="number"
                    placeholder="enter last recharge amount."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={lastRechargeAmount}
                    onChange={(e) => setLastRechargeAmount(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> Recharge Validity</b>
                  </InputLabel>
                  <input
                    type="date"
                    placeholder="enter recharge validity."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={rechargeValidity}
                    onChange={(e) => setRechargeValidity(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> APN</b>
                  </InputLabel>
                  {/* <input
                    type="text"
                    placeholder="enter  apn."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={apn}
                    onChange={(e) => setApn(e.target.value)}
                  /> */}
                  {/* todo here */}
                  <ReactSelect
                    data={apnMapping}
                    value={apn}
                    // isLoading={false}
                    handleChange={setApn}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> Basket</b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter basket."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={bassket}
                    onChange={(e) => setBasket(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> IMEI</b>
                  </InputLabel>
                  <input
                    type="number"
                    placeholder="enter IMEI no."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={imeiNum}
                    onChange={(e) => setImeiNum(e.target.value)}
                  />
                </Col>
                {/* todo */}
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Select Channel </b>
                  </InputLabel>
                  <ReactSelect
                    data={channelData}
                    value={channelVal}
                    isLoading={false}
                    handleChange={(e) => {
                      setChannelVal(e);
                    }}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b> Remark</b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter remark."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={Remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={12} lg={6} className="my-4 d-flex">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      Is_Iot <span style={{ color: "red" }}>*</span> &nbsp;
                      <input
                        type="checkbox"
                        placeholder="enter is_iot"
                        className="wb-40 t-4  "
                        style={{
                          width: "25px",
                          height: "25px",
                          position: "relative",
                          top: "5px",
                          left: "2px",
                        }}
                        // defaultChecked={is_Iot}
                        checked={is_Iot}
                        onChange={(e) => {
                          is_Iot ? set_Is_Iot(false) : set_Is_Iot(true);
                        }}
                      />
                    </b>
                  </InputLabel>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ position: "relative", left: "1.5rem" }}
                  >
                    <b>
                      Sim Status<span style={{ color: "red" }}>*</span> &nbsp;
                      <input
                        type="checkbox"
                        placeholder="enter im data no."
                        className="wb-40 t-4  "
                        style={{
                          width: "25px",
                          height: "25px",
                          position: "relative",
                          top: "5px",
                          left: "2px",
                        }}
                        checked={simStatus}
                        onChange={(e) => {
                          setSimStatus(!simStatus);
                        }}
                      />
                    </b>
                  </InputLabel>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Button
                    className="w-50 mt-4"
                    variant="outlined"
                    color="primary"
                    onClick={handleSimFormBtn}
                    // disabled={!iscDataSheetValue}
                  >
                    Cancel
                  </Button>
                </Col>
                {!updateCheck && (
                  <Col sm={12} md={4} lg={3}>
                    <Button
                      className="w-50 mt-4"
                      variant="outlined"
                      color="primary"
                      // disabled={!iscDataSheetValue}
                      onClick={() => onFormHnadle()}
                    >
                      Submit
                    </Button>
                  </Col>
                )}
                {updateCheck && (
                  <Col sm={12} md={4} lg={3}>
                    <Button
                      className="w-50 mt-4"
                      variant="outlined"
                      color="primary"
                      // disabled={!iscDataSheetValue}
                      onClick={() => UpdateExistingData()}
                    >
                      Update
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          )}

          <React.Fragment>
            <div className="mt-5">
              <h5 className="text-center">
                <b>Existing Sim</b>
              </h5>
            </div>
            <TableContainer
              component={Paper}
              className="mb-3"
              style={{ border: "1px solid #cab8e5" }}
            >
              <Table aria-label="simple-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Mobile no </TableCell>
                    <TableCell align="center">Carrier</TableCell>
                    <TableCell align="center">plan</TableCell>
                    <TableCell align="center">Recharge Validity</TableCell>

                    {/* <TableCell align="center">imei</TableCell> */}
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                {SimList?.length ? (
                  <TableBody>
                    {SimList?.map((val, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {val.mobile_no ? val.mobile_no : "-"}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {val.carrier ? val.carrier : "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {val.plan ? val.plan : "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="wrapText"
                            align="center"
                          >
                            {val.recharge_validity
                              ? val.recharge_validity
                              : "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="d-flex justify-content-around"
                            align="center"
                          >
                            <Button
                              className="float-left"
                              variant="contained"
                              color="primary"
                              title="Get Date Range"
                              style={{
                                maxHeight: "40px",
                                borderRadius: "none",
                              }}
                              onClick={() => {
                                setOpenSim(true);
                                EditHandleGetData(val.id);
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="wrapText"
                        align="center"
                      ></TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="wrapText"
                        align="center"
                      >
                        <b>Sorry there is No Sim available</b>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="wrapText"
                        align="center"
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </React.Fragment>
        </div>
      </>
    );
  };
  // DongalMainFuntion
  const DongalMainFuntion = (props) => {
    const simData =
      props?.props?.DongleList !== undefined ? props?.props?.DongleList : [];
    const simList =
      props?.props?.SimList !== undefined ? props?.props?.SimList : [];

    const tempSimList = simList.map(({ id, mobile_no }) => ({
      value: id,
      label: mobile_no,
    }));
    // post api

    const ReactSelectFuntion = (probs) => {
      let id = probs?.probs?.id;
      let data = tempSimList.find((x) => x.value === id);

      // setValue(data);
      return <>{data?.label}</>;
    };

    const [openDongal, setOpenDonGal] = useState(false);

    const [selectSim, setSelectSim] = useState(null);
    const [dongle_id, setDongle_Id] = useState(null);
    const [makeDongle, setMakeDongle] = useState(null);
    const [serial_no, setSerial_No] = useState(null);
    const [imeiNoDongle, setImeiNoDongle] = useState(null);
    const [primaryMacId, setPrimaryMacId] = useState(null);
    const [statusDongle, setStatusDongle] = useState(false);
    const [remarkDongle, setRemarkDongle] = useState(null);
    const [is_universal, setIsUniversal] = useState(false);
    const [EditCheckKey, setEditCheckKey] = useState(false);
    const [updateTo, setUPdateTo] = useState(null);
    const handleOpenForm = () => {
      setSelectSim(null);
      setDongle_Id(null);
      setMakeDongle(null);
      setSerial_No(null);
      setImeiNoDongle(null);
      setPrimaryMacId(null);
      setStatusDongle(false);
      setRemarkDongle(null);
      setIsUniversal(false);
      setOpenDonGal(!openDongal);
    };
    // post
    const onPostDSB_dongal = async (data) => {
      axios
        .post(GET_DSB_DONGLE_SIM_ZONE("dongal"), data)
        .then((res) => {
          if (res.data.status) {
            toast.success(res.data.message);

            props?.props?.onGettingDSB_Sim_dongal();
          } else {
            if (res?.data?.message?.non_field_errors) {
              res?.data?.message?.non_field_errors.map((e) => {
                toast.error(e);
              });
            } else {
              let error = Object.entries(res?.data?.message);
              error?.map((e) => {
                toast.error(`${e[0]} ${e[1]}`);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const formHandle = () => {
      let data = {
        selectSim: selectSim?.value,
        dongle_id: dongle_id,
        makeDongle: makeDongle,
        serial_no: serial_no,
        imeiNoDongle: imeiNoDongle,
        primaryMacId: primaryMacId,
        statusDongle: statusDongle,
        remarkDongle: remarkDongle,
        is_universal: is_universal,
      };
      onPostDSB_dongal(data);
    };

    const Get_EditdataForDongal = (id) => {
      let data = { id: id };
      setUPdateTo(id);
      axios
        .post(GET_EXISTING_DSB_DONGLE_SIM("dongal"), data)
        .then((res) => {
          if (res.data?.status) {
            let data = res.data?.data[0];
            // console.log(data);
            data.dongle_id
              ? setDongle_Id(` ${data.dongle_id}`)
              : setDongle_Id("");

            if (data?.sim_id) {
              var temp = tempSimList.find((x) => x.value === data.sim_id);
              setSelectSim(temp);
            } else {
              setSelectSim(null);
            }

            data.make ? setMakeDongle(data.make) : setMakeDongle("");
            data.serial_no ? setSerial_No(data.serial_no) : setSerial_No("");
            data.imei_no ? setImeiNoDongle(data.imei_no) : setImeiNoDongle("");
            data.primary_mac_id
              ? setPrimaryMacId(data.primary_mac_id)
              : setPrimaryMacId("");
            data.status ? setStatusDongle(true) : setStatusDongle(false);
            data.remark ? setRemarkDongle(data.remark) : setRemarkDongle("");
            data.is_universal ? setIsUniversal(true) : setIsUniversal(false);

            // setOpenDonGal(!openDongal);
            setEditCheckKey(true);
            console.log(data);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleUpdateDongal = () => {
      let data = {
        EditUniqueKey: updateTo,
        selectSim: selectSim?.value ? selectSim?.value : null,
        dongle_id: dongle_id.length > 1 ? dongle_id : null,
        makeDongle: makeDongle.length > 1 ? makeDongle : null,
        serial_no: serial_no.length > 1 ? serial_no : null,
        imeiNoDongle: imeiNoDongle.toString().length > 1 ? imeiNoDongle : null,
        primaryMacId: primaryMacId.toString().length > 1 ? primaryMacId : null,
        statusDongle: statusDongle,
        remarkDongle: remarkDongle.length > 1 ? remarkDongle : null,
        is_universal: is_universal,
      };

      axios
        .put(GET_EXISTING_DSB_DONGLE_SIM("dongal"), data)
        .then((res) => {
          if (res.data?.status) {
            toast.success(res?.data?.message);
            // setUpdateChect(false);
            props?.props?.onGettingDSB_Sim_dongal();
          } else {
            if (res?.data?.message?.non_field_errors) {
              res?.data?.message?.non_field_errors.map((e) => {
                toast.error(e);
              });
            } else {
              let error = Object.entries(res?.data?.message);
              error?.map((e) => {
                toast.error(`${e[0]} ${e[1]}`);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return (
      <>
        <div className="w-100 h-10 p-1">
          {!openDongal && (
            <Row className=" d-flex flex-row-reverse">
              <Col sm={6} md={4} lg={2} className="my-2">
                <div className="d-flex w-100">
                  <div className="w-100">
                    <Button
                      className="float-left"
                      variant="contained"
                      color="primary"
                      title="Get Date Range"
                      style={{ maxHeight: "40px", borderRadius: "none" }}
                      onClick={() => handleOpenForm()}
                    >
                      Create Dongal
                    </Button>
                  </div>
                </div>
              </Col>
              {/* Table for Existing User */}
            </Row>
          )}
          {openDongal && (
            <div>
              <Row>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Select Sim</b>
                  </InputLabel>
                  <ReactSelect
                    data={simList.map(({ id, mobile_no }) => ({
                      value: id,
                      label: mobile_no,
                    }))}
                    value={selectSim}
                    //   placeholder={"Select Site"}
                    isLoading={false}
                    handleChange={setSelectSim}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      Dongle id <span style={{ color: "red" }}>*</span>
                    </b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter dongle id."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={dongle_id}
                    onChange={(e) => setDongle_Id(e.target.value)}
                    required
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      Make <span style={{ color: "red" }}>*</span>
                    </b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter make."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={makeDongle}
                    onChange={(e) => setMakeDongle(e.target.value)}
                    required
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      Serial No<span style={{ color: "red" }}>*</span>
                    </b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter serial no."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={serial_no}
                    onChange={(e) => setSerial_No(e.target.value)}
                    required
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Imei No</b>
                  </InputLabel>
                  <input
                    type="number"
                    placeholder="enter imei no."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={imeiNoDongle}
                    onChange={(e) => setImeiNoDongle(e.target.value)}
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Primary mac id</b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter primary mac id."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={primaryMacId}
                    onChange={(e) => setPrimaryMacId(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Remark</b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter remark."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={remarkDongle}
                    onChange={(e) => setRemarkDongle(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-4 d-flex">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      Status <span style={{ color: "red" }}>*</span> &nbsp;
                      <input
                        type="checkbox"
                        // placeholder="enter is_iot"
                        className="wb-40 t-4  "
                        style={{
                          width: "25px",
                          height: "25px",
                          position: "relative",
                          top: "5px",
                          left: "2px",
                        }}
                        checked={statusDongle}
                        onChange={() => setStatusDongle(!statusDongle)}
                      />
                    </b>
                  </InputLabel>{" "}
                  &nbsp; &nbsp;
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      is_universal <span style={{ color: "red" }}>*</span>{" "}
                      &nbsp;
                      <input
                        type="checkbox"
                        placeholder="enter is_iot"
                        className="wb-40 t-4  "
                        style={{
                          width: "25px",
                          height: "25px",
                          position: "relative",
                          top: "5px",
                          left: "2px",
                        }}
                        checked={is_universal}
                        onChange={() => setIsUniversal(!is_universal)}
                      />
                    </b>
                  </InputLabel>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Button
                    className="w-50 mt-4"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenForm()}
                  >
                    Cancel
                  </Button>
                </Col>
                {!EditCheckKey && (
                  <Col sm={12} md={4} lg={3}>
                    <Button
                      className="w-50 mt-4"
                      variant="outlined"
                      color="primary"
                      onClick={() => formHandle()}
                    >
                      Submit
                    </Button>
                  </Col>
                )}
                {EditCheckKey && (
                  <Col sm={12} md={4} lg={3}>
                    <Button
                      className="w-50 mt-4"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleUpdateDongal();
                      }}
                    >
                      Update
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          )}

          <React.Fragment>
            <div className="mt-5">
              <h5 className="text-center">
                <b>Existing Dongal</b>
              </h5>
            </div>

            <table className="table mt-4">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Dongle Id</th>
                  <th scope="col">Serial No</th>
                  <th scope="col">Sim Id (Edit)</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {simData?.length ? (
                  simData.map((val, index) => {
                    return (
                      <tr>
                        <th scope="row"> {val.dongle_id}</th>
                        <td>{val.serial_no}</td>
                        <td>
                          <ReactSelectFuntion
                            probs={{ id: val.sim_id__id, orginalId: val.id }}
                          />
                        </td>
                        <td>{val.status ? "True" : "False"}</td>
                        {/* ravi edit */}
                        <td>
                          <Button
                            className="float-left"
                            variant="contained"
                            color="primary"
                            title="Get Date Range"
                            style={{ maxHeight: "40px", borderRadius: "none" }}
                            onClick={() => {
                              Get_EditdataForDongal(val.id);
                              setOpenDonGal(true);
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th scope="row"></th>
                    <td> </td>
                    <td>
                      <b> There is no previous Dongle </b>
                    </td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </React.Fragment>
        </div>
      </>
    );
  };
  // main dsb
  const DsbMaiFuntion = (probs) => {
    const SiteList = probs?.props?.SiteList ? probs?.props?.SiteList : [];
    const dongleList = probs?.props?.DongleList ? probs?.props?.DongleList : [];
    const AssebledList = probs?.props?.AssebledList
      ? probs?.props?.AssebledList
      : [];
    const dsbList = probs?.props?.dsbList ? probs?.props?.dsbList : [];

    const [openCloseForm, setOpenCloseForm] = useState(false);
    const [dsbId, setDsbId] = useState(null);
    const [selectDongle, setSelectDongle] = useState(null);
    const [selectSite, setSelectSite] = useState(null);
    const [selectAssembledBy, setSelectAssembledBy] = useState(null);
    const [status, setStatus] = useState(false);
    const [remark, setRemark] = useState(null);
    const [EditCheckKey, setEditCheckKey] = useState(false);
    const [updateKeyStore, setUpdateKeyStore] = useState(null);

    const tempDongle = dongleList.map(({ id, dongle_id }) => ({
      value: id,
      label: dongle_id,
    }));
    const tempSite = SiteList.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const tempAssebled = AssebledList.map(({ id, full_name }) => ({
      value: id,
      label: full_name,
    }));

    const handleOpenForm = () => {
      setDsbId(null);
      setSelectDongle(null);
      setSelectSite(null);
      setSelectAssembledBy(null);
      setStatus(false);
      setRemark(null);
      setOpenCloseForm(!openCloseForm);
      setEditCheckKey(false);
    };
    // post api
    const onPostDSB_dongal = async (data) => {
      axios
        .post(GET_DSB_DONGLE_SIM_ZONE("dsb"), data)
        .then((res) => {
          if (res.data.status) {
            toast.success(res.data.message);
            probs?.props?.onGettingDSB_Sim_dongal();
          } else {
            if (res?.data?.message?.non_field_errors) {
              res?.data?.message?.non_field_errors.map((e) => {
                toast.error(e);
              });
            } else {
              let error = Object.entries(res?.data?.message);
              error?.map((e) => {
                toast.error(`${e[0]} ${e[1]}`);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const handleSubmitForm = () => {
      let data = {
        dsbId: dsbId,
        selectDongle: selectDongle?.value,
        selectSite: selectSite?.value,
        selectAssembledBy: selectAssembledBy?.value,
        status: status,
        remark: remark,
      };

      onPostDSB_dongal(data);
    };

    const ReactSiteFuntion = (probs) => {
      const [value, setValue] = useState(null);
      let name = probs?.props?.id;
      let orginalId = probs?.props?.orginalId;
      let data = tempSite.find((x) => x.label === name);
      return <>{data?.label ? data?.label : "-"}</>;
    };
    const ReactAssembledFuntion = (probs) => {
      const [value, setValue] = useState(null);
      let name = probs?.props?.id;
      let orginalId = probs?.props?.orginalId;
      let data = tempAssebled.find((x) => x.value === name);
      return <>{data?.label ? data?.label : "-"}</>;
    };
    // update api
    const UpdateDSB_dongal = () => {
      let data = {
        EditUniqueKey: updateKeyStore,
        dsbId: dsbId,
        selectDongle: selectDongle?.value,
        selectSite: selectSite?.value,
        selectAssembledBy: selectAssembledBy?.value,
        status: status,
        remark: remark,
      };
      axios
        .put(GET_EXISTING_DSB_DONGLE_SIM("dsb"), data)
        .then((res) => {
          if (res.data?.status) {
            toast.success(res?.data?.message);
            // setUpdateChect(false);
            probs?.props?.onGettingDSB_Sim_dongal();
          } else {
            if (res?.data?.message?.non_field_errors) {
              res?.data?.message?.non_field_errors.map((e) => {
                toast.error(e);
              });
            } else {
              let error = Object.entries(res?.data?.message);
              error?.map((e) => {
                toast.error(`${e[0]} ${e[1]}`);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const ReactDongleFuntion = (probs) => {
      const [value, setValue] = useState(null);
      let name = probs?.props?.id;
      let orginalId = probs?.props?.orginalId;

      let data = tempDongle.find((x) => x.value === name);
      return <>{data?.label ? data?.label : "-"}</>;
    };

    const EditDsbContent = (id) => {
      setEditCheckKey(true);
      let data = { id: id };

      axios
        .post(GET_EXISTING_DSB_DONGLE_SIM("dsb"), data)
        .then((res) => {
          setUpdateKeyStore(id);
          if (res.data?.status) {
            let data = res.data?.data[0];
            data.dsb_id ? setDsbId(data.dsb_id) : setDsbId("");
            if (data?.assembled_by) {
              let temp = tempAssebled.find(
                (x) => x.value === data?.assembled_by
              );
              setSelectAssembledBy(temp);
            } else {
              setSelectAssembledBy(null);
            }
            if (data?.dongle_id) {
              let temp = tempDongle.find((x) => x.value === data?.dongle_id);
              setSelectDongle(temp);
            } else {
              setSelectDongle(null);
            }
            if (data?.site_id) {
              let temp = tempSite.find((x) => x.value === data?.site_id);
              setSelectSite(temp);
            } else {
              setSelectSite(null);
            }
            data.remark ? setRemark(data.remark) : setRemark("");
            data?.status ? setStatus(true) : setStatus(false);

            // setSelectDongle(null);
            // setSelectSite(null);
            // setSelectAssembledBy(null);

            console.log(data);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return (
      <>
        <div className="w-100 h-10 p-1">
          {!openCloseForm && (
            <Row className=" d-flex flex-row-reverse">
              <Col sm={6} md={4} lg={2} className="my-2">
                <div className="d-flex w-100">
                  <div className="w-100">
                    <Button
                      className="float-left"
                      variant="contained"
                      color="primary"
                      title="Get Date Range"
                      style={{ maxHeight: "40px", borderRadius: "none" }}
                      onClick={() => handleOpenForm()}
                    >
                      Create DSB
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {openCloseForm && (
            <div>
              <Row>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Select Dongle </b>
                  </InputLabel>
                  <ReactSelect
                    data={dongleList.map(({ id, dongle_id }) => ({
                      value: id,
                      label: dongle_id,
                    }))}
                    value={selectDongle}
                    handleChange={setSelectDongle}
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Select Site</b>
                  </InputLabel>
                  <ReactSelect
                    data={SiteList.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={selectSite}
                    placeholder={"Select Site"}
                    isLoading={false}
                    handleChange={setSelectSite}
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Select Assembled By</b>
                  </InputLabel>
                  <ReactSelect
                    data={AssebledList.map(({ id, full_name }) => ({
                      value: id,
                      label: full_name,
                    }))}
                    value={selectAssembledBy}
                    placeholder={"Select Assembled"}
                    isLoading={false}
                    handleChange={setSelectAssembledBy}
                  />
                </Col>
                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      DSB id <span style={{ color: "red" }}>*</span>
                    </b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter dsb id."
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={dsbId}
                    onChange={(e) => setDsbId(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-2">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>Remark</b>
                  </InputLabel>
                  <input
                    type="text"
                    placeholder="enter remark"
                    className="w-100 form-control -webkit-outer-spin-button"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </Col>

                <Col sm={12} md={6} lg={3} className="my-4 d-flex">
                  <InputLabel id="demo-simple-select-outlined-label">
                    <b>
                      Status <span style={{ color: "red" }}>*</span> &nbsp;
                      <input
                        type="checkbox"
                        // placeholder="enter is_iot"
                        className="wb-40 t-4  "
                        style={{
                          width: "25px",
                          height: "25px",
                          position: "relative",
                          top: "5px",
                          left: "2px",
                        }}
                        checked={status}
                        onChange={(e) => {
                          setStatus(!status);
                        }}
                      />
                    </b>
                  </InputLabel>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Button
                    className="w-50 mt-4"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenForm()}
                  >
                    Cancel
                  </Button>
                </Col>
                {!EditCheckKey && (
                  <Col sm={12} md={4} lg={3}>
                    <Button
                      className="w-50 mt-4"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSubmitForm()}
                    >
                      Submit
                    </Button>
                  </Col>
                )}

                {EditCheckKey && (
                  <Col sm={12} md={4} lg={3}>
                    <Button
                      className="w-50 mt-4"
                      variant="outlined"
                      color="primary"
                      onClick={() => UpdateDSB_dongal()}
                    >
                      Update
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          )}
          <React.Fragment>
            <div className="mt-5">
              <h5 className="text-center">
                <b>Existing DSB</b>
              </h5>
            </div>

            <table className="table mt-4">
              <thead className="thead-light">
                <tr>
                  <th scope="col">DSB Id</th>
                  <th scope="col">Dongle</th>
                  <th scope="col">Site Name</th>
                  <th scope="col">Assembled By</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dsbList.length ? (
                  dsbList.map((val, index) => {
                    return (
                      <tr>
                        <th scope="row">{val.dsb_id}</th>
                        <td>
                          <ReactDongleFuntion
                            props={{ id: val.dongle_id, orginalId: val.id }}
                          />
                        </td>
                        <td>
                          <ReactSiteFuntion
                            props={{ id: val.site_id__name, orginalId: val.id }}
                          />
                        </td>
                        <td>
                          <ReactAssembledFuntion
                            props={{ id: val.assembled_by, orginalId: val.id }}
                          />
                        </td>
                        <td>
                          <Col sm={12} md={4} lg={3}>
                            <Button
                              className="w-50 mt-1"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                EditDsbContent(val.id);
                                setOpenCloseForm(true);
                              }}
                            >
                              Edit
                            </Button>
                          </Col>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th scope="row"></th>
                    <td> </td>
                    <td>
                      <b> There is no previous DSB </b>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </React.Fragment>
        </div>
      </>
    );
  };
  const backBtnHandler = () => {
    return props.history.replace("/channels");
  };
  return (
    <>
      <div className="main-wrapper overflow-hidden">
        <Header {...props} />

        <Container fluid>
          <div className="mb-3 d-flex">
            <div>
              <img
                src={backLogo}
                alt="back"
                className="backBtn"
                onClick={backBtnHandler}
              />
            </div>
            <div>
              <h4 className="ml-2">
                <b> DSB-Dongle-SIM Zone</b>
              </h4>
            </div>
          </div>

          <div>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Sim Create" {...a11yProps(0)} />
                  <Tab label=" Dongal Create" {...a11yProps(1)} />
                  <Tab label=" DSB Create" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <SimMainFunction
                    props={{
                      SimList: SimList,
                      onGettingDSB_Sim_dongal: onGettingDSB_Sim_dongal,
                      ChannelValObj: ChannelValObj,
                    }}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <DongalMainFuntion
                    props={{
                      SimList: SimList,
                      DongleList: DongleList,

                      onGettingDSB_Sim_dongal: onGettingDSB_Sim_dongal,
                    }}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <DsbMaiFuntion
                    props={{
                      DongleList: DongleList,
                      SiteList: SiteList,
                      AssebledList: AssebledList,
                      onGettingDSB_Sim_dongal: onGettingDSB_Sim_dongal,
                      dsbList: dsbList,
                    }}
                  />
                </TabPanel>
              </SwipeableViews>
            </div>
          </div>
        </Container>
        <div className="w-100 h-100 p-5">
          <Row></Row>
        </div>
        <Footer {...props} />
        <ToastContainer />
      </div>
    </>
  );
};

export default DsbDongleSimManagement;
