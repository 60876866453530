import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import GenerateOTP from "./components/GenerateOTP";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import Channel from "./pages/channel";
import Channels from "./pages/Channels";
import * as axios from "axios";
import CreateChannel from "./pages/CreateChannel";
import PublicChannels from "./pages/PublicChannels";
import PublicChannel from "./pages/PublicChannel";
import DownloadZone from "./pages/DownloadZone";
import Calibration from "./pages/Calibration";
import UploadZone from "./pages/UploadZone";
import Analytics from "./pages/Analytics";
import ImageGallery from "./pages/ImageGallery";
import SoilingZone from "./pages/SoilingZone";
import EkoLogger from "./pages/EkoLogger";
import DeploymentZone from "./pages/Deployment";
import DsbDongleSimManagement from "./pages/DsbDongleSim";
const Routes = () => {
  const history = useHistory();

  const redirect = (path) => {
    history.push(path);
  };
  useEffect(() => {
    // console.log(window.location.pathname);
    if (addHeaderInAxios()) {
      console.log("Auth");
    } else if (window.location.pathname.includes("/reset/")) {
      redirect(window.location.pathname);
    } else if (window.location.pathname.includes("/eko_logger_data/")) {
      redirect(window.location.pathname);
    } else {
      console.log("No-Auth");
      localStorage.clear();
      redirect("/");
    }
  }, []);

  const addHeaderInAxios = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    const AUTH_TOKEN = localStorage.getItem("auth_token");
    if (AUTH_TOKEN) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      return true;
    }
    return false;
  };

  return (
    <>
      <Switch>
        <Route exact path="/" component={GenerateOTP} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <Route exact path="/channel/:id/:view_type" component={Channel} />
        <Route exact path="/channel/:id" component={Channel} />
        <Route exact path="/create_channel" component={CreateChannel} />
        <Route exact path="/channels" component={Channels} />
        <Route exact path="/public_channel/:id" component={PublicChannel} />
        <Route exact path="/public_channels" component={PublicChannels} />
        <Route exact path="/soiling_zone" component={SoilingZone} />
        <Route exact path="/download_zone" component={DownloadZone} />
        <Route exact path="/upload_zone" component={UploadZone} />
        <Route exact path="/calibration" component={Calibration} />
        <Route exact path="/analytics" component={Analytics} />
        <Route exact path="/deployment-zone" component={DeploymentZone} />
        <Route exact path="/dongle-zone" component={DsbDongleSimManagement} />

        <Route
          exact
          path="/image_gallery/:analysis/:id"
          component={ImageGallery}
        />
        <Route exact path="/eko_logger_data/:token" component={EkoLogger} />
        <Route exact path="/eko_logger_data/" component={EkoLogger} />
      </Switch>
    </>
  );
};

export default Routes;
