import React from "react";
import { Range, getTrackBackground } from "react-range";

const NumberRangePicker = (props) => {
  const { value, step, min, max, onChange, isDisabled } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Range
        disabled={isDisabled}
        values={value}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "6px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: value,
                  colors: ["#3d5afe", "#ccc"],
                  min: min,
                  max: max,
                }),
                filter: isDisabled ? "invert(0.2)" : "",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "35px",
              width: "35px",
              borderRadius: "50%",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
              color: "#3d5afe",
              border: "2px solid #3d5afe",
            }}
          >
            <b>{value[index]}</b>
          </div>
        )}
      />
    </div>
  );
};

export default NumberRangePicker;
