import React, { useEffect, useState } from "react";
import Moment from "moment";

import { Col, Container, Row } from "react-bootstrap";

import { SelectVisualizationModal } from "../shared/modals";
import { EditNumericWidgetModal } from "./EditNumWidgetSettingModal";
import {
  Button,
  createMuiTheme,
  MuiThemeProvider,
  Tooltip,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import ProgressBar from "react-bootstrap/ProgressBar";
import currentIcon from "../assets/images/currentIcon.png";
import tempIcon from "../assets/images/tempIcon.png";
import powerIcon from "../assets/images/powerIcon.png";
import humidityIcon from "../assets/images/humidityIcon.png";
import voltageIcon from "../assets/images/voltageIcon.png";
import closeIcon from "../assets/images/close.png";
import editIcon from "../assets/images/edit.png";
import axios from "axios";

import {
  COMBI_GRAPH_WIDGET,
  COMBI_GRAPH_WIDGET_DELETE,
  COMBI_GRAPH_WIDGET_GET,
  GRAPH_WIDGET,
  GRAPH_WIDGET_DELETE,
  GRAPH_WIDGET_GET,
  NUMERIC_WIDGET,
  NUMERIC_WIDGET_DELETE,
  NUMERIC_WIDGET_GET,
} from "../shared/ApiURLs";
import _ from "lodash";
import { checkSignature } from "../utils/Helpers";
import GraphChartCard from "./GraphChartCard";
import { EditGraphWidgetModal } from "./EditGraphWidgetSettingModal";

import { ChannelLocationCard } from "./ChannelGeoLocationCard";
import CombiGraphChartCard from "./CombiGraphChartCard";
import { EditCombiGraphWidgetModal } from "./EditCombiGraphWidgetSettingModal";
import { Spinner } from "react-bootstrap";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "white",
        border: "0.5px solid black",
      },
    },
  },
});

const PrivateView = (props) => {
  const [openAddVisulationModel, setOpenAddVisulationModel] = useState(false);
  const [numericVisualizationArr, setnumericVisualizationArr] = useState([]);
  const [availableNumericFields, setavailableNumericFields] = useState([]);
  const [openEditNumericWidgetModal, setOpenEditNumericWidgetModal] =
    React.useState(false);

  const [openEditGraphWidgetModal, setOpenEditGraphWidgetModal] =
    React.useState(false);

  const [selectedNumWidgtForEdit, setSelectedNumWidgtForEdit] = React.useState(
    {}
  );
  const [selectedGraphWidgtForEdit, setSelectedGraphWidgtForEdit] =
    React.useState({});

  const [graphVisualizationArr, setgraphVisualizationArr] = useState([]);
  const [availableGraphFields, setavailableGraphFields] = useState([]);

  const [combiGraphVisualizationArr, setCombiGraphVisualizationArr] = useState(
    []
  );
  const [availableCombiGraphFields, setAvailableCombiGraphFields] = useState(
    []
  );

  const [selectedCombiGraphWidgtForEdit, setSelectedCombiGraphWidgtForEdit] =
    React.useState({});

  const [graphLoading, setGraphLoading] = useState(true);

  const [openEditCombiGraphWidgetModal, setOpenEditCombiGraphWidgetModal] =
    React.useState(false);

  useEffect(() => {
    // Anything in here is fired on component mount.
    console.log("Private View Loaded");
    getAllNumericWidgets();
    getAllGraphWidgets();
    getAllCombiGraphWidgets();
    const refreshInterval = setInterval(() => {
      getAllNumericWidgets();
      getAllGraphWidgets();
    }, props.apiRefreshRateMs);

    return () => {
      // Anything in here is fired on component unmount.
      clearInterval(refreshInterval);
    };
  }, []);

  const getAllNumericWidgets = () => {
    axios
      .get(NUMERIC_WIDGET_GET(props.channelData.id))
      .then((res) => {
        setnumericVisualizationArr(res.data.data);
        const fieldArr = calculateAvailableFields(
          res.data.numeric_widget_field_arr
        );
        setavailableNumericFields(fieldArr);
        props.handleLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
      });
  };

  const getAllGraphWidgets = () => {
    axios
      .get(GRAPH_WIDGET_GET(props.channelData.id))
      .then((res) => {
        setgraphVisualizationArr(res.data.data);
        const fieldArr = calculateAvailableFields(
          res.data.graph_widget_field_arr
        );
        setavailableGraphFields(fieldArr);
        setGraphLoading(false);
        props.handleLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        setGraphLoading(false);
        console.log("api error: ", error);
        props.handleLoading(false);
      });
  };

  const getAllCombiGraphWidgets = () => {
    axios
      .get(COMBI_GRAPH_WIDGET_GET(props.channelData.id))
      .then((res) => {
        setCombiGraphVisualizationArr(res.data.data);

        const fieldArr = calculateAvailableFields(
          res.data.combi_graph_widget_field_arr
        );
        setAvailableCombiGraphFields(fieldArr);
        props.handleLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
      });
  };

  const addNumericWidget = (data) => {
    props.handleLoading(true);
    data["channel_id"] = props.channelData.id;
    axios
      .post(NUMERIC_WIDGET, data)
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllNumericWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", "Failed to add widget!");
      });
  };

  const addGraphWidget = (data) => {
    props.handleLoading(true);
    data["channel_id"] = props.channelData.id;
    axios
      .post(GRAPH_WIDGET, data)
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllGraphWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", error?.response?.data?.message);
      });
  };

  const addCombiGraphWidget = (data) => {
    props.handleLoading(true);
    data["channel_id"] = props.channelData.id;
    axios
      .post(COMBI_GRAPH_WIDGET, data)
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllCombiGraphWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", error?.response?.data?.message);
      });
  };

  const removeNumericWidget = (widget) => {
    props.handleLoading(true);
    axios
      .delete(NUMERIC_WIDGET_DELETE(widget.channel, widget.id))
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllNumericWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", "Failed to add widget!");
      });
  };

  const removeGraphWidget = (widget) => {
    props.handleLoading(true);
    axios
      .delete(GRAPH_WIDGET_DELETE(widget.channel, widget.id))
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllGraphWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", "Failed to add widget!");
      });
  };

  const removeCombiGraphWidget = (widget) => {
    props.handleLoading(true);
    axios
      .delete(COMBI_GRAPH_WIDGET_DELETE(widget.channel, widget.id))
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllCombiGraphWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", "Failed to add widget!");
      });
  };

  const editNumericWidget = (data) => {
    props.handleLoading(true);
    axios
      .put(NUMERIC_WIDGET, data)
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllNumericWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", "Failed to update widget!");
      });
  };

  const editGraphWidget = (data) => {
    props.handleLoading(true);
    axios
      .put(GRAPH_WIDGET, data)
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllGraphWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", "Failed to update widget!");
      });
  };

  const editCombiGraphWidget = (data) => {
    props.handleLoading(true);
    axios
      .put(COMBI_GRAPH_WIDGET, data)
      .then((res) => {
        props.handleToast("success", res.data.message);
        getAllCombiGraphWidgets();
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.handleLoading(false);
        props.handleToast("error", "Failed to update widget!");
      });
  };

  const calculateAvailableFields = (widget_field) => {
    let filteredArr;
    if (widget_field.length) {
      filteredArr = _.difference(props.active_feed_fields, widget_field);
      return filteredArr;
    }
    filteredArr = props.active_feed_fields;
    return filteredArr;
  };

  const opentNumWigEditModel = (widget) => {
    setSelectedNumWidgtForEdit(widget);
    setOpenEditNumericWidgetModal(true);
  };

  const closetNumWigEditModel = () => {
    setSelectedNumWidgtForEdit({});
    setOpenEditNumericWidgetModal(false);
  };

  const opentGraphWigEditModel = (widget) => {
    setSelectedGraphWidgtForEdit(widget);
    setOpenEditGraphWidgetModal(true);
  };

  const closeGraphWigEditModel = () => {
    setSelectedGraphWidgtForEdit({});
    setOpenEditGraphWidgetModal(false);
  };

  const opentCombiGraphWigEditModel = (widget) => {
    setSelectedCombiGraphWidgtForEdit(widget);
    setOpenEditCombiGraphWidgetModal(true);
  };

  const closeCombiGraphWigEditModel = () => {
    setSelectedCombiGraphWidgtForEdit({});
    setOpenEditCombiGraphWidgetModal(false);
  };

  const renderGraphWidgets = () => {
    if (graphVisualizationArr.length) {
      return graphVisualizationArr.map((el) => {
        return (
          <Col sm={12} md={6} key={el?.id} className="mb-5">
            <GraphChartCard
              data={el.feed_data}
              yAxisTitle={el.y_label}
              xAxisTitle={el.x_label}
              name={el.title}
              chartTitle={el.title}
              chartType={"line"}
              y_min={el.y_min}
              y_max={el.y_max}
              widget={el}
              onDelete={removeGraphWidget}
              onEdit={opentGraphWigEditModel}
            />
          </Col>
        );
      });
    }
  };

  const renderCombiGraphWidgets = () => {
    if (graphVisualizationArr.length) {
      return combiGraphVisualizationArr.map((el) => {
        return (
          <Col sm={12} md={6} key={el?.id} className="mb-5">
            <CombiGraphChartCard
              key={el?.id}
              feed_data={el?.feed_data}
              wms_data={el?.wms_data}
              x_label={el?.x_label}
              y1_label={el?.y1_label}
              y2_label={el?.y2_label}
              title={el?.title}
              y1_min={el?.y1_min}
              y1_max={el?.y1_max}
              y2_min={el?.y2_min}
              y2_max={el?.y2_max}
              widget={el}
              onDelete={removeCombiGraphWidget}
              onEdit={opentCombiGraphWigEditModel}
            />
          </Col>
        );
      });
    }
  };

  const { feedData: feed, channelData } = props;

  return (
    <React.Fragment>
      <Container fluid id="widgetContainer">
        <div className="mb-5">
          <h3 className="mb-3">Channel Stats</h3>
          <p className="mb-1">
            <b>Created: </b>
            {Moment(channelData?.created_at).format("MMM Do YYYY")}
          </p>
          <p className="mb-1">
            <b>Last Feed Entry: </b>
            {Moment(feed.created_at).format("MMM Do YYYY, h:mm:ss A")}
          </p>
        </div>

        <div className="mb-5 w-50">
          <Button
            style={{
              width: "47%",
              marginRight: "10px",
            }}
            variant="outlined"
            color="primary"
            onClick={() => setOpenAddVisulationModel(true)}
          >
            <AddIcon className="px-1" />
            Add Visualization{" "}
          </Button>
          <SelectVisualizationModal
            handleToast={props.handleToast}
            show={openAddVisulationModel}
            availableNumericFields={availableNumericFields}
            availableGraphFields={availableGraphFields}
            availableCombiGraphFields={availableCombiGraphFields}
            onHide={() => setOpenAddVisulationModel(false)}
            addNumericWidget={addNumericWidget}
            addGraphWidget={addGraphWidget}
            addCombiGraphWidget={addCombiGraphWidget}
          />
        </div>
        {numericVisualizationArr.length > 0 && (
          <div className="d-flex py-2">
            <MuiThemeProvider theme={theme}>
              {numericVisualizationArr.map((el, index) => {
                return (
                  <Tooltip
                    title={`${el.field} : ${feed[el.field]?.toFixed(2)} ${
                      el.unit
                    } `}
                    arrow
                  >
                    <div className="numericVisualBox mr-2 w-100 ">
                      <div className="p-2 b_radius d-flex justify-content-between">
                        <h6 className="mb-0 pl-2 pt-2 wrapText w-75">
                          {el.name}({el.unit})
                        </h6>
                        <span className="float-right">
                          <img
                            className="mr-2 c-pointer"
                            src={editIcon}
                            alt="edit"
                            onClick={() => opentNumWigEditModel(el)}
                          />
                          <img
                            className="c-pointer"
                            src={closeIcon}
                            alt="closeIcon"
                            onClick={() => removeNumericWidget(el)}
                          />
                        </span>
                      </div>

                      <div className="d-flex justify-content-between pt-3 p-2">
                        <b>{el.minValue?.toFixed(2)}</b>{" "}
                        <MuiThemeProvider theme={theme}>
                          <ProgressBar
                            style={{ width: "70%", marginTop: "3px" }}
                            now={feed[el.field]?.toFixed(2)}
                            min={el.minValue?.toFixed(2)}
                            max={el.maxValue?.toFixed(2)}
                            label={`${feed[el.field]?.toFixed(2)}`}
                          />
                        </MuiThemeProvider>
                        <b>{el.maxValue?.toFixed(2)}</b>
                      </div>
                      <div className="d-flex justify-content-between p-2">
                        <span className="float-left">
                          {el.field === "current" && (
                            <img src={currentIcon} alt="humidityIcon" />
                          )}
                          {el.field === "voltage" && (
                            <img src={voltageIcon} alt="humidityIcon" />
                          )}
                          {el.field === "rh" && (
                            <img src={humidityIcon} alt="humidityIcon" />
                          )}
                          {el.field === "power" && (
                            <img src={powerIcon} alt="humidityIcon" />
                          )}
                          {el.field === "temperature" && (
                            <img src={tempIcon} alt="humidityIcon" />
                          )}
                        </span>
                        <span className="float-right">
                          <b>
                            {feed[el.field]?.toFixed(2)}
                            {/* <small>
                            <b>{el.unit}</b>
                          </small> */}
                          </b>
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </MuiThemeProvider>
          </div>
        )}

        <Row className="mt-5">
          {graphLoading ? (
            <div
              style={{
                position: "absolute",
                left: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center ",
                flexDirection: "column",
                marginBottom: 10,
              }}
              // className="d-flex position-absolute top-0 start-50 translate-middle flex-column justify-content-center align-item-center"
            >
              <Spinner animation="border" role="status" />
              <h6>Widgets are loading....</h6>
            </div>
          ) : (
            renderGraphWidgets()
          )}
          {renderCombiGraphWidgets()}
          {channelData.is_channel_location &&
            channelData.latitude &&
            channelData.longitude && (
              <ChannelLocationCard channel={channelData} />
            )}
        </Row>
      </Container>
      <EditNumericWidgetModal
        selectedWidget={selectedNumWidgtForEdit}
        isNumEditModalOpen={openEditNumericWidgetModal}
        onClose={closetNumWigEditModel}
        onSave={editNumericWidget}
        handleToast={props.handleToast}
      />

      <EditGraphWidgetModal
        selectedWidget={selectedGraphWidgtForEdit}
        isGraphEditModalOpen={openEditGraphWidgetModal}
        onClose={closeGraphWigEditModel}
        onSave={editGraphWidget}
        handleToast={props.handleToast}
      />

      <EditCombiGraphWidgetModal
        selectedWidget={selectedCombiGraphWidgtForEdit}
        isGraphEditModalOpen={openEditCombiGraphWidgetModal}
        onClose={closeCombiGraphWigEditModel}
        onSave={editCombiGraphWidget}
        handleToast={props.handleToast}
      />
    </React.Fragment>
  );
};

export default PrivateView;
