import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "@material-ui/core";
import { checkSignature } from "../utils/Helpers";
import { CHANGE_CHANNEL_SHARING } from "../shared/ApiURLs";
import axios from "axios";

const SharingView = (props) => {
  const [value, setValue] = React.useState(props.channelData.is_private);
  const [valueChanged, setValueChanged] = React.useState(false);

  useEffect(() => {
    setValue(props.channelData.is_private);
  }, [props]);

  const handleChange = (event) => {
    setValueChanged(true);
    let values = event.target.value === "true" ? true : false;
    setValue(values);
  };

  const handleSaveSetting = () => {
    axios
      .post(CHANGE_CHANNEL_SHARING, {
        channel_id: props.channelData.id,
        isPrivate: value,
      })
      .then((res) => {
        props.refreshChannel(props.channelData.id);
        props.handleToast("success", res.data.message);
        props.handleLoading(false);

        // props.history.replace(`channel/${props.channelData.id}`);
      })
      .catch((error) => {
        checkSignature(error);
        console.log("api error: ", error);
        props.history.replace("/channels");
      });
  };

  return (
    <Container>
      <h2 className="my-3">Channel Sharing Setting</h2>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Shareing"
          name="Shareing"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Keep channel to yourself."
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Make channel public."
          />
        </RadioGroup>
        <Button
          color="primary"
          className="px-4 py-2"
          variant="contained"
          component="span"
          onClick={handleSaveSetting}
          disabled={!valueChanged}
        >
          Save
        </Button>
      </FormControl>
    </Container>
  );
};

export default SharingView;
