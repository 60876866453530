import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../styles/generateOTP.module.css";
import { SEND_OTP } from "../shared/ApiURLs";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop, TextField, Button } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo.png";
import loginImage from "../assets/images/login.png";
import Toast from "../shared/Toast";
import { fetchAuthToken } from "../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const inputStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));

const GenerateOTP = (props) => {
  const classes = useStyles();
  const inputClasses = inputStyles();

  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(true);
  const [message, setMessage] = useState("");
  const [toastColor, setToastColor] = useState("");

  useEffect(() => {
    console.log("On load block of landing page");
    let token = fetchAuthToken();
    if (token) {
      console.log("navigate to dashboard directly");
      props.history.push("/channels");
    } else {
      console.log("navigate to dashboard directly");
    }
  }, []);

  const hassError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const validate = () => {
    var temp_errors = [...errors];

    const emailRegex = /\S+@\S+/;
    var validEmail = emailRegex.test(String(email).toLowerCase());

    if (email === "" && temp_errors.length < 4) {
      console.log("email is blank");
      temp_errors.push("email_blank");
      setEmailHelper("Email is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "email_blank");
      setEmailHelper("");
    }

    if (!validEmail && temp_errors.length < 4) {
      temp_errors.push("email_invalid");
      setEmailHelper("Please enter a valid email");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "email_invalid");
      setEmailHelper("");
    }

    if (password === "" && temp_errors.length < 4) {
      temp_errors.push("password_blank");
      setPasswordHelper("Password is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "password_blank");
      setPasswordHelper("");
    }

    if (password.length < 8 && temp_errors.length < 4) {
      temp_errors.push("password_minLength");
      setPasswordHelper("Atleasr 8 characters are required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "password_minLength");
      setPasswordHelper("");
    }
    setErrors(temp_errors);

    if (errors.length > 0) {
      return false;
    } else {
      console.log("all inputs are valid");
    }
  };

  const handleEmailChange = (event) => {
    let email = event.target.value;
    if (email === "") {
      // setEmailHelper('Email is required');
    } else {
      setEmail(email);
    }
  };

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    if (password === "") {
      setPasswordHelper("Password is required");
    } else if (password !== "" && email !== "") {
      setPassword(password);
      setLogin(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validate();
    const requestData = {
      email: email,
      password: password,
      otp_type: "verify_email",
    };

    setLoading(true);
    axios
      .post(SEND_OTP, requestData)
      .then((res) => {
        setLoading(false);
        console.log("response.data.message ", res.data.message);
        setToastColor("success");
        setMessage(res.data.message);
        handleToast("success", res.data.message);
        localStorage.setItem("user_email", res.data.email);
        setTimeout(() => {
          props.history.push({
            pathname: "/login",
            state: { password: password },
          });
        }, 900);
      })
      .catch((error) => {
        setToastColor("error");
        setMessage("Incorrect email address or password");
        setLoading(false);
        handleToast("error", "Incorrect email address or password");
      });
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage("");
    setToastColor("");
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  return (
    <>
      <Toast
        message={message}
        color={toastColor}
        handleClose={handleSnackClose}
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="container px-0" style={{ marginTop: "50px" }}>
        <div className="row">
          <div className="col-12 mb-5">
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <h1>
              <b>LOGIN</b>
            </h1>
            <form className={inputClasses.root} onSubmit={handleSubmit}>
              <div className="form-group mb-5 mt-5">
                <TextField
                  error={hassError("email_blank") || hassError("email_invalid")}
                  onChange={(e) => handleEmailChange(e)}
                  id="outlined-error-helper-text"
                  label="Email"
                  defaultValue=""
                  helperText={emailHelper}
                  variant="outlined"
                />
              </div>
              <div className="form-group mb-5">
                <TextField
                  error={
                    hassError("password_blank") ||
                    hassError("password_minLength")
                  }
                  onChange={(e) => handlePasswordChange(e)}
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  helperText={passwordHelper}
                  inputProps={{ minLength: 8 }}
                  variant="outlined"
                />

                <Link
                  to={"/forgot_password"}
                  className=""
                  style={{ float: "right" }}
                >
                  Forgot Password
                </Link>
              </div>

              <Button
                disabled={login}
                type="submit"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </form>
          </div>
          <div
            className="col-lg-8 col-md-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={loginImage} alt="" />
          </div>
        </div>
      </div>
      <ToastContainer />

      {/* <Footer/> */}
    </>
  );
};

export default GenerateOTP;
