import React from "react";
import LineChart from "../shared/lineChart";

import closeIcon from "../assets/images/close.png";
import editIcon from "../assets/images/edit.png";

const GraphChartCard = (props) => {
  const {
    data,
    yAxisTitle,
    name,
    chartType,
    y_min,
    y_max,
    xAxisTitle,
    widget,
  } = props;

  return (
    <div className="graphWidgetCard card">
      <div className="card-header mb-3">
        <div className="b_radius d-flex justify-content-between">
          <h6 className="mb-0 pl-2 pt-2 wrapText w-75">{name}</h6>
          <span className="float-right">
            <img
              className="mr-2 c-pointer"
              style={{ filter: "brightness(2)" }}
              src={editIcon}
              alt="edit"
              onClick={() => props.onEdit(widget)}
            />
            <img
              className="c-pointer"
              src={closeIcon}
              alt="closeIcon"
              onClick={() => props.onDelete(widget)}
            />
          </span>
        </div>
      </div>
      <div className="p-0">
        <LineChart
          data={data}
          yAxisTitle={yAxisTitle}
          xAxisTitle={xAxisTitle}
          name={name}
          chartType={chartType}
          y_min={y_min}
          y_max={y_max}
        />
      </div>
    </div>
  );
};

export default GraphChartCard;
