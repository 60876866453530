import { Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";

export default function DateRangePickerModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="DatePicker"
      style={{ zIndex: 1500 }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Pick Date Range
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DateRange
          minDate={props.minDate}
          maxDate={props.maxDate}
          editableDateInputs={false}
          moveRangeOnFirstSelection={false}
          ranges={props.date_range}
          onChange={(item) => props.handleDateRangeChange(item)}
        />
      </Modal.Body>
    </Modal>
  );
}
