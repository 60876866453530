import * as axios from "axios";

export const fetchAuthToken = () => {
  try {
    const token = localStorage.getItem("auth_token");
    return token;
  } catch (e) {
    console.log("local storage error: ", e);
    return undefined;
  }
};

export const fetchLoggedInUserEmail = () => {
  try {
    const userEmail = localStorage.getItem("user_email");
    return userEmail;
  } catch (e) {
    console.log("local storage error: ", e);
    return undefined;
  }
};

export const channelViews = [
  "private",
  "public",
  "settings",
  "sharing",
  "api_keys",
  "import_export",
];

export const addHeaderInAxios = (props) => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  const AUTH_TOKEN = localStorage.getItem("auth_token");
  if (AUTH_TOKEN) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    return true;
  } else {
    localStorage.clear();
    props.history.replace("/");
    return false;
  }
};

export const checkSignature = (error) => {
  if (
    error.response &&
    error.response.status &&
    error.response.status === 401 &&
    error.response.data.detail === "Signature has expired."
  ) {
    localStorage.clear();
    return window.location.reload("/");
  }
  return;
};

export const timeScale = [
  { value: "0", field: "Select Timescale" },
  { value: "1T", field: "1 Min" },
  { value: "5T", field: "5 Min" },
  { value: "15T", field: "15 Min" },
  { value: "H", field: "60 Min" },
  { value: "D", field: "24 Hour" },
];

export const aggregateFunc = ["Average", "Max", "Min"];

export const combiTimeScale = [
  { value: "0", field: "Select Timescale", is_disabled: false },
  { value: "1T", field: "1 Min", is_disabled: true },
  { value: "5T", field: "5 Min", is_disabled: true },
  { value: "15T", field: "15 Min", is_disabled: true },
  { value: "H", field: "60 Min", is_disabled: true },
  { value: "D", field: "24 Hour", is_disabled: true },
];

export const combiWMSFeeds = [
  { value: "", field: "Select WMS Field" },
  { value: "poa_rad", field: "POA RAD" },
  { value: "tmod", field: "TMOD" },
  { value: "tamb", field: "TAMB" },
  { value: "ws", field: "WS" },
  { value: "wd", field: "WD" },
  { value: "pressure", field: "Pressure" },
  { value: "rh", field: "RH" },
  { value: "ghi_rad", field: "GHI RAD" },
];

export function parseDate(date) {
  return new Date(date);
}

export function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24)) + 1;
}

export const moduleLibraryFields = {
  Isc: 0,
  make: "",
  model: "",
  Pmax: 0,
  technology: "NA",
  Vm: 0,
  Voc: 0,
  Im: 0,
  FF: 0,
  YOM: "NA",
  alpha: 0,
  beta_t: 0,
  gamma_t: 0,
  kappa_t: 0,
};
