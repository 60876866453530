import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  AppBar,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import { fetchAuthToken } from "../utils/Helpers";
import userImg from "../assets/images/user.png";
import publicIco from "../assets/images/feedback.png";
import logoutIco from "../assets/images/logout.png";
import individualIco from "../assets/images/individual.png";
import downloadZoneIco from "../assets/images/download.png";
import uploadZone from "../assets/images/uploadZone.png";
import analytics from "../assets/images/analytics.png";
import deployment from "../assets/images/deployment.png";
import calibration from "../assets/images/calibration.png";
import soilingIco from "../assets/images/soiling.png";
import NotificationBell from "../assets/images/NotificationBell.png";
import dsb_simZone from "../assets/images/dsb_simZone.png";
import arrowDown from "../assets/images/arrowDown.png";
import configuration from "../assets/images/configuration.png";
import uparrow from "../assets/images/uparrow.png";
import Notification from "./Notification";
import { addHeaderInAxios } from "../utils/Helpers";
import { NOTIFICATION_ALL, NOTIFICATION_DELETE } from "../shared/ApiURLs";
import axios from "axios";

import pvdIco from "../assets/images/pvd.png";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Header = (props) => {
  // const navClasses = navStyles();
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [notificationArray, setNotificationArray] = React.useState([]);
  const [showNotification, setShowNotification] = React.useState(false);

  const [showConfiguration, setShowConfiguration] = React.useState(false);

  const showCalibration = localStorage.getItem("organization");
  //console.log("orgo", showCalibration);

  useEffect(() => {
    addHeaderInAxios(props)
      ? getNotification(props?.match?.params?.id)
      : this.props.history.replace("/");
    getNotification();
    // console.log("On load block of header");
    let token = fetchAuthToken();
    if (token) {
      setIsLoggedIn(true);
      setUsername(localStorage.getItem("user_name"));
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const getNotification = (channel_id) => {
    const token = localStorage.getItem("auth_token");
    axios
      .get(NOTIFICATION_ALL, {
        channel_id: channel_id,
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((res) => {
        setNotificationArray(res.data.data);
        //console.log("res", res);
        // setNotificationArray([
        //   {
        //     id: 46,
        //     channel_id: 13,
        //     channel_name: "MMB_V2_05",
        //     created_at: "2022-07-18T11:00:03.734575",
        //     title: "Open Circuit",
        //     description: {
        //       feed_ids: [
        //         47514, 47504, 3813, 5634, 3812, 5633, 3811, 5632, 5631, 3810,
        //       ],
        //       rules: "if i>200, v<-10",
        //     },
        //   },
        // ]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    props.history.push("/");
    localStorage.clear();
  };

  const handleNotification = () => {
    setShowNotification(!showNotification);
  };

  const onNotificationDelete = (e, id = null) => {
    let ids = [];
    if (e === "single") {
      ids.push(id);
    } else {
      ids = notificationArray.map((e) => e.id);
    }

    axios
      .post(NOTIFICATION_DELETE, { ids })
      .then((res) => {
        getNotification();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h5" noWrap>
            {!isLoggedIn && (
              <Link className="home-link" to={"/"}>
                <img
                  src={pvdIco}
                  alt="individualIco"
                  style={{ width: "40px" }}
                />
                FLARE
              </Link>
            )}
            {isLoggedIn && (
              <Link className="home-link" to="/channels">
                <img
                  src={pvdIco}
                  alt="individualIco"
                  style={{ width: "40px" }}
                />
                FLARE
              </Link>
            )}
          </Typography>

          {isLoggedIn && (
            <React.Fragment>
              <div
                className="ml-auto d-flex"
                style={{
                  width: "20%",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <div
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      marginTop: "50%",
                    }}
                    onClick={handleNotification}
                  >
                    <span
                      className="badge badge-pill badge-warning text-center"
                      style={{
                        position: "absolute",
                        right: "-30%",
                        top: "-35%",
                      }}
                    >
                      <b>{notificationArray.length}</b>
                    </span>
                    <img
                      src={NotificationBell}
                      alt="Notification"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "-10px",
                      }}
                    />
                  </div>
                  {showNotification ? (
                    <Notification
                      {...props}
                      data={notificationArray}
                      onNotificationDelete={(e, id) =>
                        onNotificationDelete(e, id)
                      }
                    />
                  ) : null}
                </div>
                <Button color="inherit">
                  <span>
                    <img src={userImg} className="pr-2 pb-2" alt="user" />
                  </span>
                  <span>
                    <h6> {username} </h6>
                  </span>{" "}
                </Button>
              </div>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          <ListItem button component={Link} to="/public_channels">
            <ListItemIcon>
              <img src={publicIco} alt="public" />
            </ListItemIcon>
            <ListItemText primary="Public Channels" />
          </ListItem>
          <ListItem button component={Link} to="/soiling_zone">
            <ListItemIcon>
              <img src={soilingIco} alt="soiling" />
            </ListItemIcon>
            <ListItemText primary="Underperformance Zone" />
          </ListItem>
          <ListItem button component={Link} to="/channels">
            <ListItemIcon>
              <img src={individualIco} alt="individualIco" />
            </ListItemIcon>
            <ListItemText primary="My Channels" />
          </ListItem>

          <ListItem button component={Link} to="/download_zone">
            <ListItemIcon>
              <img src={downloadZoneIco} alt="downloadZoneIco" />
            </ListItemIcon>
            <ListItemText primary="Download Zone" />
          </ListItem>

          <ListItem button component={Link} to="/upload_zone">
            <ListItemIcon>
              <img src={uploadZone} alt="uploadZone" />
            </ListItemIcon>
            <ListItemText primary="Upload Zone" />
          </ListItem>

          <ListItem button component={Link} to="/analytics">
            <ListItemIcon>
              <img src={analytics} alt="analytics" />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>

          {showCalibration == "PVD" && (
            <ListItem
              button
              onClick={() => {
                // todo
                setShowConfiguration(!showConfiguration);
                console.log("configuration btn heat");
              }}
            >
              <ListItemIcon>
                <img src={configuration} alt="deployment" />
              </ListItemIcon>
              {/* <ListItemText primary="Configuration" /> */}
              <ListItemText>
                Configuration &nbsp;
                {showConfiguration ? (
                  <img src={uparrow} alt="deployment" />
                ) : (
                  <img src={arrowDown} alt="deployment" />
                )}
              </ListItemText>
            </ListItem>
          )}

          {showCalibration == "PVD" && showConfiguration && (
            <div
              style={{
                position: "relative",
                left: "6%",
                width: "90%",
              }}
            >
              <ListItem button component={Link} to="/dongle-zone">
                <ListItemIcon>
                  <img src={dsb_simZone} alt="analytics" />
                </ListItemIcon>
                <ListItemText primary="DSB-Dongle-SIM zone" />
              </ListItem>

              <ListItem button component={Link} to="/deployment-zone">
                <ListItemIcon>
                  <img src={deployment} alt="deployment" />
                </ListItemIcon>
                <ListItemText primary="Deployment zone" />
              </ListItem>

              <ListItem button component={Link} to="/calibration">
                <ListItemIcon>
                  <img src={calibration} alt="calibration" />
                </ListItemIcon>
                <ListItemText primary="Calibration Zone" />
              </ListItem>
            </div>
          )}

          {/* {showCalibration == "PVD" && (
            <ListItem button component={Link} to="/dongle-zone">
              <ListItemIcon>
                <img src={dsb_simZone} alt="analytics" />
              </ListItemIcon>
              <ListItemText primary="DSB-Dongle-SIM zone" />
            </ListItem>
          )}

          {showCalibration == "PVD" ? (
            <ListItem button component={Link} to="/deployment-zone">
              <ListItemIcon>
                <img src={deployment} alt="deployment" />
              </ListItemIcon>
              <ListItemText primary="Deployment zone" />
            </ListItem>
          ) : (
            ""
          )}

          {showCalibration == "PVD" ? (
            <ListItem button component={Link} to="/calibration">
              <ListItemIcon>
                <img src={calibration} alt="calibration" />
              </ListItemIcon>
              <ListItemText primary="Calibration Zone" />
            </ListItem>
          ) : (
            ""
          )} */}

          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <img src={logoutIco} alt="logout" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
  );
};

export default Header;
