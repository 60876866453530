import React from "react";
// import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);

const LineChart = (props) => {
  const { data, yAxisTitle, xAxisTitle, name, y_min, y_max } = props;

  const options = {
    time: {
      useUTC: false,
      timezone: "Asia/Calcutta",
    },
    chart: {
      zoomType: "xy",
      resetZoomButton: {
        position: {
          align: "left", // by default
          verticalAlign: "top", // by default
          x: 10,
          y: 0,
        },
        theme: {
          zIndex: 6,
        },
        relativeTo: "chart",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: xAxisTitle,
        style: {
          fontSize: "14px",
          fontFamily: "Verdana, sans-serif",
          color: "#3f51b5",
        },
      },
      labels: {
        format: "{value:%Y-%b-%e}",
      },
      ordinal: false,
      dateTimeLabelFormats: {
        second: "%H:%M:%S",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%b. %e",
        week: "%b. %e",
        month: "%b. %y",
        year: "%Y",
      },
      // min: x_min, // Date.UTC(2010, 8, 1),
      // max: x_max,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: {
          fontSize: "14px",
          fontFamily: "Verdana, sans-serif",
          color: "#3f51b5",
        },
      },

      max: y_max,
      min: y_min,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          "</b><br/>" +
          "<b>Value: " +
          parseFloat(this.y)?.toFixed(2) +
          "</b><br/>" +
          "<b>At: " +
          moment(new Date(this.x)).format("LL hh:mm A") +
          "</b>"
        );
      },
    },
    exporting: {
      filename: name,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "downloadPNG",
            "downloadPDF",
            "downloadXLS",
            "downloadCSV",
          ],
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    navigator: {
      enabled: false,
    },

    scrollbar: {
      enabled: false,
    },

    rangeSelector: {
      enabled: true,
      allButtonsEnabled: true,
      inputEnabled: true,
      buttons: [
        //   {
        //   type: 'month',
        //   count: 1,
        //   text: '1m'
        // }, {
        //   type: 'month',
        //   count: 3,
        //   text: '3m'
        // }, {
        //   type: 'all',
        //   text: 'All'
        // }
      ],
      selected: 2,
    },

    series: [
      {
        type: "line",
        lineWidth: 1,
        // color:'#3f51b5',
        // data : [{
        //   color:'red',
        // }],
        marker: {
          enabled: true,
          radius: 2,
        },
        tooltip: {
          valueDecimals: 2,
        },
        states: {
          hover: {
            lineWidthPlus: 1,
          },
        },
        name: name,
        data: data,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LineChart;
