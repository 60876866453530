import React, { useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl, Form } from "react-bootstrap";
import axios from "axios";
import {
  UPDATE_CHANNEL_SETTING,
  DELETE_CHANNEL,
  CLEAR_CHANNEL_FEED
} from "../shared/ApiURLs";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { serverURL } from "../shared/ApiURLs";
import { ReactSelect } from "../components/ReactSelect";
import { moduleLibraryFields } from "../utils/Helpers";
const SettingsView = (props) => {
  const [current, setCurrent] = useState(false);
  const [voltage, setVoltage] = useState(false);
  const [power, setPower] = useState(false);
  const [temperature, setTemperature] = useState(false);
  const [rh, setRh] = useState(false);

  const [channel_name, setChannel_name] = useState("");
  const [channel_description, setChannel_description] = useState("");

  const [channel_metadata, setChannel_metadata] = useState("");

  const [channel_status, setChannel_status] = useState(true);
  const [is_channel_location_enabled, setIs_channel_location_enabled] =
    useState(false);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [alertModalContent, setalertModalContent] = useState({
    heading: "",
    text: "",
    onAgree: null
  });

  const [siteName, setSiteName] = useState(null);
  const [siteNameList, setSiteNameList] = useState([]);
  const [moduleDetailsData, setModuleDetailsData] = useState([]);
  const [moduleDetails, setModuleDetails] = useState(moduleLibraryFields);
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    setChannelSetting(props.channelData);
  }, [props.channelData]);

  const handleFieldDisable = (e) => {
    console.log(e.target.name, e.target.checked);
    if (e.target.name === "current") {
      setCurrent(e.target.checked);
    } else if (e.target.name === "voltage") {
      setVoltage(e.target.checked);
    } else if (e.target.name === "power") {
      setPower(e.target.checked);
    } else if (e.target.name === "temperature") {
      setTemperature(e.target.checked);
    } else if (e.target.name === "rh") {
      setRh(e.target.checked);
    } else if (e.target.name === "is_channel_location_enabled") {
      setLongitude(is_channel_location_enabled ? "" : longitude);
      setLatitude(is_channel_location_enabled ? "" : latitude);
      setIs_channel_location_enabled(e.target.checked);
    }
  };

  const validateForm = () => {
    if (
      channel_name === "" ||
      channel_description === "" ||
      channel_metadata === "" ||
      !moduleDetails.model ||
      !siteName.value
    ) {
      props.handleToast("error", "Please fill all the mendatory fields!");
      return false;
    } else {
      return true;
    }
  };

  const handleSaveChannelSetting = () => {
    props.handleLoading(true);
    if (!validateForm()) return;
    let temp = moduleDetails;
    temp.model = temp.model.value;
    temp.make = temp.make.value;

    const channel = {
      channel_id: props.channelData.id,
      write_api_key: props.channelData.write_api_key,
      name: channel_name,
      description: channel_description,
      current: current,
      voltage: voltage,
      power: power,
      temperature: temperature,
      rh: rh,
      cleaning_block: channel_metadata,
      status: channel_status,
      is_channel_location: is_channel_location_enabled,
      is_private: true,
      site_id: siteName.value,
      moduleDetails: temp
    };

    axios
      .post(UPDATE_CHANNEL_SETTING, channel)
      .then((response) => {
        props.refreshChannel(channel.channel_id);
        props.handleLoading(false);
        props.handleToast("success", response.data.message);
      })
      .catch((e) => {
        props.handleLoading(false);
        props.handleToast("error", "Please fill valid values!");
      });
  };

  const setChannelSetting = (channel) => {
    setChannel_name(channel.name);
    setChannel_description(channel.description);
    setCurrent(channel.current);
    setVoltage(channel.voltage);
    setPower(channel.power);
    setTemperature(channel.temperature);
    setRh(channel.rh);
    setChannel_metadata(channel.cleaning_block);
    setSiteName({ value: channel?.site?.id, label: channel?.site_name });
    setChannel_status(channel.status);
    setIs_channel_location_enabled(channel.is_channel_location);
    selectSiteList();
    selectModuleList();
  };

  const handleDeleteChannel = () => {
    closetAlertModel();
    props.handleLoading(true);
    const data = { channel_id: props.channelData.id };
    axios
      .post(DELETE_CHANNEL, data)
      .then((response) => {
        props.handleLoading(false);
        props.handleToast("success", response.data.message);

        setTimeout(() => {
          props.history.replace(`/channels`);
        }, 1000);
      })
      .catch((e) => {
        props.handleLoading(false);
        props.handleToast("error", "Failed to Delete Channel!");
      });
  };

  const handleClearChannelFeeds = () => {
    closetAlertModel();
    props.handleLoading(true);
    const data = { channel_id: props.channelData.id };
    axios
      .post(CLEAR_CHANNEL_FEED, data)
      .then((response) => {
        props.handleLoading(false);
        props.handleToast("success", response.data.message);

        setTimeout(() => {
          props.history.replace(`/channels`);
        }, 1000);
      })
      .catch((e) => {
        props.handleLoading(false);
        props.handleToast("error", "Failed to Delete Channel!");
      });
  };

  const opentAlertModel = (data) => {
    setisModalOpen(true);
    setalertModalContent(data);
  };

  const closetAlertModel = () => {
    setisModalOpen(false);
    setalertModalContent({
      heading: "",
      text: "",
      onAgree: null
    });
  };

  const selectSiteList = () => {
    axios
      .get(`${serverURL}/channels/get_all_site`, {
        Authorization: `Bearer ${token}`
      })
      .then((res) => {
        //console.log("resModel", res.data);
        setSiteNameList(res.data.sites);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const selectModuleList = () => {
    axios
      .get(`${serverURL}/channels/get-all-module-details`, {
        Authorization: `Bearer ${token}`
      })
      .then((res) => {
        setModuleDetailsData(res.data.dropdown_data);
        let obj = { make: {}, model: {} };
        res.data.dropdown_data?.makes?.forEach((make) => {
          if (make.value === props.channelData.module.make) {
            obj.make = make;
            make.models.forEach((model) => {
              if (model.value === props.channelData.module.model) {
                obj.model = model;
              }
            });
          }
        });
        setModuleDetails({ ...moduleDetails, ...obj });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleModuleDetailsChanges = (key, value) => {
    let tempModuleDetails = null;
    if (key === "model") {
      tempModuleDetails = value.details;
      tempModuleDetails.model = value;
      tempModuleDetails.make = moduleDetails.make;
      setModuleDetails({ ...tempModuleDetails });
      return true;
    } else if (key === "make") {
      tempModuleDetails = moduleLibraryFields;
    } else {
      tempModuleDetails = moduleDetails;
    }
    tempModuleDetails[key] = value;
    setModuleDetails({ ...tempModuleDetails });
    return true;
  };

  return (
    <React.Fragment>
      <div className="main-wrapper">
        <Row>
          <Col sm={12} md={8}>
            <div>
              <h2 className="mb-3">Channel Setting</h2>
              <p>
                {" "}
                <b>Channel ID: </b> {props.channelData.id}{" "}
              </p>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSaveChannelSetting();
                }}
              >
                <Row>
                  <Col md={6}>
                    <InputGroup className="mb-3 w-85">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Name
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label="channel_name"
                        name="channel_name"
                        aria-describedby="inputGroup-sizing-default"
                        value={channel_name}
                        onChange={(e) => setChannel_name(e.target.value)}
                      />
                    </InputGroup>
                  </Col>

                  <Col md={6}>
                    <InputGroup className="mb-3 w-85">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Cleaning Block</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="channel_metadata"
                        aria-label="With textarea"
                        value={channel_metadata}
                        onChange={(e) => setChannel_metadata(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <InputGroup className="mb-3 w-100">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Address</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="channel_description"
                        as="textarea"
                        aria-label="With textarea"
                        value={channel_description}
                        onChange={(e) => setChannel_description(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id="inputGroup-sizing-default"
                          style={{ width: 135 }}
                        >
                          <span>Current</span>
                          <span className="mx-auto">
                            <Form.Check
                              key="current"
                              aria-label="Current"
                              name="current"
                              checked={current}
                              onChange={(e) => handleFieldDisable(e)}
                            />
                          </span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>

                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id="inputGroup-sizing-default"
                          style={{ width: 135 }}
                        >
                          <span>Voltage</span>
                          <span className="mx-auto">
                            <Form.Check
                              key="voltage"
                              aria-label="Voltage"
                              name="voltage"
                              checked={voltage}
                              onChange={(e) => handleFieldDisable(e)}
                            />
                          </span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>

                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id="inputGroup-sizing-default"
                          style={{ width: 135 }}
                        >
                          <span>Power</span>
                          <span className="mx-auto">
                            <Form.Check
                              key="power"
                              aria-label="Power"
                              name="power"
                              checked={power}
                              onChange={(e) => handleFieldDisable(e)}
                            />
                          </span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>

                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id="inputGroup-sizing-default"
                          style={{ width: 135 }}
                        >
                          <span>Temperature</span>
                          <span className="mx-auto">
                            <Form.Check
                              key="temperature"
                              aria-label="Temperature"
                              name="temperature"
                              checked={temperature}
                              onChange={(e) => handleFieldDisable(e)}
                            />
                          </span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>

                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id="inputGroup-sizing-default"
                          style={{ width: 135 }}
                        >
                          <span>RH</span>
                          <span className="mx-auto">
                            <Form.Check
                              key="rh"
                              aria-label="RH"
                              name="rh"
                              checked={rh}
                              onChange={(e) => handleFieldDisable(e)}
                            />
                          </span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="w-100 mb-3">
                      {" "}
                      <ReactSelect
                        data={moduleDetailsData?.makes}
                        value={moduleDetails?.make}
                        placeholder={"Select Make..."}
                        isDisabled={false}
                        isLoading={false}
                        handleChange={(e) =>
                          handleModuleDetailsChanges("make", e)
                        }
                        className="w-50"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="w-100 mb-3">
                      <ReactSelect
                        style={{ width: "50%" }}
                        data={moduleDetails?.make?.models}
                        value={moduleDetails?.model}
                        placeholder={"Select Model..."}
                        isDisabled={!moduleDetails?.make}
                        isLoading={!moduleDetails?.make}
                        handleChange={(e) =>
                          handleModuleDetailsChanges("model", e)
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="w-100 mb-3">
                      <ReactSelect
                        data={siteNameList.map(({ id, name }) => ({
                          value: id,
                          label: name
                        }))}
                        value={siteName}
                        placeholder={"Select Site"}
                        isLoading={false}
                        handleChange={setSiteName}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <InputGroup className="mt-2 w-75">
                      <span className="mr-2">Status </span>
                      <span>
                        {" "}
                        <Form.Check
                          name="channel_status"
                          aria-label="status"
                          value={channel_status}
                          onChange={(e) => {
                            handleFieldDisable(e);
                          }}
                          checked={channel_status}
                        />
                      </span>
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <div>
                      <button
                        type="submit"
                        className="py-2 px-4 btn btn-success w-100"
                      >
                        Save Channel
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <div className="mb-2">
              <h6>Want to clear all feed data from this Channel?</h6>
              <button
                onClick={() =>
                  opentAlertModel({
                    heading:
                      "Are you sure you want to clear all feeds of this channel?",
                    text: "You will loose all feeds of this Channel.",
                    onAgree: handleClearChannelFeeds
                  })
                }
                className="btn btn-danger px-4 py-2"
              >
                Clear Channel
              </button>
            </div>
            <div className="mb-5">
              <h6>Want to delete this Channel?</h6>
              <button
                onClick={() =>
                  opentAlertModel({
                    heading: "Are you sure you want to delete this channel?",
                    text: "You will loose all channel data.",
                    onAgree: handleDeleteChannel
                  })
                }
                className="btn btn-danger px-4 py-2"
              >
                Delete Channel
              </button>
            </div>
            <div></div>
            <div>
              <h2 className="mb-3">Help</h2>
              <p>
                Channels store all the data that a Flare application collects.
                Each channel includes eight fields that can hold any type of
                data, plus three fields for location data and one for status
                data. Once you collect data in a channel, you can use Flare apps
                to analyze and visualize it.
              </p>

              <h4>Channel Settings</h4>
              <ul className="helpUL">
                {/* <li>
                  <b>Percentage complete:</b>
                  Calculated based on data entered into the various fields of a
                  channel. Enter the name, description, location, URL, video,
                  and tags to complete your channel.
                </li> */}
                <li>
                  <b>Channel Name: </b>
                  Enter a unique name for the Flare channel.
                </li>
                <li>
                  <b>Address: </b>
                  Enter a address of the Flare channel.
                </li>
                <li>
                  <b>Current, Voltage, Power, Temperature and RH: </b>
                  Enable them for receiving feed data.
                </li>
                <li>
                  <b>Cleaning Block: </b>
                  Enter the cleaning block.
                </li>
                <li>
                  <b>Site : </b>
                  Select the site under which channel comes.
                </li>
                {/* <li>
                  <b>Tags:</b>
                  Enter keywords that identify the channel. Separate tags with
                  commas.
                </li>
                <li>
                  <b>Show Channel Location:</b>
                  <ul>
                    <li>
                      <b>Latitude:</b>
                      Specify the latitude position in decimal degrees. For
                      example, the latitude of the city of London is 51.5072.
                    </li>
                    <li>
                      <b>Longitude:</b>
                      Specify the longitude position in decimal degrees. For
                      example, the longitude of the city of London is -0.1275.
                    </li>
                    <li>
                      <b>Elevation:</b>
                      Specify the elevation position meters. For example, the
                      elevation of the city of London is 35.052.
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      <Dialog
        open={isModalOpen}
        onClose={closetAlertModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertModalContent.heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertModalContent.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closetAlertModel} color="primary">
            Disagree
          </Button>
          <Button onClick={alertModalContent.onAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SettingsView;
