import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Button,
  Container,
  Tooltip,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { FormControl, InputGroup, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Moment from "moment";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { GET_PUBLIC_CHANNELS } from "../shared/ApiURLs";
import { addHeaderInAxios, checkSignature } from "../utils/Helpers";

import currentIcon from "../assets/images/currentIcon.png";
import tempIcon from "../assets/images/tempIcon.png";
import powerIcon from "../assets/images/powerIcon.png";
import humidityIcon from "../assets/images/humidityIcon.png";
import voltageIcon from "../assets/images/voltageIcon.png";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import MyLoader from "../components/ContentLoader";
import ReactPaginate from "react-paginate";
import refreshIco from "../assets/images/refresh.png";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "white",
        border: "0.5px solid black",
      },
    },
  },
});
class PublicChannels extends Component {
  state = {
    searchBarValue: "",
    channels: [],
    filteredChannels: [],
    isContentLoading: true,
    num_of_pages: 1,
    limit: 0,
    current_page: 0,
    loading: false,
  };

  get_channels = () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    axios
      .get(
        GET_PUBLIC_CHANNELS(
          this.state.current_page + 1,
          this.state.searchBarValue
        )
      )
      .then((res) => {
        this.setState(
          {
            channels: res.data.data,
            filteredChannels: res.data.data,
            isContentLoading: false,
            loading: false,

            num_of_pages: res.data.num_of_pages,
            limit: res.data.limit,
          },
          () => console.log(this.state.num_of_pages)
        );
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({
          isContentLoading: false,
          loading: false,
        });
      });
  };

  componentDidMount = () => {
    addHeaderInAxios(this.props)
      ? this.get_channels()
      : this.props.history.replace("/");
  };

  handleSearch = () => {
    this.get_channels();
  };

  setFilteredChannelToChannel = () =>
    this.setState({ filteredChannels: this.state.channels });

  loadChannels = () => {
    if (this.state.channels.length) {
      if (this.state.filteredChannels.length) {
        return this.state.filteredChannels.map((el) => {
          let { channel, feed } = el;
          return (
            <Col sm={12} md={4} className="mb-3" key={channel.id}>
              <div
                className={
                  channel.status
                    ? "borderSuccess publicChannelCard"
                    : "borderDanger publicChannelCard"
                }
              >
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Link to={`public_channel/${channel.id}`}>
                        {channel.name}
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                    >
                      <Typography component="p">
                        <small>
                          <b>Channel Created At : </b>
                          {Moment(channel.created_at).format("MMM Do YYYY")}
                        </small>
                      </Typography>

                      <Typography component="p">
                        <small>
                          <b>Feed Updated At : </b>
                          {Moment(feed.created_at).format(
                            "MMM Do YYYY, h:mm:ss A"
                          )}
                        </small>
                      </Typography>
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <div className="card-body p-2">
                  <div className="d-flex py-2">
                    <Tooltip
                      title={`Current : ${feed?.current?.toFixed(2)}`}
                      arrow
                    >
                      <Card className="p-2 w-100 mx-1 text-center ">
                        <span className="px-2 pb-2">
                          <img src={currentIcon} alt="Current" />
                        </span>
                        <span className="px-2">
                          <b>{feed?.current?.toFixed(2)}</b>
                        </span>
                      </Card>
                    </Tooltip>
                    <MuiThemeProvider theme={theme}>
                      <Tooltip
                        title={`Voltage : ${feed?.voltage?.toFixed(2)}`}
                        arrow
                      >
                        <Card className="p-2 w-100 mx-1 text-center">
                          <span className="px-2 pb-2">
                            <img src={voltageIcon} alt="voltage" />
                          </span>
                          <span className="px-2">
                            <b>{feed?.voltage?.toFixed(2)}</b>
                          </span>
                        </Card>
                      </Tooltip>
                    </MuiThemeProvider>
                    <Tooltip title={`Power : ${feed?.power?.toFixed(2)}`} arrow>
                      <Card className="p-2 w-100 mx-1 text-center">
                        <span className="px-2 pb-2">
                          <img src={powerIcon} alt="powerIcon" />
                        </span>
                        <span className="px-2">
                          <b>{feed?.power?.toFixed(2)}</b>
                        </span>
                      </Card>
                    </Tooltip>
                    <Tooltip
                      title={`Temperature : ${feed?.temperature?.toFixed(2)}`}
                      arrow
                    >
                      <Card className="p-2 w-100 mx-1 text-center">
                        <span className="px-2 pb-2">
                          <img src={tempIcon} alt="tempIcon" />
                        </span>
                        <span className="px-2">
                          <b>{feed?.temperature?.toFixed(2)}</b>
                        </span>
                      </Card>
                    </Tooltip>
                    <Tooltip title={`Humidity : ${feed?.rh?.toFixed(2)}`} arrow>
                      <Card className="p-2 w-100 mx-1 text-center">
                        <span className="px-2 pb-2">
                          <img src={humidityIcon} alt="humidityIcon" />
                        </span>
                        <span className="px-2">
                          <b>{feed?.rh?.toFixed(2)}</b>
                        </span>
                      </Card>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Col>
          );
        });
      } else {
        return (
          <Col md={12}>
            <h3 className="text-center">
              No Channels Found with name {this.state.searchBarValue}!
            </h3>
            <div className="text-center mt-2">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.setFilteredChannelToChannel}
              >
                Get All Channels
              </Button>
            </div>
          </Col>
        );
      }
    } else {
      if (this.state.searchBarValue) {
        return (
          <Col md={12}>
            <h3 className="text-center">
              No Public Channels Found with your search keywords!{" "}
            </h3>
            <div className="text-center mt-2">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.setState(
                    {
                      ...this.state,
                      searchBarValue: "",
                    },
                    () => this.get_channels()
                  );
                }}
              >
                Clear Search
              </Button>
            </div>
          </Col>
        );
      } else {
        return (
          <Col md={12}>
            <h3 className="text-center">No Public Channels Found! </h3>
            <div className="text-center mt-2">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.props.history.replace("/channels")}
              >
                My-Channels
              </Button>
            </div>
          </Col>
        );
      }
    }
  };

  handlePageClick = (event) => {
    console.log(event);
    this.setState(
      {
        ...this.state,
        current_page: event.selected,
      },
      () => {
        this.get_channels();
      }
    );
  };

  render() {
    return (
      <div className="main-wrapper">
        <Header {...this.props} />
        {this.state.isContentLoading && <MyLoader />}
        {!this.state.isContentLoading && (
          <Container>
            <div className="mb-3">
              <h2>Public Channels</h2>
            </div>
            <Row className="mb-5">
              <Col sm={12} md={6}>
                <div className="mb-3">
                  <InputGroup className="search-bar">
                    <FormControl
                      placeholder="Search by channel name..."
                      aria-label="Search by channel name..."
                      aria-describedby="basic-addon2"
                      value={this.state.searchBarValue}
                      onChange={(e) => {
                        this.setState({ searchBarValue: e.target.value });
                      }}
                    />
                    <small
                      onClick={() => {
                        this.setState(
                          {
                            ...this.state,
                            searchBarValue: "",
                          },
                          () => this.get_channels()
                        );
                      }}
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        padding: "5px",
                        right: "16%",
                        top: "17%",
                      }}
                    >
                      <img
                        className="mr-1"
                        style={{
                          cursor: "pointer",
                          width: "11px",
                          filter: "brightness(0)",
                        }}
                        src={refreshIco}
                        alt="refresh"
                      />
                      <b>Clear</b>
                    </small>
                    <Button
                      style={{ borderRadius: 0 }}
                      variant="contained"
                      color="primary"
                      id="button-addon2"
                      onClick={this.handleSearch}
                    >
                      Search
                    </Button>
                  </InputGroup>
                </div>
              </Col>
            </Row>
            <Backdrop
              style={{
                zIndex: theme.zIndex.drawer + 1,
                color: "#fff",
              }}
              open={this.state.loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Row className="mb-5">{this.loadChannels()}</Row>

            {this.state.num_of_pages > 1 && (
              <div className="d-flex justify-content-end">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={this.state.limit}
                  pageCount={this.state.num_of_pages}
                  previousLabel="< previous"
                  renderOnZeroPageCount={false}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </Container>
        )}
        <Footer />
      </div>
    );
  }
}

export default PublicChannels;
