import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import {
  aggregateFunc,
  combiTimeScale,
  datediff,
  parseDate,
  timeScale,
} from "../utils/Helpers";
import moment from "moment";
import DateRangePickerModal from "./DateRangePicker";

export const EditCombiGraphWidgetModal = (props) => {
  const { selectedWidget: widget } = props;

  const [title, setTitle] = React.useState("");
  const [x_label, set_x_label] = React.useState("");
  const [y1_label, set_y1_label] = React.useState("");
  const [y2_label, set_y2_label] = React.useState("");

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const defaultDateRange = [
    {
      startDate: yesterday,
      endDate: today,
      key: "selection",
    },
  ];
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [date_range, set_date_range] = useState(defaultDateRange);

  const [timescale, setTimescale] = React.useState(combiTimeScale[0].value);
  const [combiTimeScaleArr, setCombiTimeScaleArr] = useState(combiTimeScale);

  const [graph_function, setGraph_function] = React.useState("Select Function");

  const [y1_min, set_y1_min] = React.useState(0);
  const [y1_max, set_y1_max] = React.useState(0);
  const [y2_min, set_y2_min] = React.useState(0);
  const [y2_max, set_y2_max] = React.useState(0);

  useEffect(() => {
    setTitle(props.selectedWidget.title);
    set_x_label(props.selectedWidget.x_label);
    set_y1_label(props.selectedWidget.y1_label);
    set_y2_label(props.selectedWidget.y2_label);
    set_date_range([
      {
        startDate: new Date(props.selectedWidget.startDate),
        endDate: new Date(props.selectedWidget.endDate),
        key: "selection",
      },
    ]);
    let startDate = new Date(props.selectedWidget?.startDate);
    let endDate = new Date(props.selectedWidget?.endDate);
    let day = datediff(parseDate(startDate), parseDate(endDate));
    disableTimeScaleOnDates(day);

    setTimescale(props.selectedWidget.timeScale);
    setGraph_function(props.selectedWidget.function);

    set_y1_min(props.selectedWidget.y1_min);
    set_y1_max(props.selectedWidget.y1_max);
    set_y2_min(props.selectedWidget.y2_min);
    set_y2_max(props.selectedWidget.y2_max);
  }, [props?.selectedWidget]);

  const handleSave = () => {
    let startDate = new Date(date_range[0].startDate);
    let endDate = new Date(date_range[0].endDate);

    const data = {
      channel_id: props?.selectedWidget.channel,
      widget_id: props?.selectedWidget.id,
      title: title,
      x_label: x_label,
      y1_label: y1_label,
      y2_label: y2_label,
      timeScale: timescale,
      function: graph_function,
      y1_min: parseFloat(y1_min),
      y1_max: parseFloat(y1_max),
      y2_min: parseFloat(y2_min),
      y2_max: parseFloat(y2_max),
      startDate: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
      },
      endDate: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
      },
    };
    console.log(data);
    if (!validateForm(data))
      return props.handleToast("error", "Please fill all the fields!");
    props.onClose();
    props.onSave(data);
  };

  const validateForm = (data) => {
    if (
      data.title === "" ||
      data.x_label === "" ||
      data.y1_label === "" ||
      data.y2_label === "" ||
      data.timeScale === "" ||
      data.timeScale === "0" ||
      data.timeScale === undefined ||
      data.function === "" ||
      data.function === undefined ||
      data.function === "Select Function" ||
      data.y1_min === undefined ||
      data.y1_min === null ||
      data.y1_max === undefined ||
      data.y1_max === null ||
      data.y2_min === undefined ||
      data.y2_min === null ||
      data.y2_max === undefined ||
      data.y2_max === null ||
      date_range === null ||
      date_range === undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  const timeScaleSelect = () => {
    return (
      <FormControl variant="outlined" className="w-50 m-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Timescales
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Timescale"
          value={timescale}
          onChange={(e) => {
            setTimescale(e.target.value);
            // e.target.value === 0
            //   ? setGraph_function("Select Function")
            //   : console.log("check work");
          }}
        >
          {combiTimeScaleArr.map((el, index) => (
            <MenuItem key={el.value} value={el.value} disabled={el.is_disabled}>
              {el.field}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const disableTimeScaleOnDates = (day) => {
    console.log("day", day);
    let newTimeScale = combiTimeScale;
    if (day >= 1 && day <= 2) {
      console.log("at 1T");
      newTimeScale[1]["is_disabled"] = false;
      newTimeScale[2]["is_disabled"] = false;
      newTimeScale[3]["is_disabled"] = false;
      newTimeScale[4]["is_disabled"] = false;
      newTimeScale[5]["is_disabled"] = false;
    }
    if (day >= 3 && day <= 7) {
      newTimeScale[1]["is_disabled"] = true;
      newTimeScale[2]["is_disabled"] = false;
      newTimeScale[3]["is_disabled"] = false;
      newTimeScale[4]["is_disabled"] = false;
      newTimeScale[5]["is_disabled"] = false;
    }
    if (day >= 8 && day <= 31) {
      newTimeScale[1]["is_disabled"] = true;
      newTimeScale[2]["is_disabled"] = true;
      newTimeScale[3]["is_disabled"] = false;
      newTimeScale[4]["is_disabled"] = false;
      newTimeScale[5]["is_disabled"] = false;
    }
    if (day > 31) {
      newTimeScale[1]["is_disabled"] = true;
      newTimeScale[2]["is_disabled"] = true;
      newTimeScale[3]["is_disabled"] = true;
      newTimeScale[4]["is_disabled"] = true;
      newTimeScale[5]["is_disabled"] = false;
    }
    console.log("new timescale", newTimeScale);
    setCombiTimeScaleArr(newTimeScale);
    return true;
  };

  const aggreFunctionSelect = () => {
    return (
      <FormControl variant="outlined" className="w-50 m-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Functions
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Function"
          value={graph_function}
          onChange={(e) => setGraph_function(e.target.value)}
          // disabled={timescale === "0"}
        >
          <MenuItem key="Select Function" value="Select Function">
            Select Function
          </MenuItem>
          {aggregateFunc.map((el, index) => (
            <MenuItem key={index} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleDateRangeSelect = (item) => {
    set_date_range([item?.selection]);
    let startDate = new Date(item?.selection?.startDate);
    let endDate = new Date(item?.selection?.endDate);
    let day = datediff(parseDate(startDate), parseDate(endDate));
    disableTimeScaleOnDates(day);
    setTimescale(combiTimeScale[0].value);
  };

  const renderModal = () => {
    if (Object.keys(widget).length) {
      return (
        <React.Fragment>
          <Dialog
            open={props.isGraphEditModalOpen}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Edit {String(widget.title)}
            </DialogTitle>
            <DialogContent>
              <div>
                <form noValidate autoComplete="off">
                  <div className="d-flex">
                    <TextField
                      label="Title"
                      variant="outlined"
                      className="w-50 m-2"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />

                    <TextField
                      label="X-Label"
                      variant="outlined"
                      className="w-50 m-2"
                      value={x_label}
                      onChange={(e) => set_x_label(e.target.value)}
                    />
                  </div>

                  <div className="d-flex">
                    <TextField
                      label="Y1-Label"
                      variant="outlined"
                      className="w-50 m-2"
                      value={y1_label}
                      onChange={(e) => set_y1_label(e.target.value)}
                    />

                    <TextField
                      label="Y2-Label"
                      variant="outlined"
                      className="w-50 m-2"
                      value={y2_label}
                      onChange={(e) => set_y2_label(e.target.value)}
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <TextField
                      label="Y1-Min"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="m-2"
                      value={y1_min}
                      onChange={(e) => set_y1_min(e.target.value)}
                    />
                    <TextField
                      label="Y1-Max"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="m-2"
                      value={y1_max}
                      onChange={(e) => set_y1_max(e.target.value)}
                    />

                    <TextField
                      label="Y2-Min"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="m-2"
                      value={y2_min}
                      onChange={(e) => set_y2_min(e.target.value)}
                    />
                    <TextField
                      label="Y2-Max"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="m-2"
                      value={y2_max}
                      onChange={(e) => set_y2_max(e.target.value)}
                    />
                  </div>

                  <div className="d-flex">
                    <span className="d-flex w-50">
                      <TextField
                        label="Date Range"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        className="m-2 w-100"
                        readOnly
                        onClick={() => setShowDatePicker(true)}
                        value={`${moment(date_range[0]?.startDate).format(
                          "MMM D, YYYY"
                        )} - ${moment(date_range[0]?.endDate).format(
                          "MMM D, YYYY"
                        )}`}
                      />
                    </span>
                    <span className="d-flex w-50">
                      {timeScaleSelect()}
                      {aggreFunctionSelect()}
                    </span>
                  </div>
                </form>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" autoFocus>
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <DateRangePickerModal
            show={showDatePicker}
            onHide={() => setShowDatePicker(false)}
            date_range={date_range}
            handleDateRangeChange={handleDateRangeSelect}
          />
        </React.Fragment>
      );
    }
    return false;
  };
  return renderModal();
};
