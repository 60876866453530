import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import MyLoader from "../components/ContentLoader";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import { ToastContainer } from "react-toastify";
import Moment from "moment";

import { addHeaderInAxios, checkSignature } from "../utils/Helpers";
import backLogo from "../assets/images/back.png";
import {
  CHANNEL_FEED_EXPORT,
  GET_PUBLIC_CHANNEL_BY_ID,
} from "../shared/ApiURLs";
import axios from "axios";
import PublicViewWidgets from "../components/publicViewWidgets";

const PublicChannel = (props) => {
  const [channel, setChannel] = useState({});
  const [feed, setFeed] = useState({});
  const [isContentLoading, setContentLoading] = useState(true);

  useEffect(() => {
    // Anything in here is fired on component mount.
    const getChannel = (channel_id) => {
      axios
        .post(GET_PUBLIC_CHANNEL_BY_ID, { channel_id: channel_id })
        .then((res) => {
          setChannel(res.data.channel);
          setFeed(res.data.feed);
          setContentLoading(false);
        })
        .catch((error) => {
          checkSignature(error);
          console.log("api error: ", error);
          setContentLoading(false);
          return props.history.replace("/public_channels");
        });
    };

    addHeaderInAxios(props)
      ? getChannel(props?.match?.params?.id)
      : this.props.history.replace("/");

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [props]);

  const backBtnHandler = () => {
    return props.history.replace("/public_channels");
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && Object.keys(channel).length && (
        <Container fluid={true}>
          <div className="mb-3">
            <img
              src={backLogo}
              alt="back"
              className="backBtn"
              onClick={backBtnHandler}
            />
          </div>

          <div className="mb-3">
            <h1 className="mb-3">{channel.name}</h1>
            <div>
              <h6 className="mb-1">
                {" "}
                <b>Channel ID : </b> {channel?.id}{" "}
              </h6>
              <h6 className="mb-1">
                {" "}
                <b>Access : </b> {channel?.is_private ? "Private" : "Public"}{" "}
              </h6>
              <h6 className="mb-1">
                {" "}
                <b>Description : </b> {channel.description}
              </h6>
              <h6 className="mb-1">
                {" "}
                <b>Site : </b> {channel.site_name}
              </h6>
              <h6 className="mb-1">
                {" "}
                <b>Last Feed Entry: </b>{" "}
                {Moment(feed.created_at).format("MMM Do YYYY, h:mm:ss A")}
              </h6>
            </div>
          </div>
          <div className="mb-5">
            <p className="mb-3">
              <b>Download all of this Channel's feeds in CSV format.</b>
            </p>
            <div className="mx-auto">
              <a
                className="px-4 py-2 btn btn-success"
                href={CHANNEL_FEED_EXPORT(channel.id)}
                download
                target="_blank"
                rel="noreferrer"
              >
                DOWNLOAD
              </a>
            </div>
          </div>
          <PublicViewWidgets channel={channel} feed={feed} />
        </Container>
      )}
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default PublicChannel;
