import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { LOGIN } from "../shared/ApiURLs";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop, TextField, Button } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo.png";
import loginImage from "../assets/images/login.png";
import Toast from "../shared/Toast";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const inputStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const Login = (props) => {
  const emailFromLocalStorage = localStorage.getItem("user_email");
  const classes = useStyles();
  const inputClasses = inputStyles();

  const [email, setEmail] = useState(emailFromLocalStorage);
  const [otp, setOTP] = useState("");
  const [otpHelper, setOtpHelper] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [message, setMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const validate = () => {
    var temp_errors = [...errors];
    if (otp === "") {
      temp_errors.push("otp_blank");
      setOtpHelper("OPT is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "otp_blank");
      setOtpHelper("");
    }

    if (otp.length < 4) {
      temp_errors.push("otp_minLength");
      setOtpHelper("Atleast 4 characters are required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "otp_minLength");
      setOtpHelper("");
    }
    setErrors(temp_errors);
    if (errors.length > 0) {
      console.log("execution stopped");
      return false;
    } else {
      console.log("all inputs are valid");
    }
  };

  const handleOTPChange = (event) => {
    let otp = event.target.value;
    if (otp === "") {
    } else {
      setOTP(otp);
      setDisableBtn(false);
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    validate();
    const requestData = {
      email: email,
      password: props.location.state.password,
      otp: otp,
      otp_type: "verify_email",
    };

    setLoading(true);
    axios
      .post(LOGIN, requestData)
      .then((res) => {
        setLoading(false);
        // setToastColor("success");
        // setMessage(res.data.message);
        handleToast("success", res.data.message);

        localStorage.setItem("auth_token", res.data.token);
        localStorage.setItem("user_name", res.data.user_name);
        localStorage.setItem("user_email", res.data.email);
        localStorage.setItem("organization", res.data.organization);

        setTimeout(() => {
          props.history.push("/channels");
        }, 900);
      })
      .catch((error) => {
        console.log("api error: ", error);
        // setToastColor("error");
        // setMessage("Incorrect OTP");
        setLoading(false);
        handleToast("error", "Incorrect OTP");
      });
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage("");
    setToastColor("");
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  return (
    <>
      <Toast
        message={message}
        color={toastColor}
        handleClose={handleSnackClose}
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <div className="container" style={{ marginTop: "50px" }}>
        <div className="row">
          <div className="col-12 mb-5">
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <h3 className="">VERIFY OTP</h3>
            <form className={inputClasses.root} onSubmit={handleLogin}>
              <div className="form-group mt-5 mb-5">
                <TextField
                  id="outlined-read-only-input"
                  label="Email"
                  defaultValue={email}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>

              <div className="form-group mt-5">
                <TextField
                  error={hasError("otp_blank") || hasError("otp_minLegth")}
                  onChange={(e) => handleOTPChange(e)}
                  id="outlined-password-input"
                  label="OTP"
                  type="password"
                  helperText={otpHelper}
                  inputProps={{ maxLength: 4 }}
                  variant="outlined"
                />
                <Link
                  to={"/forgot_password"}
                  className=""
                  style={{ float: "right" }}
                >
                  Forgot Password
                </Link>
              </div>
              <Button
                disabled={disableBtn}
                className="mt-3"
                type="submit"
                variant="contained"
                color="primary"
              >
                Verify OTP
              </Button>
            </form>
          </div>
          <div
            className="col-lg-8 col-md-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={loginImage} alt="" />
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};
export default Login;
