import React from "react";
// import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);

const CombiLineChart = (props) => {
  const {
    feed_data,
    wms_data,
    x_label,
    y1_label,
    y2_label,
    title,
    y1_min,
    y1_max,
    y2_min,
    y2_max,
    widget,
  } = props;

  const options = {
    time: {
      useUTC: false,
      timezone: "Asia/Calcutta",
    },
    chart: {
      zoomType: "xy",
      resetZoomButton: {
        position: {
          align: "left", // by default
          verticalAlign: "top", // by default
          x: 10,
          y: 0,
        },
        theme: {
          zIndex: 6,
        },
        relativeTo: "chart",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: x_label,
        style: {
          fontSize: "14px",
          fontFamily: "Verdana, sans-serif",
          color: "#3f51b5",
        },
      },
      labels: {
        format: "{value:%Y-%b-%e}",
      },
      ordinal: false,
      dateTimeLabelFormats: {
        second: "%H:%M:%S",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%b. %e",
        week: "%b. %e",
        month: "%b. %y",
        year: "%Y",
      },
      crosshair: true,
    },
    yAxis: [
      {
        title: {
          text: y1_label,
          style: {
            fontSize: "14px",
            fontFamily: "Verdana, sans-serif",
            color: "#3f51b5",
          },
        },

        max: y1_max,
        min: y1_min,
      },
      {
        title: {
          text: y2_label,
          style: {
            fontSize: "14px",
            fontFamily: "Verdana, sans-serif",
            color: "#3f51b5",
          },
        },
        opposite: true,
        max: y2_max,
        min: y2_min,
      },
    ],
    legend: {
      enabled: true,
    },
    tooltip: {
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return (
            "<b>" +
            s +
            "</b>" +
            "<br/>" +
            "<b>" +
            point.series.name +
            "</b>" +
            ": " +
            "<b>" +
            parseFloat(point.y)?.toFixed(2) +
            "</b>"
          );
        }, "<b>" + moment(new Date(this.x)).format("LL hh:mm A") + "</b>");
      },
      shared: true,
      split: false,
      enabled: true,
    },
    exporting: {
      filename: title,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "downloadPNG",
            "downloadPDF",
            "downloadXLS",
            "downloadCSV",
          ],
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    navigator: {
      enabled: false,
    },

    scrollbar: {
      enabled: false,
    },

    rangeSelector: {
      enabled: true,
      allButtonsEnabled: true,
      inputEnabled: true,
      selected: 2,
      buttons: [
        //   {
        //   type: 'month',
        //   count: 1,
        //   text: '1m'
        // }, {
        //   type: 'month',
        //   count: 3,
        //   text: '3m'
        // }, {
        //   type: 'all',
        //   text: 'All'
        // }
      ],
    },

    series: [
      {
        type: "line",
        lineWidth: 1,
        color: "#7cb5ec",
        marker: {
          enabled: true,
          radius: 1,
        },
        tooltip: {
          valueDecimals: 2,
        },
        states: {
          hover: {
            lineWidthPlus: 1,
          },
        },
        name: String(widget.feed_field).toUpperCase(),
        data: feed_data,
      },
      {
        type: "line",
        lineWidth: 1,
        color: "red",
        marker: {
          enabled: true,
          radius: 1,
        },
        tooltip: {
          valueDecimals: 2,
        },
        states: {
          hover: {
            lineWidthPlus: 1,
          },
        },
        name: String(widget.wms_field).toUpperCase(),
        data: wms_data,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CombiLineChart;
