import React from "react";
import Select from "react-select";

export const ReactSelect = (props) => {
  return (
    <Select
      onChange={props.handleChange}
      className="basic-single"
      maxMenuHeight="200px"
      classNamePrefix="select"
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      isSearchable={true}
      defaultValue={props.selected}
      value={props.value}
      name="name"
      isMulti={props.isMulti}
      options={props.data}
      isOptionDisabled={(option) => option?.disabled}
    />
  );
};

export const CustomMultiSelect = (props) => {
  const options = [{ label: "Select All", value: "all" }, ...props.data];
  return (
    <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
      <Select
        isDisabled={props.isDisabled}
        className="basic-single"
        maxMenuHeight="200px"
        classNamePrefix="select"
        placeholder={props.placeholder}
        name="name"
        options={options}
        isMulti={true}
        isSearchable={true}
        value={props.value}
        isOptionDisabled={(option) => option?.disabled}
        onChange={(selected) => {
          props.multi &&
          selected.length &&
          selected.find((option) => option.value === "all")
            ? props.handleChange(options.slice(1))
            : !props.multi
            ? props.handleChange((selected && selected.value) || null)
            : props.handleChange(selected);
        }}
      />
    </div>
  );
};
