import React from "react";
import MyLoader from "../components/ContentLoader";
import Header from "../components/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
// import {
//   Backdrop,
//   Checkbox,
//   CircularProgress,
//   FormControlLabel,
//   Button,
//   TextField,
// } from "@material-ui/core";
import backLogo from "../assets/images/back.png";
import { addHeaderInAxios, checkSignature } from "../utils/Helpers";
import {
  DOWNLOAD_CHANNELS_DATA,
  GET_DOWNLOAD_ZONE_CHANNELS,
  DOWNLOAD_ZONE_WMS_DATA,
} from "../shared/ApiURLs";
import { timeScale, aggregateFunc } from "../utils/Helpers";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateRangePickerModal from "../components/DateRangePicker";
import Moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import AppBar from "@material-ui/core/AppBar";
import {
  Backdrop,
  Button,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Tab,
  Tabs,
  Input,
  Modal,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
class DownloadZone extends React.Component {
  state = {
    channels: [],
    siteData: [],
    isContentLoading: true,
    loading: false,
    allSelected: false,
    selectedTimeScale: timeScale[0].value,
    aggFunction: aggregateFunc[0],
    value: 0,
    date_range: [
      {
        startDate: yesterday,
        endDate: new Date(),
        key: "selection",
      },
    ],

    wms_data_range: [
      {
        startDate: yesterday,
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDatePicker: false,
  };

  componentDidMount = () => {
    addHeaderInAxios(this.props);
    this.get_channels();
  };

  get_channels = () => {
    axios
      .get(GET_DOWNLOAD_ZONE_CHANNELS)
      .then((res) => {
        let sorted = res.data.data.sort((a, b) => a.name.localeCompare(b.name));
        let siteData = res.data.datasite.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.setState(
          { channels: sorted, isContentLoading: false, siteData: siteData },
          () => {
            console.log(this.state.channels);
          }
        );
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({ isContentLoading: false });
      });
  };

  backBtnHandler = () => {
    return this.props.history.replace("/channels");
  };

  checkBoxChangeHandler = (event, index, type) => {
    const { checked } = event.target;

    if (type === "select_all") {
      const all_selected_channel = this.state.channels.map((el) => ({
        id: el?.id,
        name: el?.name,
        is_selected: checked,
      }));
      this.setState(
        { channels: all_selected_channel, allSelected: checked },
        () => {
          console.log(this.state.channels, this.state.allSelected);
        }
      );
    } else if (type === "single") {
      let data = this.state.channels;
      data[index]["is_selected"] = checked;
      this.setState({ channels: data }, () => {
        console.log(this.state.channels);
      });
    }
  };
  checkWms_BoxChangeHandler = (event, index, type) => {
    const { checked } = event.target;

    if (type === "select_all") {
      const all_selected_channel = this.state.siteData.map((el) => ({
        id: el?.id,
        name: el?.name,
        is_selected: checked,
      }));
      this.setState(
        { siteData: all_selected_channel, allSelected: checked },
        () => {
          console.log(this.state.siteData, this.state.allSelected);
        }
      );
    } else if (type === "single") {
      let data = this.state.siteData;
      data[index]["is_selected"] = checked;
      this.setState({ siteData: data }, () => {
        console.log(this.state.siteData);
      });
    }
  };
  timeScaleSelect = () => {
    return (
      <FormControl variant="outlined" className="my-2 w-100">
        <InputLabel id="demo-simple-select-outlined-label">
          Timescales
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Timescale"
          value={this.state.selectedTimeScale}
          onChange={(e) => {
            this.setState(
              { selectedTimeScale: e.target.value },
              console.log(this.state.selectedTimeScale)
            );
          }}
        >
          {timeScale.map((el, index) => (
            <MenuItem key={el.value} value={el.value}>
              {el.field}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  aggreFunctionSelect = () => {
    return (
      <FormControl variant="outlined" className="w-100 my-2">
        <InputLabel id="demo-simple-select-outlined-label">
          Functions
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Select Function"
          value={this.state.aggFunction}
          onChange={(e) => {
            this.setState(
              { aggFunction: e.target.value },
              console.log(this.state.aggFunction)
            );
          }}
          disabled={this.state.selectedTimeScale === "0"}
        >
          <MenuItem key="Select Function" value="Select Function">
            Select Function
          </MenuItem>
          {aggregateFunc.map((el, index) => (
            <MenuItem key={index} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  handleDateRangeSelect = (item) => {
    console.log(item);
    this.state.value === 0
      ? this.setState({ date_range: [item?.selection] })
      : this.setState({ wms_data_range: [item?.selection] });
  };

  showDateRangePicker = () => {
    this.setState({ showDatePicker: true });
  };

  handleDownloadData = () => {
    const selected_channels = this.state.channels.filter(
      (el) => el.is_selected === true
    );
    if (!selected_channels.length) {
      return this.handleToast("error", "Please Select Atleast One Channel!");
    }
    // else if (this.state.selectedTimeScale === "0") {
    // return this.handleToast("error", "Please Select Timescale!"); }
    else if (
      !this.state.date_range[0].endDate ||
      !this.state.date_range[0].startDate
    ) {
      return this.handleToast("error", "Please Select Date Range!");
    }

    let startDate = new Date(this.state.date_range[0].startDate);
    let endDate = new Date(this.state.date_range[0].endDate);

    let data = {
      selected_channels: selected_channels,
      selectedTimeScale: this.state.selectedTimeScale,
      aggFunction: this.state.aggFunction,
      startDate: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
      },
      endDate: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
      },
    };
    console.log(data);
    this.setState({ loading: true });
    axios
      .post(DOWNLOAD_CHANNELS_DATA, data, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const timeStamp = new Date().getTime();
        const fileName = `Data_${timeStamp}.csv`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.log(e?.response.data);
        this.handleToast("error", `Something Went Wrong, Please Try Again!`);
      });
  };
  handleDownloadForWmsData = () => {
    const selected_channels = this.state.siteData.filter(
      (el) => el.is_selected === true
    );

    let startDate = new Date(this.state.wms_data_range[0].startDate);
    let endDate = new Date(this.state.wms_data_range[0].endDate);
    var channel = selected_channels.map((e) => e.id);
    let data = {
      site: channel,
      startDate: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
      },
      endDate: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
      },
    };
    this.setState({ loading: true });
    axios
      .post(DOWNLOAD_ZONE_WMS_DATA, data, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const timeStamp = new Date().getTime();
        const fileName = `Data_${timeStamp}.csv`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.log(e?.response?.data);
        this.handleToast("error", `Something Went Wrong, Please Try Again!`);
      });
  };
  handleChange = (index) => {
    console.log("testing-->", index);
    this.setState({ value: index });

    const all_selected_channel = this.state.channels.map((el) => ({
      id: el?.id,
      name: el?.name,
      is_selected: false,
    }));
    const siteDatalist = this.state.siteData.map((el) => ({
      id: el?.id,
      name: el?.name,
      is_selected: false,
    }));
    this.setState({
      channels: all_selected_channel,
      allSelected: false,
      siteData: siteDatalist,
    });
  };

  render() {
    const {
      channels,
      isContentLoading,
      loading,
      allSelected,
      date_range,
      siteData,
      wms_data_range,
    } = this.state;

    return (
      <div className="main-wrapper">
        <Header {...this.props} />
        {isContentLoading && <MyLoader />}
        {!isContentLoading && (
          <Container fluid>
            <div className="mb-3">
              <img
                src={backLogo}
                alt="back"
                className="backBtn mb-3"
                onClick={this.backBtnHandler}
              />

              <div>
                <div className="custom_card pt-3 pb-2 px-3">
                  <div className="mb-3">
                    <h1>Download Zone</h1>
                  </div>
                  <hr />

                  <AppBar position="static" color="default">
                    <Tabs
                      // value={value}
                      // onChange={this.handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab
                        label=" Mmb Data"
                        onClick={() => this.handleChange(0)}
                        style={{
                          background: this.state.value == 0 ? "#fff" : "none",
                        }}
                      />
                      <Tab
                        label=" Wms Download Data"
                        onClick={() => this.handleChange(1)}
                        style={{
                          background: this.state.value == 1 ? "#fff" : "none",
                        }}
                      />
                    </Tabs>
                  </AppBar>
                  {this.state.value === 0 ? (
                    <>
                      <h6 className="mt-4">Select Channels:</h6>

                      <div className="my-3">
                        <span className="mx-auto px-auto">
                          <FormControlLabel
                            key={"all"}
                            control={
                              <Checkbox
                                checked={allSelected}
                                onChange={(e) => {
                                  this.checkBoxChangeHandler(
                                    e,
                                    null,
                                    "select_all"
                                  );
                                }}
                                color="primary"
                              />
                            }
                            label={"Select All"}
                          />
                        </span>
                        {channels.length > 0 &&
                          channels.map((channel, index) => {
                            return (
                              <span
                                key={channel?.id}
                                className="mx-auto px-auto"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={channel?.is_selected}
                                      color="primary"
                                      onChange={(e) => {
                                        this.checkBoxChangeHandler(
                                          e,
                                          index,
                                          "single"
                                        );
                                      }}
                                    />
                                  }
                                  label={channel?.name}
                                />
                              </span>
                            );
                          })}
                        <Row className="mb-3">
                          <Col lg={4} md={12}>
                            {this.timeScaleSelect()}
                          </Col>
                          <Col lg={4} md={12}>
                            {this.aggreFunctionSelect()}
                          </Col>
                          <Col lg={4} md={12}>
                            <TextField
                              label="Date Range"
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              className="my-2 w-100"
                              readOnly
                              onClick={this.showDateRangePicker}
                              value={`${Moment(date_range[0].startDate).format(
                                "MMM D, YYYY"
                              )} - ${Moment(date_range[0].endDate).format(
                                "MMM D, YYYY"
                              )}`}
                            />
                          </Col>
                        </Row>
                        <div className="mb-3">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleDownloadData}
                          >
                            Download Data
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6 className="mt-4">Select site</h6>
                      <div className="my-3">
                        <span className="mx-auto px-auto">
                          <FormControlLabel
                            key={"all"}
                            control={
                              <Checkbox
                                checked={allSelected}
                                onChange={(e) => {
                                  this.checkWms_BoxChangeHandler(
                                    e,
                                    null,
                                    "select_all"
                                  );
                                }}
                                color="primary"
                              />
                            }
                            label={"Select All"}
                          />
                        </span>

                        {siteData.length > 0 &&
                          siteData.map((channel, index) => {
                            return (
                              <span
                                key={channel?.id}
                                className="mx-auto px-auto"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={channel?.is_selected}
                                      color="primary"
                                      onChange={(e) => {
                                        this.checkWms_BoxChangeHandler(
                                          e,
                                          index,
                                          "single"
                                        );
                                      }}
                                    />
                                  }
                                  label={channel?.name}
                                />
                              </span>
                            );
                          })}
                      </div>

                      <Row className="mb-3">
                        <Col lg={4} md={12}>
                          <TextField
                            label="Date Range"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            className="my-2 w-100"
                            readOnly
                            onClick={this.showDateRangePicker}
                            value={`${Moment(
                              wms_data_range[0].startDate
                            ).format("MMM D, YYYY")} - ${Moment(
                              wms_data_range[0].endDate
                            ).format("MMM D, YYYY")}`}
                          />
                        </Col>
                      </Row>

                      <div className="mb-3">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.handleDownloadForWmsData}
                        >
                          Download Wms-Data
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Container>
        )}
        <Footer />
        <ToastContainer />
        <Backdrop
          style={{
            zIndex: "10",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DateRangePickerModal
          show={this.state.showDatePicker}
          onHide={() => this.setState({ showDatePicker: false })}
          date_range={
            this.state.value === 0
              ? this.state.date_range
              : this.state.wms_data_range
          }
          handleDateRangeChange={this.handleDateRangeSelect}
        />
      </div>
    );
  }
}

export default DownloadZone;
